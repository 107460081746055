import React, { useState } from "react";

import axios from "axios";

import { useSelector } from "react-redux";

const ProfileApp = () => {
  const getusers = useSelector((state) => state.user.value);

  const getname = getusers.fullname;

  const getemail = getusers.email;

  const [fullname, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [sex, setSex] = useState("");
  const [states, setStates] = useState("");
  const [birth, setBirth] = useState("");

  const [phoneErrr, setPhoneErr] = useState({});
  const [fullNameErr, setFullNameErr] = useState({});

  const [state, setState] = React.useState({
    isValid: false,
  });

  const onSubmit = (b) => {
    b.preventDefault();

    const isValid = formValidation();

    if (isValid) {
      const user_contact = {
        address: address,
        fullname: fullname,
        phone: phone,
        state: states,
        email: getemail,
        birth: birth,
        sex: sex,
      };

      console.log(user_contact);

      axios({
        method: "post",
        url: "/api/profile.php",
        data: user_contact,
        config: { headers: { "Content-Type": "multiport/form-data" } },
      })
        .then(function (response) {
          if (response.data.message === "success") {
            
            setState({
                withdrawtext:
                  "Hurray! Your profile has been updated. It is undergoing further review. 👋",
                withdrawclass:
                  "alert bg-light-success text-dark-success alert-dismissible fade show",
              });

          } else {
            setState({
                withdrawtext:
                  "Request Failed. Profile update not completed! Please Check your connection ☹️",
                withdrawclass:
                  "alert bg-light-danger text-dark-danger alert-dismissible fade show",
              });
          }
        })
        .catch((err) => {
          // console.log(err);
          setState({
            withdrawtext:
              "Request Failed. Profile update not completed! Please Check your connection ☹️",
            withdrawclass:
              "alert bg-light-danger text-dark-danger alert-dismissible fade show",
          });
        });
    }
  };

  const formValidation = () => {
    const fullNameErr = {};
    const phoneErrr = {};
    let isValid = true;

    if (fullname.trim().length < 5) {
      fullNameErr.fullNameshort = "Full name is required";
      isValid = false;
    }

    if (!phone) {
      phoneErrr.phoneShort = "Please enter your phone number";
      isValid = false;
    } else if (phone.trim().length < 11) {
      phoneErrr.phoneShort = "Phone number is not correct!";
      isValid = false;
    }

    setFullNameErr(fullNameErr);
    setPhoneErr(phoneErrr);

    return isValid;
  };

  return (
    <div class="col-lg-9 col-md-8 col-12">
      <div class="card">
        <div
          class="alert bg-light-warning text-dark alert-dismissible fade show mb-4"
          role="alert"
        >
          <strong>customerservice@smartedung.com</strong>
          <p class="mt-3 mb-3">
            Refresh the page if some input contents are not displaying on your
            browser. Contact the email above if you have any issues as regarding
            your profile.
          </p>
        </div>

        <div class="card-header">
          <h3 class="mb-0">Profile Details</h3>
          <p class="mb-0">
            You have full control to manage your own account setting.
          </p>
        </div>

        <div class="card-body">

        <div class={state.withdrawclass} role="alert">
          <p class="mb-0">
            {state.withdrawtext}
            <br />
          </p>
        </div>

          <div>
            <p class="mb-4">Edit your personal information and address.</p>

            <form class="row" onSubmit={onSubmit}>
              <div class="mb-3 col-12 col-md-6">
                <label class="form-label" for="fname">
                  Full Name
                </label>
                <input
                  type="text"
                  id="fname"
                  class="form-control"
                  placeholder="Full Name"
                  onChange={(e) => {
                    setName(e.target.value);
                    fullNameErr.fullNameshort = " ";
                  }}
                  required
                />

                {Object.keys(fullNameErr).map((key) => {
                  return (
                    <div className="text-danger fs-5 mt-3">
                      {fullNameErr[key]}
                    </div>
                  );
                })}
              </div>

              <div class="mb-3 col-12 col-md-6">
                <label class="form-label" for="phone">
                  Phone
                </label>
                <input
                  type="text"
                  id="phone"
                  class="form-control"
                  placeholder="Phone"
                  onChange={(e) => {
                    setPhone(e.target.value);
                    phoneErrr.phoneShort = "";
                  }}
                  required
                />

                {Object.keys(phoneErrr).map((key) => {
                  return (
                    <div className="text-danger fw-bold fs-5 mt-2">
                      {phoneErrr[key]}
                    </div>
                  );
                })}
              </div>

              <div class="mb-3 col-12 col-md-10">
                <label class="form-label" for="address">
                  Address
                </label>
                <input
                  type="text"
                  id="address"
                  class="form-control"
                  placeholder="Address"
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  required
                />
              </div>

              <div class="mb-3 col-12 col-md-2">
                <label class="form-label">Sex</label>
                <select
                  class="selectpicker"
                  data-width="100%"
                  onChange={(e) => {
                    setSex(e.target.value);
                  }}
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>

              <div class="mb-3 col-12 col-md-6">
                <label class="form-label">State</label>
                <select
                  class="selectpicker"
                  data-width="100%"
                  onChange={(e) => {
                    setStates(e.target.value);
                  }}
                >
                  <option value="">Select State</option>
                  <option value="Lagos">Lagos</option>
                  <option value="Ogun">Ogun</option>
                  <option value="Ibadan">Ibadan</option>
                </select>
              </div>

              <div class="mb-3 col-12 col-md-6">
                <label class="form-label" for="birth">
                  Birthday
                </label>
                <input
                  class="form-control flatpickr"
                  type="date"
                  placeholder="Date of Birth"
                  onChange={(e) => {
                    setBirth(e.target.value);
                  }}
                  id="birth"
                  name="birth"
                />
              </div>

              <div class="col-12">
                <button class="btn btn-primary" type="submit">
                  Update Profile
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileApp;
