import React from "react";

import axios from "axios";

import "./Loader.css";

import { useState } from "react";

const SchoolTable = (getemail) => {
  const [schools, setSchools] = useState(null);

  const [message, setMessage] = useState("");

  const email = getemail.getemail;

  const [state, setState] = React.useState({
    color: "",
    alerts: "",
    info: "",
    fetch: "",
    buttonname: " Fetch All School Reports"
  });

  const loader = {
    display: "grid",
    marginLeft: "300px",
    marginRight: "auto",
    position: "relative",
  };

  const fetchData = async () => {
    const response = await axios.post("./api/school_lists.php", { email });

    const view = response.data;

    if (view.message === "failed") {
      setMessage(view.message);
    } else {
      setSchools(view);
    }

    setState({
      alerts: "",
      info: "",
      fetch: "spinner-border spinner-border-sm text-primary",
      buttonname: "Fetching All...."
    });
  };

  const renderTable = () => {
    if (message === "failed") {
      return (
        <div className="col-md-12 py-5 mt-1 text-center">
          <img
            src="../assets/images/background/5.png"
            alt="Gallery image 3"
            class="gallery__img rounded-3"
            style={{ width: "10%" }}
          />

          <h4 className="fs-3 fw-bold mt-8">No Records Found</h4>
        </div>
      );
    } else if (schools) {
      return schools.map((school, index) => {
        const TimeDate = new Date(school.tm_date).toDateString();
        //console.log(school.tm_date)
        return (
          <tr>
            <td class="align-middle border-top-0">
              <a href="#">
                <div class="d-lg-flex align-items-center">{index + 1}</div>
              </a>
            </td>
            <td class="align-middle border-top-0">
              {school.schoolname} - {school.schoolcode}
            </td>
            <td class="align-middle border-top-0">
              ₦{school.schoolpopulation * 200}.0
            </td>
            <td class="align-middle border-top-0">
              <span className={school.color}>{school.status_code}</span>
            </td>
            <td class="text-muted align-middle border-top-0">{TimeDate}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="5">
            <div className="col-md-12 text-center mt-5">
              <div class="lds-facebook">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </td>
        </tr>
      );
    }
  };

  return (
    <div class="card mb-4">
      <div
        class="pb-6 d-lg-flex justify-content-between align-items-center card-header border-bottom-0"
        onMouseMove={fetchData}
        onTouchMove={fetchData}
      >
        <div class="mb-3 mb-lg-0">
          <h3 class="h4 mb-0">All School Report</h3>
        </div>
        <div>
          <button
            type="button"
            class="btn btn-outline-primary btn-sm"
            onClick={fetchData}
          >
            {state.buttonname}
            {"  "}
            <div className={state.fetch} role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </button>
        </div>
      </div>

      <div
        class="table-responsive border-0"
        onMouseMove={fetchData}
        onTouchMove={fetchData}
      >
        <table class="table mb-0 text-nowrap">
          <thead class="table-primary">
            <tr>
              <th scope="col" class="border-0">
                S/N
              </th>
              <th scope="col" class="border-0">
                SCHOOL DETAILS
              </th>
              <th scope="col" class="border-0">
                AMOUNT
              </th>
              <th scope="col" class="border-0">
                STATUS
              </th>
              <th scope="col" class="border-0">
                DATE REGISTERED
              </th>
            </tr>
          </thead>
          <tbody>{renderTable()}</tbody>
        </table>
      </div>
    </div>
  );
};

export default SchoolTable;
