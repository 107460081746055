import React from "react";

import { Link } from "react-router-dom";

const Sidebar = () => {

  function LogOut(){
    localStorage.clear();
    window.location.href = "../#/login";
  }

  return (
    <div class="col-lg-3 col-md-4 col-12">
      <nav class="navbar navbar-expand-md navbar-light shadow-sm mb-4 mb-lg-0 sidenav">
        <a class="d-xl-none d-lg-none d-md-none text-inherit fw-bold" href="#">
          Menu
        </a>

        <button
          class="navbar-toggler d-md-none icon-shape icon-sm rounded bg-primary text-light"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sidenav"
          aria-controls="sidenav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="fe fe-menu"></span>
        </button>

        <div class="collapse navbar-collapse" id="sidenav">
          <div class="navbar-nav flex-column">
            <span class="navbar-header">Dashboard</span>
            <ul class="list-unstyled ms-n2 mb-4">
              <li class="nav-item">
                <Link to="../app/home" class="nav-link">
                  <i class="fe fe-home nav-icon"></i>My Dashboard
                </Link>
              </li>

              <li class="nav-item">
                <Link to="../app/schools" class="nav-link">
                  <i class="bi bi-speedometer nav-icon"></i>All Schools
                </Link>
              </li>

              <li class="nav-item">
                <Link to="../app/earnings" class="nav-link">
                  <i class="bi bi-piggy-bank nav-icon"></i>My Earnings
                </Link>
              </li>

              <li class="nav-item">
                <Link to="../app/orders" class="nav-link">
                  <i class="bi bi-cart-check nav-icon"></i>Proposal Orders
                </Link>
              </li>

              <li class="nav-item">
                <Link to="../app/payouts" class="nav-link">
                  <i class="bi bi-cash-coin nav-icon"></i>Payouts
                </Link>
              </li>

              <li class="nav-item">
                <Link to="../app/smartinvest.io" class="nav-link bg-dark text-white fs-4">
                  <i class="bi bi-bank nav-icon"></i>Smart Invest.io
                </Link>
              </li>

              
            </ul>

            <span class="navbar-header">Account Settings</span>
            <ul class="list-unstyled ms-n2 mb-0">
              <li class="nav-item">
                <Link to="../app/profile" class="nav-link">
                  <i class="fe fe-settings nav-icon"></i>Edit Profile
                </Link>
              </li>

              <li class="nav-item">
                <Link to="../app/security" class="nav-link">
                  <i class="fe fe-user nav-icon"></i>Security
                </Link>
              </li>

              <li class="nav-item">
                <Link to="../app/notifications" class="nav-link">
                  <i class="fe fe-bell nav-icon"></i>Notifications
                </Link>
              </li>

              <li class="nav-item">
                <Link to="../app/delete" class="nav-link">
                  <i class="fe fe-trash nav-icon"></i>Delete Profile
                </Link>
              </li>

              <li class="nav-item">
                <a class="nav-link" onClick={LogOut}>
                  <i class="fe fe-power nav-icon"></i>Sign Out
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
