import React from "react";

import Typed from "typed.js";

import Auth from "./Auth";

import Navbar from "./Navbar";

import Footer from "../Footer";

import { Link } from "react-router-dom";

import { useEffect, useRef } from "react";

const Header = () => {
  const el = useRef(null);

  const els = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ["with", "us", "Smart Edu Freelance"], // Strings to display
      // Speed settings, try diffrent values untill you get good results
      startDelay: 300,
      typeSpeed: 100,
      backSpeed: 100,
      backDelay: 100,
      smartBackspace: true,
      loop: true,
      showCursor: true,
      cursorChar: "!",
    });

    // Destropying
    return () => {
      typed.destroy();
    };
  }, []);

  useEffect(() => {
    const typed = new Typed(els.current, {
      strings: ["Connected", "Richer", "a Major Freelancer"], // Strings to display
      // Speed settings, try diffrent values untill you get good results
      startDelay: 300,
      typeSpeed: 100,
      backSpeed: 100,
      backDelay: 100,
      smartBackspace: true,
      loop: true,
      showCursor: true,
      cursorChar: "|",
    });

    // Destropying
    return () => {
      typed.destroy();
    };
  }, []);

  const handleindex = { zIndex: "1" };

  return (
    <div>
      <Navbar />
      <div className="py-md-20 py-12 bg-success">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-xl-6 col-md-12">
              <div className="mb-4 mb-xl-0 text-center text-md-start">
                <h1 className="display-2 fw-bold mb-3 text-white ls-sm ">
                  Become{" "}
                  <span class="headingTyped text-dark" ref={els}></span>
                </h1>
                <p className="mb-4 lead text-white">
                  Earn a great deal with our marketing platform (Smart Edu
                  Freelance) - Build capacity, Have a reliable money making
                  platform, Strengthen your connection .
                </p>

                <div className="mb-6 mb-0">
                  <ul className="list-unstyled fs-4 ">
                    <li className="mb-2 text-white">
                      <span className="me-2 ">
                        <i className="bi bi-check2-circle text-dark"></i>
                      </span>
                      <span className="align-top">Easy Going</span>
                    </li>
                    <li className="mb-2 text-white">
                      <span className="me-2 ">
                        <i className="bi bi-check2-circle text-dark"></i>
                      </span>
                      <span className="align-top">Reliable Platform</span>
                    </li>
                    <li className="mb-2 text-white">
                      <span className="me-2 ">
                        <i className="bi bi-check2-circle text-dark"></i>
                      </span>
                      <span className="align-top">Fast Payment</span>
                    </li>
                  </ul>
                </div>
               
              </div>
            </div>

            <div class="offset-lg-1 col-lg-5 col-md-8 py-8 py-xl-0">
              <Auth />
            </div>
          </div>
        </div>
      </div>

      <div className="py-4 -sm position-relative bg-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-12">
              <div className="text-dark fw-semi-bold lh-1 fs-4 mb-3 mb-lg-0">
                <span className="icon-shape icon-xs rounded-circle bg-light-success text-center me-2">
                  <i className="mdi mdi-check text-dark-warning "></i>
                </span>
                <span className="align-middle">No Certification Needed</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="text-dark fw-semi-bold lh-1 fs-4 mb-3 mb-lg-0">
                <span className="icon-shape icon-xs rounded-circle bg-light-success text-center me-2">
                  <i className="mdi mdi-check text-dark-warning "></i>
                </span>
                <span className="align-middle">Flexible Deadlines</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="text-dark fw-semi-bold lh-1 fs-4 mb-3 mb-md-0">
                <span className="icon-shape icon-xs rounded-circle bg-light-success text-center me-2">
                  <i className="mdi mdi-check text-dark-warning "></i>
                </span>
                <span className="align-middle">Market Availability 100%</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="text-dark fw-semi-bold lh-1 fs-4">
                <span className="icon-shape icon-xs rounded-circle bg-light-success text-center me-2">
                  <i className="mdi mdi-check text-dark-warning "></i>
                </span>
                <span className="align-middle">Active 24 hours</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="py-5 py-lg-14 bg-white">
        <div class="container">
          <div class="row">
            <div class="col-xl-5 col-lg-6 col-12">
              <div class="avatar-group me-5 mb-5">
                <span class="avatar avatar-lg">
                  <img
                    alt="avatar"
                    src="../assets/images/avatar/avatar-1.jpg"
                    class="rounded-circle"
                  />
                </span>
                <span class="avatar avatar-lg">
                  <img
                    alt="avatar"
                    src="../assets/images/avatar/avatar-2.jpg"
                    class="rounded-circle"
                  />
                </span>
                <span class="avatar avatar-lg">
                  <img
                    alt="avatar"
                    src="../assets/images/avatar/avatar-3.jpg"
                    class="rounded-circle"
                  />
                </span>
                <span class="avatar avatar-lg">
                  <img
                    alt="avatar"
                    src="../assets/images/avatar/avatar-4.jpg"
                    class="rounded-circle"
                  />
                </span>
              </div>
              <div class="mb-5  ">
                <h1 class="display-3 mb-4 fw-bold ">
                  Join the team, and Earn massively!
                </h1>
                <p class="lead mb-4 pe-xl-12 ">
                  We’re looking for incredible people to build on our strong
                  momentum. Help us power the brands you know and love.
                </p>
                <Link to="/earnings" class="btn btn-success">
                  Check How Much You Earn
                </Link>
                <p class=" mt-4 mb-0">
                  Here are the locations of school within our reach{" "}
                  <Link to="/earnings#location">all locations</Link>.
                </p>
              </div>
            </div>

            <div class="col-lg-3 col-md-12 col-12 mt-4 mt-lg-0"></div>

            <div class="col-xl-4 col-lg-4 col-md-12 col-12 mb-3 mb-lg-0 ">
              <div
                class="card mb-4 card-hover bg-light-gradient-bottom"
                style={handleindex}
              >
                <div class="card-body">
                  <i class="bi bi-cash-coin fs-4 icon-shape icon-sm rounded-3 bg-light-success text-dark-success mt-2"></i>
                  <h3 class="display-4 fw-bold mt-3 mb-0">₦312,450</h3>
                  <span>Your total earnings</span>
                  <hr class="my-4" />
                  <div class="row">
                    <div class="col ps-0">
                      <div
                        id="totalEarning"
                        class="apex-charts mt-n4 mb-n3"
                      ></div>
                    </div>
                    <div class="col-auto">
                      <span class="badge bg-success ">
                        <i class="fe fe-trending-up fs-6 me-2"></i>32%
                      </span>
                    </div>
                  </div>
                  <p class="mb-0 lh-1.5">
                    Update your payout method in settings.
                  </p>
                </div>
              </div>

              <div className="position-relative">
                <div className="opacity-25 position-absolute bottom-0 end-0 me-md-n3 mb-md-n6 me-lg-n4 mb-lg-n4 me-xl-n6 mb-xl-n8 d-none d-md-block ">
                  <img src="../assets/images/pattern/dots-pattern.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="py-5 py-lg-0 bg-white">
        <div class="container">
          <div class="row">

          <div class="col-xl-4 col-lg-4 col-md-12 order-1 col-12 mb-3 mt-lg-3">
              <div class="mb-5 text-center">
                <h1 class="display-5 mb-2 fw-bold ">Join the community</h1>
                <p class="lead fs-5 mb-4">
                  Become part of the fastest developing platform currently.
                </p>
              </div>
            </div>

            <div class="col-xl-6 offset-xl-2 col-lg-6 col-12 mb-4 order-lg-2 text-center">
            <div
                class="card mb-4 card-hover  bg-light-gradient-top"
              >
                <div class="card-body">
              <img
                src="../assets/images/about/team.png"
                alt="Gallery image 3"
                class="gallery__img"
                style={{ width: "80%" }}
              />
            </div>
            </div>
            </div>

          </div>
        </div>
      </div>

      <div class="py-5 py-lg-5 bg-white">
        <div class="container">
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-12 mb-4 text-center">
            <div
                class="card mb-4 card-hover  bg-light-gradient-top"
              >
                <div class="card-body">
              <img
                src="../assets/images/about/fast.png"
                alt="Gallery image 3"
                class="gallery__img"
                style={{ width: "80%" }}
              />
            </div>
            </div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-12 col-12 mb-3 offset-xl-2 mt-lg-3">
              <div class="mb-5 text-center">
                <h1 class="display-5 mb-2 fw-bold ">Fast and Reliable</h1>
                <p class="lead fs-5 mb-4">
                  Get activities done with just push of buttons!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="py-5 py-lg-5 bg-white">
        <div class="container">
          <div class="row mb-2 align-items-center justify-content-center">
            <div class="col-md-10">
              <div class="row align-items-center">
                <div class="col-xl-6 col-lg-7 col-md-12 col-12 order-1 text-center text-lg-start">
                  <span class="text-primary mb-3 d-block text-uppercase fw-semi-bold ls-xl">
                    BONUS POINT
                  </span>
                  <h2 class="mb-2 display-4 fw-bold mb-3">
                    Join the team now, sign up and get{" "}
                    <span class="text-success">₦5K</span>
                    <br />
                    Instantly!
                  </h2>
                  <p class="fs-3 pe-0">
                    Sign up to the platform, submit your KYC and introduce bring
                    one school on-board and get ₦5,000 on signing of contract
                    with the school as bonus in addition to your commission
                    percentage.
                  </p>
                </div>

                <div class="offset-xl-1 col-xl-5 col-lg-5 col-12 mb-6 mb-lg-0 order-lg-2 text-center">
                  <div
                class="card mb-4 card-hover bg-light-gradient-left"
              >
                <div class="card-body">
                    <div class="mb-0 position-relative">
                      <img
                        src="../assets/images/about/bonus.gif"
                        alt="Gallery image 3"
                        class="gallery__img rounded-3"
                        style={{ width: "100%" }}
                      />
                      <a
                        class="position-absolute mt-2 ms-n4"
                        data-bs-toggle="tooltip"
                        data-placement="top"
                        title="Guaranteed"
                      >
                        <img
                          src="../assets/images/svg/checked-mark.svg"
                          alt=""
                          height="50"
                          width="50"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="py-5 py-lg-5 bg-white">
        <div class="container">
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-12 mb-4 text-center">
            <div
                class="card mb-4 card-hover  bg-light-gradient-bottom"
              >
                <div class="card-body">
              <img
                src="../assets/images/about/chat.png"
                alt="Gallery image 3"
                class="gallery__img"
                style={{ width: "80%" }}
              />
            </div>
            </div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-12 col-12 mb-3 offset-xl-2 mt-lg-3">
              <div class="mb-5 text-center">
                <h1 class="display-5 mb-2 fw-bold ">Your back. We have it</h1>
                <p class="lead fs-5 mb-4">
                  Our tech support team is here to help you 24/7/365.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="py-8 py-lg-6 bg-white">
        <div class="container">
          <div class="row mb-8 justify-content-center">
            <div class="col-lg-8 col-md-12 col-12 text-center">
              <span class="text-primary mb-3 d-block text-uppercase fw-semi-bold ls-xl">
                Steps To Get Started
              </span>
              <h2 class="mb-2 display-4 fw-bold">How do you get started?</h2>
              <p class="lead">
                Below are the steps to follow to earn with{" "}
                <span class="text-success fw-bold">SMART EDU FREELANCE</span>
              </p>
            </div>
          </div>
          <div class="row mb-6 align-items-center justify-content-center">
            <div class="col-md-4 col-12">
              <div class="card mb-4 mb-lg-0" style={handleindex}>
                <div class="card-body card-hover p-5">
                  <div class="mb-3">
                    <i class="bi bi-person-check-fill fs-1 text-dark lh-1 "></i>
                  </div>
                  <h3 class="mb-2">Sign up</h3>
                  <p class="mb-0">
                    Register to the platform with your details, and create an
                    account.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-12">
              <div class="card mb-4 mb-lg-0">
                <div class="card-body card-hover p-5">
                  <div class="mb-3">
                    <i class="bi bi-bag-check-fill fs-1 text-dark lh-1 "></i>
                  </div>
                  <h3 class="mb-2">Order Proposals</h3>
                  <p class="mb-0">
                    Once you have registered, make order requests for the Smart
                    Edu App proposal packet
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-12">
              <div class="card mb-4 mb-lg-0" style={handleindex}>
                <div class="card-body card-hover p-5">
                  <div class="mb-3">
                    <i class="bi bi-cash-coin fs-1 text-dark lh-1 "></i>
                  </div>
                  <h3 class="mb-2">Market Smart Edu App</h3>
                  <p class="mb-0">
                    Introduce the App to its direct market audience - schools,
                    and get paid Instantly!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="py-8 bg-light ">
        <div class="container">
          <div class="row">
            <div class="offset-xl-3 col-xl-6 col-md-12">
              <div class="text-center mb-8">
                <h1 class="display-4 fw-bold mb-3">
                  People <i class="mdi mdi-heart text-danger mx-1"></i> Smart
                  Edu Freelance
                </h1>

                <p class="fs-4">
                  Our average call quality rating is 4.4 out of 5. Testimonies
                  from existing members
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 col-12">
              <div class="card mb-6 mb-lg-0">
                <div class="card-body p-6">
                  <i class="mdi mdi-48px mdi-format-quote-open text-light-primary lh-1 mb-3 d-block"></i>

                  <p class="fs-3 text-dark fw-semi-bold">
                    "Smart Edu Freelance is amazing! I made twice what i earn as
                    my regular income. The platform is the best freelance
                    platform available."
                  </p>

                  <div class="d-flex mt-4 align-items-center">
                    <img
                      src="../assets/images/avatar/avatar-1.jpg"
                      alt=""
                      class="avatar avatar-md rounded-circle"
                    />
                    <div class="ms-2">
                      <h5 class="text-primary mb-0">Kayode Daniel </h5>
                      <p class="mb-0 fs-6">Freelance Marketer</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="card">
                <div class="card-body p-6">
                  <i class="mdi mdi-48px mdi-format-quote-open text-light-primary lh-1 mb-3 d-block"></i>
                  <p class="fs-3 text-dark fw-semi-bold">
                    "I am very grateful for these platform and opportunity. It
                    has been of great help to me. I pray for more success to
                    Smart Edu Freelance."
                  </p>
                  <div class="d-flex mt-4 align-items-center">
                    <img
                      src="../assets/images/avatar/avatar-9.jpg"
                      alt=""
                      class="avatar avatar-md rounded-circle"
                    />
                    <div class="ms-2">
                      <h5 class="text-primary mb-0">Omowunmi Rachael</h5>
                      <p class="mb-0 fs-6">Entrepreneur</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="py-lg-16 py-10 bg-success">
        <div class="container">
          <div class="row justify-content-center text-center">
            <div class="col-md-9 col-12">
              <h2 class="display-4 text-white">
                Join more than 1000 marketers nationwide
              </h2>
              <p class="lead text-white px-lg-12 mb-6">
                Effective freelance platform. Join the winning team and make
                large income. It can only be&nbsp;
                <span class="text-dark fw-bold" ref={el}></span>
              </p>

              <div class="d-grid d-md-block">
                <Link to="/signup" class="btn btn-dark">
                  Create An Account
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      
    </div>
  );
};

export default Header;
