import React, { useState } from "react";

import axios from "axios";

import { useSelector } from "react-redux";

const NotifyApp = () => {
  const getusers = useSelector((state) => state.user.value);

  const getname = getusers.fullname;

  const getemail = getusers.email;

  const [schools, setSchools] = useState(null);

  const [message, setMessage] = useState("");

  const fetchData = async () => {
    const response = await axios.post("./api/notify.php", { email: getemail });

    const view = response.data;

    if (view.message === "failed") {
      setMessage(view.message);
    } else {
      setSchools(view);
    }

  };

  const renderTable = () => {
    if (message === "failed") {
      return (
        <div className="col-md-12 py-5 mt-1 text-center">
          <img
            src="../assets/images/background/5.png"
            alt="Gallery image 3"
            class="gallery__img rounded-3"
            style={{ width: "10%" }}
          />

          <h4 className="fs-3 fw-bold mt-8">No Records Found</h4>
        </div>
      );
    } else if (schools) {
      return schools.map((school, index) => {
        const TimeDate = new Date(school.tm_date).toDateString();
        //console.log(school.tm_date)
        return (
            <>
        <li class="list-group-item">
        <div class="row">
          <div class="col">
            <a class="text-body">
              <div class="d-flex">
                <img
                  src="../assets/images/avatar/bot.svg"
                  alt=""
                  class="avatar-md rounded-circle"
                />
                <div class="ms-3">
                  <h5 class="fw-bold mb-1">Bot Kristin</h5>
                  <p class="mb-3 text-dark">
                  {school.message}
                  </p>
                  <span class="fs-6 text-muted">
                    <span>
                      <span class="fe fe-thumbs-up text-success me-1"></span>
                      {school.date_},
                    </span>
                    <span class="ms-1">{school.date_time}</span>
                  </span>
                </div>
              </div>
            </a>
          </div>
          <div class="col-auto text-center me-2">
            <a
              class="badge-dot bg-info"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Mark as unread"
            ></a>
          </div>
        </div>
      </li>
            </>
        );
      });
    } else {
      return (
       
        <div className="col-md-12 text-center py-5 mt-5">
        <div class="lds-facebook"><div></div><div></div><div></div></div>
       </div>

      );
    }
  };

  return (
    <div class="col-lg-9 col-md-8 col-12" onMouseMove={fetchData} onTouchMove={fetchData}>
      <div class="card">
        <div class="card-header d-flex justify-content-between align-items-center">
          <div>
            <h3 class="mb-0">Notifications</h3>
            <p class="mb-0">
              You will get all notification here. Please report any suspicious{" "}
              <br /> activities{" "}
              <span className="text-primary fw-semi-bold">
                freelance-desk@smartedung.com
              </span>
            </p>
          </div>
          <div>
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                id="checkAll"
                checked
              />
              <label class="form-check-label" for="checkAll"></label>
            </div>
          </div>
        </div>

        <div class="card-body" id="navbar-example3">
        <h4 class="mb-3 fw-semi-bold">All Notifications</h4>
        <div data-spy="scroll" data-bs-target="#navbar-example3" data-offset="0">
          <ul class="list-group list-group-flush notification-list-scroll">
            {renderTable()}
          </ul>
        </div>
        </div>
      </div>
    </div>
  );
};

export default NotifyApp;
