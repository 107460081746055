import React from "react";

import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <nav class="navbar navbar-expand-lg navbar-default">
      <div class="container-fluid px-0">
        <Link to="/" className="navbar-brand">
          <img
            src="../assets/images/brand/logo/smfreelance-2.png"
            width="260"
            alt=""
          />
        </Link>

        <button
          className="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-default"
          aria-controls="navbar-default"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="icon-bar top-bar mt-0"></span>
          <span className="icon-bar middle-bar"></span>
          <span className="icon-bar bottom-bar"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbar-default">
          <ul className="navbar-nav">
            <li className="nav-item active">
              <Link to="/about" className="nav-link active fs-4" id="navbarAbout">
                About
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/earnings" className="nav-link fs-4" id="navbarPrice">
                Earnings
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/blog" className="nav-link fs-4" id="navbarBlog">
                Blog
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/faq" className="nav-link fs-4" id="navbarFAQ">
                FAQ
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="/contact"
                className="nav-link fs-4"
                href="#"
                id="navbarContact"
              >
                Contact
              </Link>
            </li>
          </ul>

          <div className="ms-auto mt-3 mt-lg-0">
            <Link to="/login" className="btn btn-success text-white">
              Log In To Account
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
