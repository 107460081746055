import React, { useState } from "react";

import axios from "axios";

import { useSelector } from "react-redux";

const DeleteApp = () => {
  const getusers = useSelector((state) => state.user.value);

  const getname = getusers.fullname;

  const getemail = getusers.email;

  const [state, setState] = React.useState({
    withdrawclass: "alert bg-light-info text-dark alert-dismissible fade show",
    withdrawtext:
      "Deleting profile means you are no longer with Smart Edu Freelance. For further enquires contact us on the telegram group.",
    StatusDel: false,
  });

  function DeleteProfile() {
    const user_profile = {
      email: getemail,
    };

    //console.log(user_profile);

    axios({
      method: "post",
      url: "/api/delete_acct.php",
      data: user_profile,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response.data.data.account_name);
        if (response.data.message === "success") {
          setState({
            StatusDel: true,
          });
          localStorage.clear();
          setTimeout('window.location.href = "../"', 6000);
        } else {
          setState({
            withdrawclass:
              "alert bg-light-danger text-dark alert-dismissible fade show",
            withdrawtext:
              "Oops! Profile Delete Action Failed! Please check your connection",
              StatusDel: false,
          });
          window.scrollTo(100, 100);
        }
      })
      .catch((err) => {
        setState({
          withdrawclass:
            "alert bg-light-danger text-dark alert-dismissible fade show",
          withdrawtext:
            "Oops! Profile Delete Action Failed! Please check your connection",
          StatusDel: false,
        });
        window.scrollTo(100, 100);
      });
  }

  if (state.StatusDel) {
    return (
      <div class="col-lg-9 col-md-8 col-12">
        <div className="col-md-12 text-center mt-15">
          <div class="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="text-center display-3 text-dark">Deleting...</div>
      </div>
    );
  } else {
    return (
      <div class="col-lg-9 col-md-8 col-12">
        <div class="card">
          <div class={state.withdrawclass} role="alert">
            <p class="mb-4">
              {state.withdrawtext}
              <br />
            </p>

            <a href="https://t.me/+aO5rVEkmkJ5mNzBk" target="_blank" class="btn btn-outline-dark btn-sm fs-4">
              <i class="bi bi-telegram"></i> Telegaram group
            </a>
          </div>

          <div class="card-header">
            <h3 class="mb-0">Delete your account</h3>
            <p class="mb-0">Delete or Close your account permanently.</p>
          </div>

          <div class="card-body p-4">
            <span class="text-danger h4">Warning</span>
            <p>
              If you close your account, you will loose all access to your
              earnings, investments, bonuses and will lose access forever.
            </p>
            <button
              class="btn btn-outline-danger btn-sm"
              onClick={DeleteProfile}
            >
              Close My Account
            </button>
          </div>
        </div>
      </div>
    );
  }
};

export default DeleteApp;
