import React, { useState } from "react";

import axios from "axios";

import { useSelector } from "react-redux";

import { Navigate } from "react-router-dom";

const PayoutsAPP = () => {
  const getusers0 = useSelector((state) => state.user.value);

  const gettotal = getusers0.total;

  const getrevenue = getusers0.revenue;

  const getbonus = getusers0.bonus;

  const getemail = getusers0.email;

  const getzenith = getusers0.zenith_acc;

  const getpolaris = getusers0.polaris_acc;

  const getinveststat = getusers0.invest_status;

  const getname = getusers0.fullname;

  const [zenith, setZenith] = React.useState(false);

  const [disable, setDisable] = React.useState(false);

  const [polaris, setPolaris] = React.useState(false);

  const [disable1, setDisable1] = React.useState(false);

  const [loggedIn, setloggedIn] = React.useState(false);

  let revenue = getrevenue;

  let bonus = getbonus;

  let total, withdraw_status;

  if (revenue === 0 && getinveststat !== "INVEST") {
    total = bonus;
    withdraw_status =
      "You can not withdraw the bonus point until atleast a school signs up";
  } else {
    total = gettotal;
    withdraw_status = "Hurray! you can now withdraw your earnings! 😃";
  }

  const [state, setState] = React.useState({
    isSubmit: "",
    buttonname: "Update Account Number",
    account: getzenith,
    account_name: "",
    text: "",
    isSubmit1: "",
    buttonname1: "Update Account Number",
    account1: getpolaris,
    account_name1: "",
    text1: "",
    withdrawclass1: "",
    withdrawtext1: "",
    withdrawclass:
      "alert bg-light-warning text-dark-warning alert-dismissible fade show",
    withdrawtext:
      "Kindly update your payout option to the one you would like to be paid too. If you have any issues regarding payments kindly send an email to the mail above.",
  });

  const onSubmit = (b) => {
    b.preventDefault();

    //console.log(state.account)

    const user_contact = {
      account: state.account,
      email: getemail,
      fullname: getname,
    };
    // console.log(user_contact);

    axios({
      method: "post",
      url: "/api/zverify_account.php",
      data: user_contact,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response.data.data.account_name);
        if (response.data.status) {
          setState({
            statusin: true,
            alerts:
              "alert alert-success fs-4 mt-3 mb-3 alert-dismissible fade show",
            info: "Your Account Successfully Submitted. 👋",
            isSubmit: "spinner-grow spinner-grow-sm",
            buttonname: "Account Verified ",
            account_name: response.data.data.account_name,
            text: "Your Account name is - ",
            withdrawclass:
              "alert bg-light-warning text-dark-warning alert-dismissible fade show",
            withdrawtext:
              "Kindly update your payout option to the one you would like to be paid too. If you have any issues regarding payments kindly send an email to the mail above.",
          });

          setTimeout(
            axios({
              method: "post",
              url: "/login/auth.php",
              data: { email: getemail },
              config: { headers: { "Content-Type": "multiport/form-data" } },
            })
              .then(function (response) {
                if (response.data.message === "success") {
                  console.log(response.data.message);
                  // dispatch(register({zenith: state.account}));
                  //window.scrollTo(300, 300);
                  setTimeout(setloggedIn(true), 5000000000000);
                } else {
                }
              })
              .catch((err) => {
                // console.log(err);
              }),
            5000000000000
          );
        } else {
          setState({
            alerts:
              "alert alert-danger fs-4 mt-3 mb-3 alert-dismissible fade show",
            info: "Request Failed. Account not verified! Please Check the account number ☹️",
            isSubmit: "spinner-grow spinner-grow-sm",
            buttonname: "Order Failed",
            account_name: "",
            text: "",
            withdrawclass:
              "alert bg-light-warning text-dark-warning alert-dismissible fade show",
            withdrawtext:
              "Kindly update your payout option to the one you would like to be paid too. If you have any issues regarding payments kindly send an email to the mail above.",
          });
        }
      })
      .catch((err) => {
        setState({
          alerts1:
            "alert alert-danger fs-4 mt-3 mb-3 alert-dismissible fade show",
          info1:
            "Request Failed. Account not verified! Please Check the account number ☹️",
          isSubmit1: "spinner-grow spinner-grow-sm",
          buttonname1: "Order Failed",
          account_name1: "",
          text1: "",
          withdrawclass:
            "alert bg-light-warning text-dark-warning alert-dismissible fade show",
          withdrawtext:
            "Kindly update your payout option to the one you would like to be paid too. If you have any issues regarding payments kindly send an email to the mail above.",
        });
      });
  };

  //------------------------POLARIS----------------------------------//

  const onSubmit1 = (b) => {
    b.preventDefault();

    //console.log(state.account)

    const user_contact1 = {
      account: state.account1,
      email: getemail,
    };
    // console.log(user_contact);

    axios({
      method: "post",
      url: "/api/pverify_account.php",
      data: user_contact1,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        // console.log(response.data.data.account_name);
        if (response.data.status) {
          setState({
            statusin1: true,
            alerts1:
              "alert alert-success fs-4 mt-3 mb-3 alert-dismissible fade show",
            info1: "Your Account Successfully Submitted. 👋",
            isSubmit1: "spinner-grow spinner-grow-sm",
            buttonname1: "Account Verified ",
            account_name1: response.data.data.account_name,
            text1: "Your Account name is - ",
            withdrawclass:
              "alert bg-light-warning text-dark-warning alert-dismissible fade show",
            withdrawtext:
              "Kindly update your payout option to the one you would like to be paid too. If you have any issues regarding payments kindly send an email to the mail above.",
          });
          // dispatch(register({zenith: state.account}));
          //window.scrollTo(300, 300);
        } else {
          setState({
            alerts1:
              "alert alert-danger fs-4 mt-3 mb-3 alert-dismissible fade show",
            info1:
              "Request Failed. Account not verified! Please Check the account number ☹️",
            isSubmit1: "spinner-grow spinner-grow-sm",
            buttonname1: "Order Failed",
            account_name1: "",
            text1: "",
            withdrawclass:
              "alert bg-light-warning text-dark-warning alert-dismissible fade show",
            withdrawtext:
              "Kindly update your payout option to the one you would like to be paid too. If you have any issues regarding payments kindly send an email to the mail above.",
          });
        }
      })
      .catch((err) => {
        setState({
          alerts1:
            "alert alert-danger fs-4 mt-3 mb-3 alert-dismissible fade show",
          info1:
            "Request Failed. Account not verified! Please Check the account number ☹️",
          isSubmit1: "spinner-grow spinner-grow-sm",
          buttonname1: "Order Failed",
          account_name1: "",
          text1: "",
          withdrawclass:
            "alert bg-light-warning text-dark-warning alert-dismissible fade show",
          withdrawtext:
            "Kindly update your payout option to the one you would like to be paid too. If you have any issues regarding payments kindly send an email to the mail above.",
        });
      });
  };

  //-----------------------------WITHDRAWAL API---------------------------//

  const [schools, setSchools] = useState(null);

  const [message, setMessage] = useState("");

  const fetchData = async () => {
    const response = await axios.post("./api/payout_lists.php", {
      email: getemail,
    });

    const view = response.data;

    if (view.message === "failed") {
      setMessage(view.message);
    } else {
      setSchools(view);
    }
  };

  const renderTable = () => {
    if (message === "failed") {
      return (
        <div className="col-md-12 py-5 mt-1 text-center">
          <img
            src="../assets/images/background/5.png"
            alt="Gallery image 3"
            class="gallery__img rounded-3"
            style={{ width: "10%" }}
          />

          <h4 className="fs-3 fw-bold mt-8">No Records Found</h4>
        </div>
      );
    } else if (schools) {
      return schools.map((school, index) => {
        const TimeDate = new Date(school.tm_date).toDateString();
        //console.log(school.tm_date);
        return (
          <tr>
            <td class="align-middle border-top-0">
              <a href="#">
                <div class="d-lg-flex align-items-center">{index + 1}</div>
              </a>
            </td>
            <td class="align-middle text-center fw-semi-bold text-info border-top-0">
              #{school.pay_id}
            </td>
            <td class="align-middle border-top-0">{school.fullname}</td>
            <td class="align-middle text-center border-top-0">
              ₦{school.amount}
            </td>
            <td class="align-middle border-top-0">
              <span className={school.color}>{school.status_code}</span>
            </td>
            <td class="text-muted align-middle border-top-0">{TimeDate}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="6">
            <div className="col-md-12 text-center mt-5">
              <div class="lds-facebook">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </td>
        </tr>
      );
    }
  };

  const onWithdraw = () => {
    //console.log("works");

    if (revenue === 0 && getinveststat !== "INVEST") {
      setState({
        withdrawtext:
          "Sorry! You can not withdraw the ₦5000, until you have brought in atleast one school. Cheer up 😃 and go make some money! 👍",
        withdrawclass:
          "alert bg-light-dark text-dark-light alert-dismissible fade show",
      });
      window.scrollTo(200, 200);
    } else {
      const user_payout = {
        fullname: getname,
        email: getemail,
        amount: gettotal,
      };

      console.log(user_payout);

      axios({
        method: "post",
        url: "/api/payouts.php",
        data: user_payout,
        config: { headers: { "Content-Type": "multiport/form-data" } },
      })
        .then(function (response) {
          // console.log(response.data.data.account_name);
          if (response.data.message === "success") {
            setState({
              withdrawtext:
                "Hurray! Your withdrawal process has began. You would get paid within 24hours. Check the Payout Table below to confirm Payment Status.",
              withdrawclass:
                "alert bg-light-success text-dark-success alert-dismissible fade show",
            });
            window.scrollTo(1400, 1400);

            fetchData();
          } else if (response.data.message === "exist") {
            setState({
              withdrawtext:
                "Hi there! You still have an ongoing payment process. Check the Payout Table below to confirm Payment Status.",
              withdrawclass:
                "alert bg-light-info text-dark-info alert-dismissible fade show",

              withdrawtext1:
                "Hi there! You still have an ongoing payment process. Check the Payout Table below to confirm Payment Status.",
              withdrawclass1:
                "alert bg-light-info text-dark-info alert-dismissible fade show",
            });
            window.scrollTo(1400, 1400);

            fetchData();
          } else {
            setState({
              withdrawtext:
                "Request Failed. Payment process not completed! Please Check your connection ☹️",
              withdrawclass:
                "alert bg-light-danger text-dark-danger alert-dismissible fade show",
            });
            window.scrollTo(200, 200);
          }
        })
        .catch((err) => {
          setState({
            withdrawtext:
              "Request Failed. Payment process not completed! Please Check your connection ☹️",
            withdrawclass:
              "alert bg-light-danger text-dark-danger alert-dismissible fade show",
          });
          window.scrollTo(200, 200);
        });
    }
  };

  if (loggedIn) {

    setTimeout(( window.location.reload(false)), 5000);

    return <Navigate to={"../app/auths"} />;
    
  } else {
    return (
      <div class="col-lg-9 col-md-8 col-12">
        <div class="card mb-4">
          <div class="card-header">
            <h3 class="mb-0">Payout Method</h3>
            <p class="mb-0">Update your bank accounts for payment.</p>
          </div>

          <div class="card-body">
            <div class={state.withdrawclass} role="alert">
              <strong>payout@smartedung.com</strong>
              <p class="mb-0">
                {state.withdrawtext}
                <br />
                <br />
                Thank you
              </p>

              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
            <div class="row mt-6">
              <div class="col-xl-4 col-lg-4 col-md-12 col-12 mb-3 mb-lg-0">
                <div class="text-center">
                  <div id="payoutChart" class="apex-charts"></div>
                  <h4 class="mb-1">Your Earning this Term</h4>
                  <h5 class="mb-0 display-4 fw-bold">₦{total}</h5>
                  <p class="px-4 text-primary">{withdraw_status}</p>
                  <button
                    type="button"
                    class="btn btn-success"
                    onClick={onWithdraw}
                  >
                    Withdraw Earning
                  </button>
                </div>
              </div>
              <div class="col-xl-8 col-lg-8 col-md-12 col-12">
                <div class="border p-4 rounded-3 mb-3">
                  <div class="custom-control custom-radio">
                    <label
                      class="form-check-label ms-1 mb-3"
                      for="customRadio1"
                    >
                      <img
                        src="../assets/images/brand/zenith.png"
                        width="20%"
                        alt=""
                      />
                    </label>
                    {getzenith ?
                    
                    (
                      <>
                        <div className={state.alerts} role="alert">
                          {state.account_name} {state.info}
                        </div>

                        <p>Your account number below has been updated.</p>

                        <form class="row" onSubmit={onSubmit}>
                          <div class="mb-3 col-12 col-md-12">
                            <label class="form-label" for="fname">
                              Account Number
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Account Number"
                              required
                              value={state.account}
                              onChange={(b) => {
                                setState({
                                  account: b.target.value,
                                  buttonname: "Update Account Number",
                                  withdrawclass:
                                    "alert bg-light-warning text-dark-warning alert-dismissible fade show",
                                  withdrawtext:
                                    "Kindly update your payout option to the one you would like to be paid too. If you have any issues regarding payments kindly send an email to the mail above.",
                                });
                                setDisable(false);
                              }}
                            />
                            <p className="mt-2 text-primary">
                              {state.text} {state.account_name}
                            </p>
                          </div>

                          <div class="col-12">
                            <button
                              class="btn btn-light-success"
                              type="submit"
                            >
                              <span>
                                Account Verified{" "}
                                <i class="bi bi-check2-circle"></i>
                              </span>{" "}
                              <span className={state.isSubmit}></span>
                            </button>
                          </div>
                        </form>
                      </>
                    ) : zenith ? (
                      <>
                        <div className={state.alerts} role="alert">
                          {state.account_name} {state.info}
                        </div>

                        <p>Please Enter your zenith bank account</p>

                        <form class="row" onSubmit={onSubmit}>
                          <div class="mb-3 col-12 col-md-12">
                            <label class="form-label" for="fname">
                              Account Number
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Account Number"
                              required
                              onChange={(e) => {
                                setState({
                                  account: e.target.value,
                                  buttonname: "Update Account Number",
                                  withdrawclass:
                                    "alert bg-light-warning text-dark-warning alert-dismissible fade show",
                                  withdrawtext:
                                    "Kindly update your payout option to the one you would like to be paid too. If you have any issues regarding payments kindly send an email to the mail above.",
                                });
                                setDisable(false);
                              }}
                            />
                            <p className="mt-2 text-primary">
                              {state.text} {state.account_name}
                            </p>
                          </div>

                          <div class="col-12">
                            <button
                              class="btn btn-secondary"
                              type="submit"
                            >
                              <span>{state.buttonname}</span>{" "}
                              <span className={state.isSubmit}></span>
                            </button>

                            <button
                              type="button"
                              class="btn btn-outline-danger ms-6"
                              onClick={() => setZenith(false)}
                            >
                              Close Panel
                            </button>
                          </div>
                        </form>
                      </>
                    ) : (
                      <>
                        <p>Add your zenith bank account here.</p>
                        <button
                          type="button"
                          class="btn btn-outline-danger"
                          onClick={() => setZenith(true)}
                        >
                          Add Account
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-4">
          <div class={state.withdrawclass1} role="alert">
            <p class="mb-0">
              {state.withdrawtext1}
              <br />
              <br />
            </p>
          </div>

          <div class="card-header border-bottom-0">
            <h3 class="h4 mb-1">Withdraw History</h3>
            <div class="row">
              <div class="col-lg-12 col-md-12 fs-5 fw-bold text-dark text-sm-end">
                Download Payout History (Coming soon){" "}
                <a href="#" class="btn btn-sm btn-outline-success">
                  {" "}
                  <i class="fe fe-download"></i>
                </a>
              </div>
            </div>
          </div>

          <div class="table-responsive border-0">
            <table class="table mb-0 text-nowrap">
              <thead class="table-primary">
                <tr>
                  <th scope="col" class="border-0">
                    S/N
                  </th>
                  <th scope="col" class="border-0">
                    ORDER NO.
                  </th>
                  <th scope="col" class="border-0">
                    AGENT NAME
                  </th>
                  <th scope="col" class="border-0">
                    AMOUNT
                  </th>
                  <th scope="col" class="border-0">
                    STATUS
                  </th>
                  <th scope="col" class="border-0">
                    DATE REGISTERED
                  </th>
                </tr>
              </thead>
              <tbody>{renderTable()}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
};

export default PayoutsAPP;
