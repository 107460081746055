import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { register } from "../features/Users";
import Dashboard from "./Dashboard";

const HomeApp = () => {
  const dispatch = useDispatch();

  const [state, setState] = React.useState({
    user: "",
  });

  const jwttokens = {
    jwt: localStorage.getItem("token"),
  };

  console.log(jwttokens);

  useEffect(() => {
    const post = async () => {
      axios({
        method: "post",
        url: "/login/protected.php",
        data: jwttokens,
        config: { headers: { "Content-Type": "multiport/form-data" } },
      })
        .then(function (response) {
          console.log(response.data.user_data.data.fullname);
          if (response.data.message === "success") {
            localStorage.setItem(
              "user_data",
              JSON.stringify(response.data.user_data.data)
            );
            localStorage.setItem("fullname", response.data.user_data.data.fullname);
            setState({
              user: response.data.user_data.data,
            });
          }
        })
        .catch((response) => {
         // console.log(response);
        });
    };
    post();
  });

  var fname = state.user.fullname;

  var email = state.user.email;

  var userdata = { fullname: fname, email: email };

  localStorage.setItem("fullname", fname);

  dispatch(register(userdata));

  let get_user_data0;

  get_user_data0 = window.localStorage.getItem("user_data");
  //console.log(get_user_data);

  //dispatch(register(JSON.parse(get_user_data0)));

  return (
    <div>
      <Dashboard getname ={fname}/>
    </div>
  );
};

export default HomeApp;
