import React from "react";

import axios from "axios";

import { useState } from "react";

import "./Loader.css";

import { Link } from "react-router-dom";

import jsPDF from "jspdf";

import html2canvas from "html2canvas";

const SchoolsApp = (getemail) => {
  const [schools, setSchools] = useState(null);

  const [schooledit, setSchoolsEdit] = useState(null);

  const [schoolsave, setSchoolsSave] = useState(null);

  const [message, setMessage] = useState("");

  const [tokenid, setTokenId] = useState("");

  const [tokendel, setTokenDel] = useState("");

  const [tokensave, setTokenSave] = useState("");

  const email = getemail.getemail;

  const fullname = getemail.getemail;

  const [state, setState] = React.useState({
    color: "",
    alerts: "",
    info: "",
  });

  const exportPdf = () => {
    html2canvas(document.querySelector("#capture"), { quality: 1 }).then(
      (canvas) => {
        //document.body.appendChild(canvas); // if you want see your screenshot in body.
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, "PNG", 10, 10, pdfWidth, pdfHeight);
        pdf.save(schoolsave.schoolname + ".pdf");
      }
    );
  };

  const schoolDelete = async () => {
    const response_1 = await axios.post("./api/school_delete.php", {
      tokendel,
    });

    const view_1 = response_1.data;

    //console.log(view_1);

    if (view_1.message === "success") {
      window.location.reload(false);
    } else {
      alert("Something went wrong! Please check your network");
    }
  };

  const editData = async () => {
    const response_ = await axios.post("./api/school_fetch.php", { tokenid });

    const view_ = response_.data;

    setSchoolsEdit(view_);
  };

  const saveData = async () => {
    const response_0 = await axios.post("./api/school_fetch.php", {
      tokenid: tokensave,
    });

    const view_0 = response_0.data;

    setSchoolsSave(view_0);
  };

  //console.log(schooledit.schoolname);

  //let viewdata;

  const loader = {
    display: "grid",
    marginLeft: "300px",
    marginRight: "auto",
    position: "relative",
  };

  const fetchData = async () => {
    const response = await axios.post("./api/school_lists.php", { email });

    const view = response.data;

    if (view.message === "failed") {
      setMessage(view.message);
    } else {
      setSchools(view);
    }

    setState({
      alerts: "",
      info: "",
    });
  };

  const renderTable = () => {
    if (message === "failed") {
      return (
        <div className="col-md-12 py-5 mt-1 text-center">
          <img
            src="../assets/images/background/5.png"
            alt="Gallery image 3"
            class="gallery__img rounded-3"
            style={{ width: "30%" }}
          />

          <h4 className="fs-3 fw-bold mt-8">No Records Found</h4>
        </div>
      );
    } else if (schools) {
      return schools.map((school, index) => {
        const TimeDate = new Date(school.tm_date).toDateString();

        return (
          <div className="col-md-4">
            <div class="card bg-light shadow-sm card-stretch mb-5 mb-xxl-8">
              <div class="card-body">
                <h5 class="display-6 text-dark card-title">
                  {school.schoolname}
                </h5>
                <h6 class="card-subtitle fs-5 mb-2 text-dark">
                  Population - {school.schoolpopulation}
                </h6>
                <p class="card-text text-gray-800">{school.schooladdress}</p>

                <span className={school.color}>{school.status_code}</span>

                {school.status_code === "Completed" ? (
                  <div class="btn-group ms-6">
                    <button
                      class="btn btn-light btn-sm dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Menu
                    </button>
                    <div class="dropdown-menu">
                      <a
                        class="dropdown-item hoverable"
                        id={school.token}
                        onClick={(e) => setTokenSave(e.target.id)}
                      >
                        Download School Details
                      </a>
                    </div>
                  </div>
                ) : (
                  <div class="btn-group ms-6">
                    <button
                      class="btn btn-light btn-sm dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Menu
                    </button>
                    <div class="dropdown-menu">
                      <a
                        class="dropdown-item hoverable"
                        id={school.token}
                        onClick={(e) => setTokenId(e.target.id)}
                      >
                        Edit School Details
                      </a>
                      <a
                        class="dropdown-item hoverable"
                        id={school.token}
                        onClick={(e) => setTokenDel(e.target.id)}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalCenter"
                      >
                        Delete School
                      </a>
                      <div class="dropdown-divider"></div>
                      <a
                        class="dropdown-item hoverable"
                        id={school.token}
                        onClick={(e) => setTokenSave(e.target.id)}
                      >
                        Download School Details
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className="col-md-12 text-center py-15 mt-5">
          <div class="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
    }
  };

  const renderTable_0 = () => {
    if (schoolsave) {
      const pay = schoolsave.schoolpopulation * 200;
      return (
        <div className="col-md-12">
          <div class="offset-md-1 col-md-10">
            <div class="card-body p-3" id="capture">
              <h2 className="display-6 text-dark mb-5">
                {" "}
                {schoolsave.schoolname} School Details{" "}
              </h2>

              <div className="row">
                <div class="col-md-6 mb-6">
                  <span for="recipient-name" class="col-form-label fs-4 ">
                    Name of school:{" "}
                    <span className="fs-3 fw-bold text-dark">
                      {schoolsave.schoolname}
                    </span>
                  </span>
                </div>

                <div class="col-md-6 mb-6">
                  <span for="recipient-name" class="col-form-label fs-4 ">
                    Population of school:{" "}
                    <span className="fs-3 fw-bold text-danger">
                      {schoolsave.schoolpopulation}
                    </span>
                  </span>
                </div>

                <div class="col-md-12 mb-6">
                  <span for="recipient-name" class="col-form-label fs-4 ">
                    Address of school:{" "}
                    <span className="fs-3 fw-bold text-dark">
                      {schoolsave.schooladdress}
                    </span>
                  </span>
                </div>

                <div class="col-md-12 mb-6">
                  <span for="recipient-name" class="col-form-label fs-4 ">
                    Contact of school:{" "}
                    <span className="fs-3 fw-bold text-dark">
                      {schoolsave.schoolphone}
                    </span>
                  </span>
                </div>

                <hr />

                <div class="col-md-5 mb-6">
                  <span for="recipient-name" class="col-form-label fs-4 ">
                    Agent:{" "}
                    <span className="fs-3 fw-bold text-primary">
                      {schoolsave.fullname}
                    </span>
                  </span>
                </div>

                <div class="col-md-7 mb-6">
                  <span for="recipient-name" class="col-form-label fs-4 ">
                    Expected Income:{" "}
                    <span className="display-6 text-success">NGN{pay}.00</span>
                  </span>
                </div>
              </div>
            </div>

            <div className="d-grid mt-5">
              <button
                type="button"
                onClick={exportPdf}
                className="btn btn-dark"
              >
                Download PDF File
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="col-md-12 text-center py-15 mt-5">
          <div class="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
    }
  };

  const [school, setSchoolName] = useState("");
  const [populate, setPopulate] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");

  const [schoolErr, setFullNameErr] = useState({});
  const [populateErr, setEmailErr] = useState({});
  const [addressErr, setPasswordErr] = useState({});
  const [phoneErr, setPhoneErr] = useState({});

  const onSubmits = (e) => {
    e.preventDefault();

    const isValid = formValidations();
    //console.log("works");

    if (isValid) {
      const school_info = {
        schoolname: school,
        population: populate,
        schoolphone: phone,
        schooladdress: address,
        tokenid: tokenid,
        email: email,
        fullname: fullname,
      };

      setState({
        alerts: "alert alert-info fs-4 mt-3 mb-3 alert-dismissible fade show",
        info: "Loading....",
      });

      //console.log(users);

      axios({
        method: "post",
        url: "/api/school_edit.php",
        data: school_info,
        config: { headers: { "Content-Type": "multiport/form-data" } },
      })
        .then(function (response) {
          if (response.data.message === "success") {
            // console.log(response);
            setState({
              statusin: true,
              alerts:
                "alert alert-success fs-4 mt-3 mb-3 alert-dismissible fade show",
              info: "Successfully submitted form 👋",
            });
          } else {
            setState({
              statusin: false,
              alerts:
                "alert alert-danger fs-4 mt-3 mb-3 alert-dismissible fade show",
              info: "Update Failed. Try Again",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  //console.log(state.info);

  const formValidations = () => {
    const phoneErr = {};
    const schoolErr = {};
    const populateErr = {};
    const addressErr = {};

    let isValid = true;

    if (phone.trim().length < 11) {
      phoneErr.phonemsg = "Phone is too short";
      isValid = false;
    }

    if (!school) {
      schoolErr.schoolmsg = "School is required here";
      isValid = false;
    }

    if (!populate) {
      populateErr.populatemsg = "Population is required here";
      isValid = false;
    }

    if (!address) {
      addressErr.addressmsg = "Address is required here";
      isValid = false;
    }

    setFullNameErr(schoolErr);
    setEmailErr(populateErr);
    setPasswordErr(addressErr);
    setPhoneErr(addressErr);

    return isValid;
  };

  const renderTable_ = () => {
    if (schooledit) {
      return (
        <div className="col-md-12">
          <div class="offset-md-1 col-md-10">
            <div class="card-body p-3">
              <h2 className="display-6 text-dark mb-5">
                {" "}
                {schooledit.schoolname} - {schooledit.schoolpopulation} Students{" "}
              </h2>

              <form onSubmit={onSubmits} className="row">
                <div class="col-md-6 mb-3">
                  <label for="recipient-name" class="col-form-label">
                    Name of school:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter school name"
                    onChange={(e) => {
                      setSchoolName(e.target.value);
                      schoolErr.schoolmsg = "";
                      setState({
                        message: "",
                      });
                    }}
                  />
                  {Object.keys(schoolErr).map((key) => {
                    return (
                      <div className="text-danger fs-5 mt-3">
                        {schoolErr[key]}
                      </div>
                    );
                  })}
                </div>

                <div class="col-md-6 mb-3">
                  <label for="recipient-name" class="col-form-label">
                    Population of school:
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Enter school population"
                    onChange={(e) => {
                      setPopulate(e.target.value);
                      populateErr.populatemsg = "";
                      setState({
                        message: "",
                      });
                    }}
                  />
                  {Object.keys(populateErr).map((key) => {
                    return (
                      <div className="text-danger fs-5 mt-3">
                        {populateErr[key]}
                      </div>
                    );
                  })}
                </div>

                <div class="col-md-6 mb-3">
                  <label for="recipient-name" class="col-form-label">
                    Address of school:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter school address"
                    onChange={(e) => {
                      setAddress(e.target.value);
                      addressErr.addressmsg = "";
                      setState({
                        message: "",
                      });
                    }}
                  />
                  {Object.keys(addressErr).map((key) => {
                    return (
                      <div className="text-danger fs-5 mt-3">
                        {addressErr[key]}
                      </div>
                    );
                  })}
                </div>

                <div class="col-md-6 mb-3">
                  <label for="recipient-name" class="col-form-label">
                    Main Contact Phone Number:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter school phone number"
                    onChange={(e) => {
                      setPhone(e.target.value);
                      phoneErr.phonemsg = "";
                      setState({
                        message: "",
                      });
                    }}
                  />

                  <p className="mt-2 fw-bold text-primary">
                    E.g:- Director or Admin of school number.
                  </p>
                  {Object.keys(phoneErr).map((key) => {
                    return (
                      <div className="text-danger fs-5 mt-3">
                        {phoneErr[key]}
                      </div>
                    );
                  })}
                </div>

                <div className="d-grid">
                  <button type="submit" className="btn btn-dark">
                    Make Changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="col-md-12 text-center py-15 mt-5">
          <div class="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
    }
  };

  if (tokenid) {
    return (
      <div class="col-lg-9 col-md-8 col-12">
        <h2 className="display-6 text-primary mb-5">
          <Link to="../app/schools#">
            <i
              class="bi bi-arrow-left"
              onClick={() => {
                setTokenId(null);
              }}
            ></i>
          </Link>{" "}
          Edit Schools
        </h2>

        <div className="col-md-12 row" onMouseMove={editData} onTouchMove={fetchData}>
          <div className={state.alerts} role="alert">
            {state.info}
          </div>
          {renderTable_()}
        </div>
      </div>
    );
  } else if (tokensave) {
    return (
      <div class="col-lg-9 col-md-8 col-12">
        <h2 className="display-6 text-primary mb-5">
          <Link to="../app/schools#">
            <i
              class="bi bi-arrow-left"
              onClick={() => {
                setTokenSave(null);
              }}
            ></i>
          </Link>{" "}
          Download Details To PDF
        </h2>

        <div className="col-md-12 row" onMouseMove={saveData}>
          {renderTable_0()}
        </div>
      </div>
    );
  } else {
    return (
      <div class="col-lg-9 col-md-8 col-12">
        <div class="card px-4 py-4 mb-8">
          <h2 className="display-6 text-dark mb-5">All Schools</h2>
          <div
            class="alert bg-light-info text-dark alert-dismissible fade show"
            role="alert"
          >
            <p class="mb-4">
              Hi there! Please proceed to add schools and view them all in this section.
              Please send a message to <span className="fw-semi-bold">freelance-desk@smartedung.com
              or chat us on Telegram with link below.
              </span>
            </p>
            <a
              href="https://t.me/+aO5rVEkmkJ5mNzBk"
              target="_blank"
              class="btn btn-outline-dark btn-sm fs-4"
            >
              <i class="bi bi-telegram"></i> Telegaram group
            </a>
          </div>
        </div>

        <div className="row" onMouseMove={fetchData}>
          {renderTable()}
        </div>

        <div
          class="modal"
          id="exampleModalCenter"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <h4 className="fs-3 text-center">
                  <i class="fs-1 bi bi-info-circle text-warning"></i>
                  <br />
                  <br />
                  Are you sure you want to delete?
                </h4>
                <div class="mb-3 mt-4 d-flex justify-content-center">
                  <button
                    type="button"
                    class="btn btn-dark"
                    data-bs-dismiss="modal"
                  >
                    No
                  </button>
                  <button
                    type="button"
                    class="ms-5 btn btn-danger"
                    onClick={() => {
                      schoolDelete();
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default SchoolsApp;
