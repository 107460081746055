import React from "react";

import Typed from "typed.js";

import Navbar from "./Navbar";

import Footer from "../Footer";

import { Link } from "react-router-dom";

import { useEffect, useRef } from "react";

import Calculator from "./Calculator";

const Earn = () => {
  
  const handleindex = { zIndex: "1" };

  const el = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ["Earn?", "Make?"], // Strings to display
      // Speed settings, try diffrent values untill you get good results
      startDelay: 300,
      typeSpeed: 300,
      backSpeed: 100,
      backDelay: 100,
      smartBackspace: true,
      loop: true,
      showCursor: true,
      cursorChar: "|",
    });

    // Destropying
    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div>
      <Navbar />

      <div class="py-10 bg-white">
        <div class="container">
          <div class="row">
            <div class="col-lg-5 col-md-12 col-12 mb-12">
              <h1 class="display-2 fw-bold mb-3">
                How much do i <span class="text-success" ref={el}></span>
              </h1>

              <p class="h3 mb-3 ">
                Smart Edu Freelancers are being paid once after the sales of Smart Edu App.
                These means that you only get payment for just one school term.
                <br />
                <br />
                We offer you the chance to sell at your own price not below the
                fixed price but highe than the fixed price you are given and the
                gain is yours.
                <br />
                <br />
                E.g:- Smart Edu App = ₦800 per child for a school
                <br />
                <br />
                <span>
                  You Sell = ₦1500
                  <br />
                  You keep the ₦700 plus the amount you would be paid for{" "}
                  <span className="fw-bold">Freelance Service</span>
                </span>
              </p>
            </div>

            <Calculator />
          </div>
        </div>
      </div>

      <div class="py-5 py-lg-18 bg-light">
        <div class="container">
          <div class="row">
            <div class="col-xl-5 col-lg-6 col-12">
              <div class="mb-5  ">
                <h1 class="display-3 mb-4 fw-bold ">
                  Earn even more with{" "}
                  <span className="text-warning">Smart Invest!</span>
                </h1>
                <p class="lead mb-4 pe-xl-12 ">
                  Smart Invest is a platform where you can get paid for a full session 
                  (three terms) rather than one term payment.
                  To achieve this you would need to save your first and 
                  second payments with Smart Edu Inc and allow it to grow times three
                  (3).
                  <br />
                  <br />
                  imagine, ₦312,450 your actual earning multiply by 3 to give{" "}
                  <span className="fw-bold text-dark">₦937,350</span>
                </p>
                <Link to="../signup" class="btn btn-dark">
                  Create An Account
                </Link>
              </div>
            </div>

            <div class="offset-xl-4 col-xl-3 col-lg-3 col-md-12 col-12 mb-0 mb-lg-0 ">
              <div
                class="card mb-4 card-hover shadow-lg bg-light-gradient-bottom"
                style={handleindex}
              >
                <div class="card-body">
                  <i class="bi bi-graph-up fs-2 icon-shape icon-md rounded-3 bg-light-success text-dark-success mt-2"></i>
                  <h3 class="display-4 fw-bold mt-3 mb-0 text-danger">
                    ₦937,350
                  </h3>
                  <span>Your total saving earnings</span>
                </div>
              </div>

              <div className="position-relative mt-10 me-10">
                <div className="position-absolute bottom-0 end-0 me-md-n3 mb-md-n6 me-lg-n4 mb-lg-n4 me-xl-n6 mb-xl-n8 d-none d-md-block ">
                  <img
                    src="../assets/images/about/partnership.png"
                    width="600"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="py-8 py-lg-10 bg-white" id="location">
        <div class="container">
          <div class="row mb-10 align-items-center justify-content-center">
            <div class="col-md-10">
              <div class="row align-items-center ">
                <div class="col-xl-6 col-lg-7 col-md-12 col-12 text-center text-lg-start ">
                  <span class="text-primary mb-3 d-block text-uppercase fw-semi-bold ls-xl">
                    OUR LOCATIONS
                  </span>
                  <h2 class="mb-2 display-4 fw-bold mb-3">
                    Here are the lists of{" "}
                    <span class="text-primary">Locations</span>
                    <br />
                    we can service currently.
                  </h2>
                  <hr class="my-5" />
                </div>

                <div class="offset-xl-1 col-xl-5 col-lg-5 col-12 mb-6 mb-lg-0 text-center ">
                  <div class="col-md-12 col-12 px-1">
                  <div class="table-responsive">
                    <table class="table table-borderless table-lg fs-4">
                      <thead>
                        <tr >
                          <th scope="col" className="fs-4 fw-bold">#</th>
                          <th scope="col" className="fs-4 fw-bold">States</th>
                          <th scope="col" className="fs-4 fw-bold">Areas</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>Lagos State</td>
                          <td>Available for all areas</td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>Ogun State</td>
                          <td>Limited to some areas</td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>Ibadan</td>
                          <td>Limited to some areas</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer/>

    </div>
  );
};

export default Earn;
