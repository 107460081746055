import React, { useState } from "react";

import axios from "axios";

import { useSelector } from "react-redux";

import OrderList from "./OrderList";

const OrdersAPP = () => {
  const getusers0 = useSelector((state) => state.user.value);

  const getname = getusers0.fullname;

  const getphone = getusers0.phone;

  const getaddress = getusers0.address;

  const getemail = getusers0.email;

  const [state, setState] = React.useState({
    user: getname,
    phone: getphone,
    address: getaddress,
    proposal: "1",
    color: "",
    alerts: "",
    info: "",
    isSubmit: "",
    buttonname: "Update Profile",
  });

  const [disable, setDisable] = React.useState(false);

  const onSubmit = (b) => {
    b.preventDefault();

    const user_contact = {
      address: state.address,
      fullname: state.user,
      phone: state.phone,
      proposal: state.proposal,
      email: getemail,
    };
    // console.log(user_contact);

    axios({
      method: "post",
      url: "/api/order.php",
      data: user_contact,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        if (response.data.message === "success") {
          setState({
            statusin: true,
            alerts:
              "alert alert-success fs-4 mt-3 mb-3 alert-dismissible fade show",
            info: "Proposal Order Was Successfully Submitted. 👋",
            isSubmit: "spinner-grow spinner-grow-sm",
            buttonname: "Order Completed",
            user: getname,
            phone: getphone,
            address: getaddress,
            proposal: "1",
          });

          window.scrollTo(300, 300);
        } else if (response.data.message === "exist") {
          setState({
            statusin: true,
            alerts:
              "alert alert-danger fs-4 mt-3 mb-3 alert-dismissible fade show",
            info: "Order is exist and still pending please wait patiently. ⏰",
            isSubmit: "spinner-grow spinner-grow-sm",
            buttonname: "Order Exists",
            user: getname,
            phone: getphone,
            address: getaddress,
            proposal: "1",
          });

          window.scrollTo(300, 300);
        } else {
          setState({
            statusin: false,
            alerts:
              "alert alert-danger fs-4 mt-3 mb-3 alert-dismissible fade show",
            info: "Request Failed. Looks like you submitting similar requests ☹️",
            isSubmit: "spinner-grow spinner-grow-sm",
            buttonname: "Order Failed",
            user: getname,
            phone: getphone,
            address: getaddress,
            proposal: "1",
          });
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  return (
    <div class="col-lg-9 col-md-8 col-12">
      <div class="card">
        <div className={state.alerts} role="alert">
          {state.info}
        </div>

        <div class="card-header">
          <h3 class="mb-0">Order Proposal</h3>
          <p class="mb-0">
            Get proposals within 1-3 days depending on location.
          </p>
        </div>

        <div class="card-body">
          <div>
            <form class="row" onSubmit={onSubmit}>
              <div class="mb-3 col-12 col-md-6">
                <label class="form-label" for="fname">
                  Number of proposal(s)
                </label>
                <input
                  type="number"
                  id="proposal"
                  class="form-control"
                  value={state.proposal}
                  placeholder="Enter Fullname"
                  required
                  onChange={(e) => {
                    setState({
                      proposal: e.target.value,
                      buttonname: "Update Profile",
                    });
                    setDisable(false);
                  }}
                />
              </div>

              <div class="mb-3 col-12 col-md-6">
                <label class="form-label" for="fname">
                  Agent Name
                </label>
                <input
                  type="text"
                  id="fname"
                  class="form-control"
                  value={state.user}
                  placeholder="Enter Fullname"
                  required
                  onChange={(e) => {
                    setState({
                      user: e.target.value,
                      buttonname: "Update Profile",
                    });
                    setDisable(false);
                  }}
                />
              </div>

              <div class="mb-3 col-12 col-md-8">
                <label class="form-label" for="phone">
                  Phone
                </label>
                <input
                  type="tel"
                  id="phone"
                  class="form-control"
                  value={state.phone}
                  placeholder="Enter Phone Number"
                  required
                  onChange={(e) => {
                    setState({
                      phone: e.target.value,
                      buttonname: "Update Profile",
                    });
                    setDisable(false);
                  }}
                />
              </div>

              <div class="mb-3 col-12 col-md-12">
                <label class="form-label" for="address">
                  Delivery Address
                </label>
                <input
                  type="text"
                  id="address"
                  class="form-control"
                  value={state.address}
                  placeholder="Enter Address"
                  required
                  onChange={(e) => {
                    setState({
                      address: e.target.value,
                      buttonname: "Update Profile",
                    });
                    setDisable(false);
                  }}
                />
              </div>

              <div class="col-12">
                <button class="btn btn-primary" type="submit">
                  <span>{state.buttonname}</span>{" "}
                  <span className={state.isSubmit}></span>
                </button>
              </div>
            </form>
          </div>
        </div>

        <OrderList getemail={getemail} />
      </div>
    </div>
  );
};

export default OrdersAPP;
