import React, { useEffect } from "react";

import { useDispatch } from "react-redux";

import { register } from "../features/Users";

import axios from "axios";

import { Navigate } from "react-router-dom";

const Auth = () => {
  const dispatch = useDispatch();

  const [state, setState] = React.useState({
    user: "",
  });

  const jwttokens = {
    jwt: localStorage.getItem("token_id"),
  };

  useEffect(() => {
    const post = async () => {
      axios({
        method: "post",
        url: "/auth/protected-users.php",
        data: jwttokens,
        config: { headers: { "Content-Type": "multiport/form-data" } },
      })
        .then(function (response) {
          // console.log(response.user_data.data.fullname);
          //console.log(response)
          if (response.data.message === "success") {
            localStorage.setItem(
              "user_data",
              JSON.stringify(response.data.user_data.data)
            );
            setState({
              user: response.data.user_data.data,
            });
          }
        })
        .catch((response) => {
          console.log(response);
        });
    };
    post();
  });

  var fname = state.user.fullname;

  var email = state.user.email;

  var phone = state.user.phone;

  var address = state.user.address;

  var job = state.user.job;

  var birth = state.user.birth;

  var userdata = {
    fullname: fname,
    email: email,
    phone: phone,
    address: address,
    job: job,
    birth: birth,
  };

  // dispatch(register(userdata));

  const get_user_data = window.localStorage.getItem("user_data");

  console.log(get_user_data);

  dispatch(register(JSON.parse(get_user_data)));

  if (state.user) {

    return <Navigate to={"../app/home"} />;

  } else {
    localStorage.setItem("token", "");
    localStorage.setItem("tokenid", "");
    localStorage.setItem("user_data", "");
    localStorage.setItem("token_id", "");
    return <Navigate to={"../login"} />;
  }
};

export default Auth;
