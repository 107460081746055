import React from "react";

import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <div class="container-fluid bg-white">
      <div class="row align-items-center min-vh-100">

      <div
          class="col-lg-6 d-lg-flex align-items-center w-lg-50 
          min-vh-lg-100 position-fixed-lg bg-cover bg-light top-0
            right-0"
          style={{ backgroundImage: `url(../assets/images/about/contact.jpg)` }}
        >
          <div class="px-4 px-xl-20 py-20 py-lg-20"></div>
        </div>
        
        <div class="col-lg-6 col-md-12 col-12">
          <div class="px-xl-20 px-md-8 px-4 py-8 py-lg-0">
            <div class="d-flex justify-content-between mt-4 mb-7 align-items-center">
              <Link to="/">
                <img
                  className="me-10"
                  src="../assets/images/brand/logo/smfreelance-2.png"
                  width="300"
                  alt=""
                />
              </Link>
            </div>
            <div class="text-dark ms-2">
              <h1 class="display-4 fw-bold">Get In Touch.</h1>
              <p class="lead text-dark">
                We are closer to you than ever. Contact us through the following
                channels available , For enquires please call the number below
                or contact us via E-mail.
              </p>
              <div class="mt-8">
                <p class="fs-4 mb-4">
                  If you are seeking support please visit our{" "}
                  <a href="https://t.me/+aO5rVEkmkJ5mNzBk" target="_blank">
                    Telegram Page
                  </a>{" "}
                  before reaching out directly.
                </p>

                <p class="fs-4">
                  <i
                    class="bi bi-telephone text-primary
                      me-2"
                  ></i>{" "}
                  + (234) 816 560 2584
                </p>
                <p class="fs-4">
                  <i
                    class="bi bi-envelope-open
                      text-primary me-2"
                  ></i>{" "}
                  freelance-desk@smartedung.com
                </p>
                <p class="fs-4 d-flex">
                  <i
                    class="bi bi-geo-alt
                      text-primary me-2"
                  ></i>{" "}
                  27, Tunde Alabi Crescent, Victory Estate, Ejigbo, Lagos
                </p>
              </div>
              <div class="mt-10">
                <a href="#" class="text-muted me-3">
                  <i class="fab fa-facebook fs-3"></i>
                </a>

                <a href="#" class="text-muted me-3">
                  <i class="fab fa-twitter fs-3"></i>
                </a>

                <a href="#" class="text-muted">
                  <i class="fab fa-github fs-3"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        
      </div>
    </div>
  );
};

export default Contact;
