import React, { useState } from "react";

import axios from "axios";

import { useSelector } from "react-redux";

import { Navigate } from "react-router-dom";

const Form = () => {
  const getusers = useSelector((state) => state.user.value);

  const fullname = getusers.fullname;

  const email = getusers.email;

  const [state, setState] = React.useState({
    statusin: false,
    message: "",
    isSubmit: "",
    buttonname: "Submit Form",
    loggedIn: false,
  });

  const [school, setSchoolName] = useState("");
  const [populate, setPopulate] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [messages, setMessage] = useState("");

  const [schoolErr, setFullNameErr] = useState({});
  const [populateErr, setEmailErr] = useState({});
  const [addressErr, setPasswordErr] = useState({});
  const [phoneErr, setPhoneErr] = useState({});

  const onSubmits = (e) => {
    e.preventDefault();

    const isValid = formValidations();
    console.log("works");

    if (isValid) {
      const school_info = {
        schoolname: school,
        population: populate,
        schoolphone: phone,
        schooladdress: address,
        messages: messages,
        email: email,
        fullname: fullname,
      };

      //console.log(users);

      axios({
        method: "post",
        url: "/api/school_upload.php",
        data: school_info,
        config: { headers: { "Content-Type": "multiport/form-data" } },
      })
        .then(function (response) {
          if (response.data.message === "success") {
            // console.log(response);

            axios({
              method: "post",
              url: "/login/auth.php",
              data: { email: email },
              config: { headers: { "Content-Type": "multiport/form-data" } },
            })
              .then(function (response) {
                if (response.data.message === "success") {
                  console.log(response.data.message);
                  localStorage.setItem("token_id", response.data.token);

                  setState({
                    statusin: true,
                    message: "Successfully submitted form 👋",
                    isSubmit: "spinner-grow spinner-grow-sm",
                    buttonname: "School Uploaded",
                  });

                  setTimeout(
                    setState({
                      loggedIn: true,
                    }),
                    12000
                  );

                } else {
                  setState({
                    loggedIn: false,
                  });
                }
              })
              .catch((err) => {
                // console.log(err);
              });
          } else if (response.data.message === "exist") {
            setState({
              statusin: false,
              message: "Seems this details already exist. Check again!",
              isSubmit: "spinner-grow spinner-grow-sm",
              buttonname: "School Failed",
            });
          } else {
            setState({
              statusin: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const formValidations = () => {
    const phoneErr = {};
    const schoolErr = {};
    const populateErr = {};
    const addressErr = {};

    let isValid = true;

    if (phone.trim().length < 11) {
      phoneErr.phonemsg = "Phone is too short";
      isValid = false;
    }

    if (!school) {
      schoolErr.schoolmsg = "School is required here";
      isValid = false;
    }

    if (!populate) {
      populateErr.populatemsg = "Population is required here";
      isValid = false;
    }

    if (!address) {
      addressErr.addressmsg = "Address is required here";
      isValid = false;
    }

    setFullNameErr(schoolErr);
    setEmailErr(populateErr);
    setPasswordErr(addressErr);
    setPhoneErr(addressErr);

    return isValid;
  };

  if (state.loggedIn) {

    setTimeout(( window.location.reload(false)), 5000);

    return <Navigate to={"../app/auths"} />;

  } else {
    return (
      <div
        class="modal fade gd-example-modal-lg"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="exampleModalLabelOne">
                Add New School
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <form onSubmit={onSubmits} className="row">
                <div class="col-md-6 mb-3">
                  <label for="recipient-name" class="col-form-label">
                    Name of school:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter school name"
                    onChange={(e) => {
                      setSchoolName(e.target.value);
                      schoolErr.schoolmsg = "";
                      setState({
                        message: "",
                        buttonname: "Submit Form",
                      });
                    }}
                  />
                  {Object.keys(schoolErr).map((key) => {
                    return (
                      <div className="text-danger fs-5 mt-3">
                        {schoolErr[key]}
                      </div>
                    );
                  })}
                </div>

                <div class="col-md-6 mb-3">
                  <label for="recipient-name" class="col-form-label">
                    Population of school:
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Enter school population"
                    onChange={(e) => {
                      setPopulate(e.target.value);
                      populateErr.populatemsg = "";
                      setState({
                        message: "",
                        buttonname: "Submit Form",
                      });
                    }}
                  />
                  {Object.keys(populateErr).map((key) => {
                    return (
                      <div className="text-danger fs-5 mt-3">
                        {populateErr[key]}
                      </div>
                    );
                  })}
                </div>

                <div class="col-md-6 mb-3">
                  <label for="recipient-name" class="col-form-label">
                    Address of school:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter school address"
                    onChange={(e) => {
                      setAddress(e.target.value);
                      addressErr.addressmsg = "";
                      setState({
                        message: "",
                        buttonname: "Submit Form",
                      });
                    }}
                  />
                  {Object.keys(addressErr).map((key) => {
                    return (
                      <div className="text-danger fs-5 mt-3">
                        {addressErr[key]}
                      </div>
                    );
                  })}
                </div>

                <div class="col-md-6 mb-3">
                  <label for="recipient-name" class="col-form-label">
                    Main Contact Phone Number:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter school phone number"
                    onChange={(e) => {
                      setPhone(e.target.value);
                      phoneErr.phonemsg = "";
                      setState({
                        message: "",
                        buttonname: "Submit Form",
                      });
                    }}
                  />

                  <p className="mt-2 fw-bold text-primary">
                    E.g:- Director or Admin of school number.
                  </p>
                  {Object.keys(phoneErr).map((key) => {
                    return (
                      <div className="text-danger fs-5 mt-3">
                        {phoneErr[key]}
                      </div>
                    );
                  })}
                </div>

                <div class="mb-3">
                  <label for="message-text" class="col-form-label">
                    Note to Smart Edu Team (Optional):
                  </label>
                  <textarea
                    class="form-control"
                    id="message-text"
                    placeholder="Message to Team..."
                    onChange={(e) => {
                      setMessage(e.target.value);
                      setState({
                        message: "",
                        buttonname: "Submit Form",
                      });
                    }}
                  ></textarea>
                </div>

                <div class="mb-3 d-flex justify-content-between ">
                  <p className="mt-2 fw-bold ms-2">
                    Note you would be paid once the school pays for the App.
                    With <i class="text-danger bi bi-suit-heart-fill"></i> From
                    us!
                  </p>
                  <button type="submit" class="btn btn-success btn-sm">
                    <span>{state.buttonname}</span>{" "}
                    <span className={state.isSubmit}></span>
                  </button>
                </div>
                <div class="text-center mt-1 fw-bold fs-3">
                  <p className="text-center">{state.message}</p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Form;
