import React from "react";

import { Link } from "react-router-dom";

import Graph from "./Graph";

import { useSelector } from "react-redux";

import SchoolTable from "./SchoolTablle";

const EarningsAPP = () => {

  const getusers0 = useSelector((state) => state.user.value);

  const gettotal = getusers0.total;

  const getrevenue = getusers0.revenue;

  const getinvest = getusers0.invest;

  const getbonus = getusers0.bonus;

  const getemail = getusers0.email;

  const plan_type = getusers0.plan_type;

  let revenue = getrevenue

  let bonus = getbonus

  let total, bonusper, revenueper, investper, graphper, plan_amt;


  if(plan_type == "NULL"){
    plan_amt = 100000;
  } 
  else if(plan_type == "BASIC_PLAN_UPGRADE"){
    plan_amt = 300000;
  }
  else if(plan_type == "STANDARD_PLAN_UPGRADE"){
    plan_amt = 500000;
  } 
  else if(plan_type == "PLATINIUM_PLAN_UPGRADE"){
    plan_amt = 1000000;
  }

  if (revenue == 0){
   total = bonus
   bonusper = (bonus/5000 * 100)
   revenueper = (revenue/plan_amt * 100)
   investper = (getinvest/plan_amt * 100)

   graphper = Math.round(revenueper);

  }else{
   total = gettotal
   bonusper = (bonus/5000 * 100)
   revenueper = (revenue/plan_amt * 100)
   investper = (getinvest/plan_amt * 100)

   graphper = Math.round(revenueper);

  }

  return (
    <div class="col-lg-9 col-md-8 col-12">
      <div class="card mb-4">
        <div class="card-body">
          <h3 class="mb-0">Earnings</h3>
          <p class="mb-0">
            You have full control to manage your own account setting.
          </p>
        </div>
      </div>

      <div class="card mb-4">
        <div class="card-header d-flex justify-content-between">
          <h4 class="mb-0">Earnings Details</h4>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-12 col-12 mb-3 mb-lg-0">
              <div>
                <i class="bi bi-wallet icon-shape icon-sm rounded-3 bg-light-success text-dark-success mt-2"></i>
                <h3 class="display-4 fw-bold mt-3 mb-0">₦{total}.0</h3>
                <span>Your total earnings</span>
                <br/><br/>
                <span className="mt-6 fw-semi-bold fs-5 text-danger">Limit of ₦{plan_amt} Earning.
                </span>
                <br/>
                <Link to='../app/upgrade' class="btn btn-danger btn-sm text-white">
                      Upgrade Now
                 </Link>
                <hr class="my-4" />
                <div class="row">
                  <div class="col-auto">
                    <span class="badge bg-success">
                      <i class="fe fe-trending-up fs-6 me-2"></i>{graphper}%
                    </span>
                  </div>
                </div>
                <p class="mb-0 lh-1.5">
                  Update your payout method in settings.
                </p>
              </div>
            </div>

            <div class="col-xl-8 col-lg-8 col-md-12 col-12">
              <Graph getemail={getemail}/>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-12 col-12">
          <div class="card mb-4">
            <div class="p-4">
              <span class="icon-shape icon-sm bg-light-primary text-dark-primary rounded-3">
                <i class="bi bi-cash-coin"></i>
              </span>
              <h2 class="h1 fw-bold mb-0 mt-4 lh-1">₦{getrevenue}.0</h2>
              <p>Revenue</p>
              <div class="progress bg-light-primary" style={{ height: "2px" }}>
                <div
                  class="progress-bar"
                  role="progressbar"
                  style={{ width: revenueper+'%'}}
                  aria-valuenow="65"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-12">
          <div class="card mb-4">
            <div class="p-4">
              <span class="icon-shape icon-sm bg-light-success text-dark-success rounded-3">
                <i class="bi bi-piggy-bank-fill"></i>
              </span>
              <h2 class="h1 fw-bold mb-0 mt-4 lh-1">₦{getinvest}.0</h2>
              <p>Smart Invest Balance</p>
              <div class="progress bg-light-success" style={{ height: "2px" }}>
                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  style={{ width: investper+"%" }}
                  aria-valuenow="45"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-12">
          <div class="card mb-4">
            <div class="p-4">
              <span class="icon-shape icon-sm bg-light-warning text-dark-warning rounded-3">
                <i class="bi bi-gift"></i>
              </span>
              <h2 class="h1 fw-bold mb-0 mt-4 lh-1">₦{getbonus}.0</h2>
              <p>Bonus Earning</p>
              <div class="progress bg-light-warning" style={{ height: "2px" }}>
                <div
                  class="progress-bar bg-warning"
                  role="progressbar"
                  style={{ width: bonusper+'%' }}
                  aria-valuenow="35"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-4">
       <SchoolTable getemail={getemail}/>
      </div>
    </div>
  );
};

export default EarningsAPP;
