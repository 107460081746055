import React from "react";

import Typed from "typed.js";

import Navbar from "./Navbar";

import Footer from "../Footer";

import { Link } from "react-router-dom";

import { useEffect, useRef } from "react";

const About = () => {
  const handleindex = { zIndex: "1" };

  const el = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ["Reliable", "Swift", "Impactful", "Smart Edu Freelance"], // Strings to display
      // Speed settings, try diffrent values untill you get good results
      startDelay: 300,
      typeSpeed: 300,
      backSpeed: 100,
      backDelay: 100,
      smartBackspace: true,
      loop: true,
      showCursor: true,
      cursorChar: "|",
    });

    // Destropying
    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div className="bg-white">
      <Navbar />

      <div class="py-10">
        <div class="container">
          <div class="row">
            <div class="col-lg-5 col-md-12 col-12 mb-12">
              <h1 class="display-2 fw-bold mb-5">
                Hi there, we’re{" "}
                <span class="text-primary" ref={el}>
                 
                </span>
              </h1>

              <p class="h2 mb-3 ">
                An influential platform that is set to create career
                opportunities and wealth creation for people. Smart Edu
                Freelance is owned by Smart Edu Inc.
              </p>
            </div>

            <div class="offset-xl-2 col-xl-5 col-lg-5 col-12 mb-6 mb-lg-0 order-lg-2 text-center ">
              <div class="col-md-12 col-12 px-1">
                <figure
                  class="gallery__item gallery__item--3 mb-0 position-relative"
                  style={handleindex}
                >
                  <img
                    src="../assets/images/about/freelance2.jpg"
                    alt="Gallery image 3"
                    class="gallery__img rounded-3"
                    style={{ width: "100%", height: "100%" }}
                  />
                  <a
                    class="position-absolute mt-2 ms-n4"
                    data-bs-toggle="tooltip"
                    data-placement="top"
                    title="Verifed"
                  >
                    <img
                      src="../assets/images/svg/checked-mark.svg"
                      alt=""
                      height="50"
                      width="50"
                    />
                  </a>
                </figure>
                <div className="position-relative">
                  <div className="opacity-25 pt-10 position-absolute bottom-0 end-0 me-md-n8 mb-md-n6 me-lg-n8 mb-lg-n4 me-xl-n6 mb-xl-n8 d-none d-md-block ">
                    <img
                      src="../assets/images/pattern/dots-pattern.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 offset-right-md-6">
              <h1 class="display-4 fw-bold mb-3">Our global reach</h1>

              <p class="lead">
                Smart Edu Freelance in conjunction with Smart Edu Inc. has
                helped to Market Smart Edu App to an extent reach
              </p>
            </div>
            <div class="col-lg-3 col-md-6 col-6">
              <div class="border-top pt-4 mt-6 mb-5">
                <h1 class="display-3 fw-bold mb-0">20</h1>
                <p class="text-uppercase text-muted">Schools</p>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-6">
              <div class="border-top pt-4 mt-6 mb-5">
                <h1 class="display-3 fw-bold mb-0">1k</h1>
                <p class="text-uppercase text-muted">Freelancers</p>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-6">
              <div class="border-top pt-4 mt-6 mb-5">
                <h1 class="display-3 fw-bold mb-0">3</h1>
                <p class="text-uppercase text-muted">Available Locations</p>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-6">
              <div class="border-top pt-4 mt-6 mb-5">
                <h1 class="display-3 fw-bold mb-0">6.8M</h1>
                <p class="text-uppercase text-muted">Payouts</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="py-8 py-lg-18 bg-light-success">
        <div class="container">
          <div class="row mb-6 align-items-center justify-content-center">
            <div class="col-md-10">
              <div class="row align-items-center ">
                <div class="col-xl-6 col-lg-7 col-md-12 col-12 order-1 text-center text-lg-start ">
                  <span class="text-dark mb-3 d-block text-uppercase fw-semi-bold ls-xl">
                    MARKETING MANAGER
                  </span>
                  <h2 class="mb-2 display-4 fw-bold mb-3">
                    Hi, I’m <span class="text-success">Deborah Funmilayo</span>,
                    <br />I would be your guide.
                  </h2>
                  <p class="fs-3 pe-6 text-dark">
                    Would explain more details to you and also discuss to you on
                    how you can carry out the marketing jobs. What you need to
                    understand about the Smart Edu Web App and how it works,
                    Marketing ideas and operations.
                    <br />
                    <br />
                    Join the telegram group now to get started
                  </p>

                  <hr class="my-5" />

                  <a
                    href="https://t.me/+aO5rVEkmkJ5mNzBk"
                    target="_blank"
                    class="btn btn-outline-dark mb-2 fs-3"
                  >
                    <i class="bi bi-telegram fs-3"></i> Join Telegram Group
                  </a>
                </div>

                <div class="offset-xl-1 col-xl-5 col-lg-5 col-12 mb-6 mb-lg-0 order-lg-2 text-center ">
                  <img
                    src="../assets/images/about/sales-reps.png"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="py-lg-16 py-10 bg-white">
        <div class="container">
          <div class="row justify-content-center text-center">
            <div class="col-md-9 col-12">
              <h2 class="display-4 text-dark">How much do you earn with us?</h2>
              <p class="lead text-dark px-lg-12 mb-6">
                Check out the&nbsp;
                <span class="text-primary fw-bold">Earnings Calculator</span>
                &nbsp;to know how much you earn on each school
              </p>

              <div class="d-grid d-md-block">
                <Link to="../earnings" class="btn btn-dark round-4">
                  Earnings Calculator
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
