import React, {useEffect} from "react";

import Navbar from "./About/Navbar";

import Footer from "./Footer";

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div class="bg-white">
      <Navbar />

      <div class="py-10">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12 col-12">
              <div class="text-center">
                <h1 class="display-3 fw-bold">Terms & Conditions</h1>
                <p class="lead px-8">
                  Read our terms below to learn more about your rights and
                  responsibilities as a Smart Edu Freelance user.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pb-10">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12 col-12">
              <div class="py-3 mb-5 border-bottom">
                <h3>
                  Effective date:{" "}
                  <span class="text-primary">23rd of March, 2022</span>
                </h3>
              </div>

              <h1 class="fw-bold mb-3">Terms of Use</h1>

              <p class="fs-4 mb-4">
                Please read these Terms carefully. By using Smart Edu App or
                signing up for an account, you’re agreeing to these Terms, which
                will result in a legal agreement between you and Smart Edu Inc.
                We’ll start with the basics, including a few definitions that
                should help you understand these Terms. Smart Edu
                Freelance(Smart Edu Inc “we,” or “us”) is a Freelance Marketing
                platform,(the “App”) that allows you to earn, network and
                connect with Smart Edu Inc. community.
              </p>

              <p class="mb-1 fs-4">
                <span class="text-dark fw-semi-bold">Email: </span>
                freelance-desk@smartedung.com
              </p>

              <p class="mb-1 fs-4">
                <span class="text-dark fw-semi-bold">Address: </span>27, Tunde
                Alabi Crescent, Victory Estate, Lagos - Nigeria
              </p>

              <div class="d-flex mt-5">
                <div class="">
                  <h3 class="fw-bold">A</h3>
                </div>
                <div class="ms-3">
                  <h3 class="fw-bold">Term</h3>
                  <p class="fs-4">
                    When you sign up and agree to these Terms, the Agreement
                    between you and Smart Edu Inc is formed, and the term of the
                    Agreement (the “Term”) will begin. The Term will continue
                    for as long as you have an account with Smart Edu Freelance.
                  </p>
                </div>
              </div>

              <div class="d-flex mt-3">
                <div class="">
                  <h3 class="fw-bold">B</h3>
                </div>
                <div class="ms-3">
                  <h3 class="fw-bold">Ownership</h3>
                  <p class="fs-4">
                    The Smart Edu Inc is the sole owner of Smart Edu Freelance
                    and shall have the right to exercise all incidents of
                    ownership.
                  </p>
                </div>
              </div>

              <div class="d-flex mt-3">
                <div class="">
                  <h3 class="fw-bold">C</h3>
                </div>
                <div class="ms-3">
                  <h3 class="fw-bold">Eligibility</h3>
                  <p class="fs-4">
                    In order to use the service, you must: <br />
                    <br />
                    - properly sign up with Smart Edu Freelance <br />
                    - Provide all necessary information needed for proper
                    registration <br />
                    - Be sure there is no outstanding payment during
                    reengagement of contract.
                    <br />
                  </p>
                </div>
              </div>

              <div class="mt-5">
                <h2 class="fw-bold">Privacy Policy</h2>
                <p class="fs-4">
                  Smart Edu Inc takes data privacy seriously. This privacy
                  policy explains who we are, how we collect, share and use
                  Personal Information, and how you can exercise your privacy
                  rights. We recommend that you read this privacy policy in full
                  to ensure you are fully informed. If you have any questions or
                  concerns about our use of your Personal Information, then
                  please contact us using the contact details provided below.
                </p>

                <h3 class="fw-bold mt-5">The Basics</h3>

                <div class="d-flex mt-3">
                  <div class="">
                    <h3 class="fw-bold">A</h3>
                  </div>
                  <div class="ms-3">
                    <h3 class="fw-bold">About Us</h3>
                    <p class="fs-4">
                      Smart Edu Inc is a school management system structured to
                      effectively provide accurate and swift services for
                      schools management.
                      <br />
                      Our Service enables our users to, among other things,
                      conduct E- Learning operations, carry out E- Report
                      activities get Websites and website management services.
                      We also provide other related services, such as real-time
                      data storage ,E- payment platform and many more.
                      <br />
                      <br />
                      Smart Edu Freelance is a section under Smart Edu Inc. and
                      it's a community of freelance marketers with the
                      opportunity to earn from Smart Edu Inc.
                    </p>
                  </div>
                </div>

                <div class="d-flex mt-3">
                  <div class="">
                    <h3 class="fw-bold">B</h3>
                  </div>
                  <div class="ms-3">
                    <h3 class="fw-bold">Key Terms</h3>
                    <p class="fs-4">
                      "Member" means any person or entity that is registered
                      with us to use the service.
                      <br />
                      <br />
                      "Personal Information" means any information that
                      identifies or can be used to identify an individual
                      directly or indirectly. Examples of Personal Information
                      include, but are not limited to, first and last name, date
                      of birth, email address, gender., or other demographic
                      information.
                    </p>
                  </div>
                </div>

                <h3 class="fw-bold mt-5">Privacy for Members</h3>
                <p class="fs-4">
                  This section applies to the Personal Information we collect
                  and process from a Member or potential Member through the
                  provision of the Service.
                </p>

                <div class="d-flex mt-3">
                  <div class="">
                    <h3 class="fw-bold">A</h3>
                  </div>
                  <div class="ms-3">
                    <h3 class="fw-bold">Information We Collect</h3>
                    <p class="fs-4">
                      The Personal Information that we collect depends on the
                      context of your interactions with Smart Edu Inc. However,
                      the Personal Information we collect broadly falls into the
                      following categories:
                      <br />
                      <br />
                      (i) Information you provide to us: You (or your
                      organization) may provide certain Personal Information to
                      us when you sign up for a Smart Edu Freelance account and
                      use the Service, consult with our customer service team,
                      send us an email.
                      <br />
                      <br />
                      This information may include: Business contact information
                      (such as your name, job title, organization, location,
                      phone number, email address, and country); Marketing
                      information (such as your contact preferences); Account
                      log-in credentials (such as your email address or username
                      and password when you sign up for an account with us);
                      Troubleshooting and support data (which is data you
                      provide or we otherwise collect in connection with support
                      queries we receive from you. This may include contact, the
                      content of your chats and other communications with us.
                      <br />
                      <br />
                      Payment information( Including your bank name and account
                      number)
                      <br />
                      <br />
                      (ii) Information we collect automatically Device
                      information: We collect information about the device and
                      applications you use to access the Service, such as your
                      IP address, your operating system, your browser ID,
                      viewfinder size, and other information about your system
                      and connection, your mobile device’s operating system or
                      platform, the type of mobile device you use, your mobile
                      device’s name and unique device ID.
                    </p>
                  </div>
                </div>

                <div class="d-flex mt-3">
                  <div class="">
                    <h3 class="fw-bold">B</h3>
                  </div>
                  <div class="ms-3">
                    <h3 class="fw-bold">Use of Personal Information</h3>
                    <p class="fs-4">
                      We may use the Personal Information we collect or receive
                      about you in reliance on our legitimate interests for the
                      following purposes:
                    </p>
                  </div>
                </div>

                <div class="mt-2">
                  <ul class="fs-4">
                    <li>
                      To ensure compliance with our Standard Terms of Use and
                      applicable law. This may include utilizing usage data and
                      developing tools and algorithms that help us prevent
                      violations.
                    </li>

                    <li>
                      To protect the rights and safety of Members, third
                      parties.
                    </li>

                    <li>
                      To provide, support and improve the Service. For example,
                      this may include sharing your information with third
                      parties in order to provide and support our Service or to
                      make certain features of the Service available to our
                      Members. When we share Personal Information with third
                      parties, we take steps to protect your information in a
                      manner that is consistent with applicable privacy laws.
                    </li>

                    <li>
                      To carry out other legitimate business purposes, as well,
                      such as the Advert zone platform on the App. ( This is
                      solely sending adverts to Email addresses given to us.)
                    </li>

                    <li>
                      To respond to your online inquiries and requests, and to
                      provide you with information and access to resources or
                      services that you have requested from us.
                    </li>

                    <li>
                      To manage the Smart Edu Sites and system administration
                      and security.
                    </li>
                  </ul>
                </div>

                <h3 class="fw-bold mt-4">How We Share Information</h3>
                <p class="fs-4">
                  We may share and disclose your Personal Information with our
                  subsidiaries or Affiliates and third parties.
                  <br />
                  <br />
                  (i) Our service providers: Sometimes, we share your
                  information with our third-party service providers working on
                  our behalf for the purposes described in this privacy policy.
                  For example, companies we’ve hired to help us provide and
                  support our Service or assist in protecting and securing our
                  systems and services and other business-related functions.
                  <br />
                  <br />
                  Other examples include analyzing data, hosting data, engaging
                  technical support for our Service, processing payments, and
                  delivering content. In connection to our services, we also use
                  a third party system Paystack for online payment on Smart Edu
                  Freelance.
                </p>
              </div>

              <div class="mt-5">
                <h2 class="fw-bold">Cookie Notice</h2>
                <p class="fs-4">
                  To help our partners manage their advertising and websites, we
                  offer many products, including AdSense, AdWords, Google
                  Analytics, and a range of DoubleClick-branded services. When
                  you visit a page or see an ad that uses one of these products,
                  either on Google services or on other sites and apps, various
                  cookies may be sent to your browser.
                </p>
                <p class="mb-6 fs-4">
                  These may be set from a few different domains, including
                  google.com, doubleclick.net, googlesyndication.com, or
                  googleadservices.com, or the domain of our partners’ sites.
                  Some of our advertising products enable our partners to use
                  other services in conjunction with ours (like an ad
                  measurement and reporting service), and these services may
                  send their own cookies to your browser. These cookies will be
                  set from their domains.
                </p>
              </div>

              <div class="mt-5">
                <h2 class="fw-bold">Our Security</h2>
                <p class="fs-4">
                  We take appropriate and reasonable technical and
                  organizational measures designed to protect Personal
                  Information from cases of loss, misuse, unauthorized access,
                  disclosure, alteration, and destruction, taking into account
                  the risks involved in the processing and the nature of the
                  Personal Information. If you have any questions about the
                  security of your Personal Information, you may contact us .
                </p>
                <p class="mb-6 fs-4">
                  Smart Edu Freelance accounts require a username and password
                  to log in. Members must keep their username and password
                  secure, and never disclose it to a third party. Because the
                  information in a Member’s Smart Edu App account is private. We
                  cannot resend forgotten passwords, We will only provide
                  Members with instructions on how to reset them.
                </p>
                <h2 class="fw-bold">Changes about terms</h2>
                <p class="fs-4">
                  If we change our terms of use we will post those changes on
                  this page. Registered users will be sent an email that
                  outlines changes made to the terms of use.
                </p>
                <p class="fs-4">
                  Questions? Please email us at{" "}
                  <a href="mailto:freelance-desk@smartedung.com">
                    freelance-desk@smartedung.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Terms;
