import React from "react";

import axios from "axios";

import { Navigate } from "react-router-dom";

const Auth = () => {

  const [state, setState] = React.useState({
    user: "",
  });

  const jwttokens = {
    jwt: localStorage.getItem("token_id"),
  };

    const post = async () => {
      axios({
        method: "post",
        url: "/auth/protected-users.php",
        data: jwttokens,
        config: { headers: { "Content-Type": "multiport/form-data" } },
      })
        .then(function (response) {
         // console.log(response.user_data.data.fullname);
          //console.log(response)
          if (response.data.message === "success") {
            localStorage.setItem(
              "user_data",
              JSON.stringify(response.data.user_data.data)
            );
            setState({
              user: response.data.user_data.data,
            });
          }
        })
        .catch((response) => {
          console.log(response);
        });
    };

    setTimeout(post, 5000);


  const get_user_data = window.localStorage.getItem("user_data");
  
  console.log(get_user_data);

  if (get_user_data) {

    setTimeout(( window.location.reload(false)), 3000)

    return(

      <div className="col-md-12 text-center py-10 mt-5">
              <div class="lds-facebook">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>

    );

  } else {
    localStorage.setItem("token", "");
    localStorage.setItem("tokenid", "");
    localStorage.setItem("user_data", "");
    localStorage.setItem("token_id", "");
    return <Navigate to={"../login"} />;
  }
};

export default Auth;
