import React, { useState, useEffect } from "react";

import Chart from "react-apexcharts";

import axios from "axios";

const Home = (getemail) => {
  const email = getemail.getemail;

  const [options, setChartData] = useState({});

  const [series, setSchoolPay] = useState([]);

  function fetchData() {
    
      const school = [];
      const pay = [];

      axios({
        method: "post",
        url: "/api/graph.php",
        data: { email: email },
        config: { headers: { "Content-Type": "multiport/form-data" } },
      })
        .then(function (response) {
          console.log("response", response);
          response.data.map((item) => {
            console.log("item", item);
            school.push(item.schoolname);
            pay.push(item.schoolpopulation);
          });

          setChartData({
            chart: {
              height: 350,
              type: "area",
              toolbar: {
                show: false,
              },
            },
            theme: {
              palette: "palette2",
            },
            dataLabels: {
              enabled: false,
            },

            fill: {
              type: "gradient",
              gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.5,
                opacityTo: 0.8,
                stops: [0, 90, 100],
              },
            },

            responsive: [
              {
                breakpoint: 1000,
                options: {
                  plotOptions: {
                    bar: {
                      horizontal: false,
                    },
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],

            xaxis: {
              type: "text",
              name: "school",
              categories: school,
            },

            yaxis: {
              type: "currency",
              labels: {
                formatter: function (value) {
                  return "₦" + value;
                },
              },
            },
          });

          setSchoolPay([
            {
              name: "Earnings",
              data: pay,
            },
          ]);

          console.log("school", school, "pay", pay);
        })
        .catch((err) => {
          // console.log(err);
        });
    
  }
  return (
    <div class="card mb-4" onMouseMove={fetchData} onTouchMove={fetchData}>
      <div class="card-header">
        <h3 class="h4 mb-0">Earnings Statistics</h3>
      </div>

      <div class="card-body">
        <div id="chart" class="apex-charts">
          <Chart options={options} series={series} type="area" height={280} />
        </div>
      </div>
    </div>
  );
};

export default Home;
