import React from 'react';

import { Link } from "react-router-dom";

const Calculator = () => {

    const handleindex = { zIndex: "1" };

    const [state, setState] = React.useState({
        schools: "1",
        students: "200",
      });
    
      let total;
      let color;
    
      if(state.students < 200){
         total = "Student number can't be less than 200";
         color = "text-danger fs-3";
      }else{
         total = "NGN "+state.students * state.schools * 300+".0";
         color = "text-success fw-bold";
      }

    return (
        <div className="offset-xl-2 col-xl-5 col-lg-6 col-md-12 "
        id="signup"
      >
        <div className="card shadow-lg" style={handleindex}>
          <div className="card-body p-6">
            <div className="mb-4">
              <div class="row mb-5">
                <div class="col-lg-5 col-md-4 col-12">
                  <h2>YOU EARN : </h2>
                </div>
                <div class="col-lg-7 col-md-8 col-12">
                  <h2 className={color}>{total}</h2>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div className="border-bottom"></div>
              <div className="text-center mt-n2  lh-1">
                <span className="bg-white px-2 fs-6">CALCULATOR</span>
              </div>
            </div>

            <form>
              <div className="mb-6">
                <label for="name" className="form-label ">
                  Number Of Schools
                </label>
                <input
                  type="number"
                  className="form-control form-control-lg"
                  name="schools"
                  id="schools"
                  value={state.schools}
                  onChange={handleChange}
                  placeholder="Number Of Schools"
                  required=""
                />
              </div>

              <div className="mb-3">
                <label for="name" className="form-label ">
                  Number Of Students
                </label>
                <input
                  type="number"
                  className="form-control form-control-lg"
                  name="students"
                  id="students"
                  value={state.students}
                  onChange={handleChange}
                  placeholder="Number Of Students"
                  required=""
                />
              </div>

              <div className="mt-8 mb-5">
                <div className="border-bottom"></div>
                <div className="text-center mt-n2  lh-1">
                  <span className="bg-white px-2 text-danger fs-6">
                    Get Started Now
                  </span>
                </div>
              </div>

              <div className="d-grid">
                <Link to="/signup" className="btn btn-dark">
                  Create Free Account Now
                </Link>
              </div>
            </form>
          </div>

          <div className="card-footer bg-white px-6 py-4">
            <p className="mb-0 fw-semi-bold">
              Please note that the payment is just once in that school
              term for{" "}
              <span className="text-primary">Freelance Marketers</span>
            </p>
          </div>
        </div>

        <div className="position-relative">
          <div className="opacity-25 position-absolute bottom-0 end-0 me-md-n3 mb-md-n6 me-lg-n4 mb-lg-n4 me-xl-n6 mb-xl-n8 d-none d-md-block ">
            <img src="../assets/images/pattern/dots-pattern.svg" alt="" />
          </div>
        </div>
      </div>
    )

    function handleChange(evt) {
        const value = evt.target.value;
        setState({
          ...state,
          [evt.target.name]: value,
        });
      }

}

export default Calculator
