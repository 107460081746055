import React from 'react'

import { Link } from "react-router-dom";

const Footer = () => {

    const year = new Date().getFullYear();

    return (
        <div class="pt-lg-10 pt-5 footer bg-white">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-12">
                 
              <div class="mb-4">
                <img src="../assets/images/brand/logo/smfreelance-2.png" width="300" alt=""/>
                <div class="mt-4 ms-3">
                  <p>Smart Edu Freelance is owned by Smart Edu Inc. An influential platform 
                      that is set to create career opportunities and wealth creation for people. </p>
                     
                  <div class="fs-4 mt-4">
                    <a href="https://t.me/+aO5rVEkmkJ5mNzBk" target="_blank" class="bi bi-telegram text-muted me-2"></a>
                    <a href="https://twitter.com/SmEduFreelance?s=09" target="_blank" class="mdi mdi-twitter text-muted me-2"></a>
                    <a href="https://www.instagram.com/smartedufreelance?r=nametag" target="_blank" class="mdi mdi-instagram text-muted "></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="offset-lg-1 col-lg-2 col-md-3 col-6">
              <div class="mb-4">
                   
                <h3 class="fw-bold mb-3">Company</h3>
                <ul class="list-unstyled nav nav-footer flex-column nav-x-0">
                  <li><Link to="/about" class="nav-link">About</Link></li>
                  <li><Link to="/earnings" class="nav-link">Earnings</Link></li>
                  <li><Link to="/blog" class="nav-link">Blog</Link></li>
                  <li><Link to="/contact" class="nav-link">Contact</Link></li>
                </ul>
              </div>
            </div>
            <div class="col-lg-2 col-md-3 col-6">
              <div class="mb-4">
                    
                <h3 class="fw-bold mb-3">Support</h3>
                <ul class="list-unstyled nav nav-footer flex-column nav-x-0">
                  <li><Link to="/support" class="nav-link">Help and Support</Link></li>
                  <li><a href="#" class="nav-link" >Get App (Coming soon)</a></li>
                  <li><Link to="/faq" class="nav-link">FAQ’s</Link></li>
                  <li><a href="#" class="nav-link">Watch Video</a></li>
                </ul>

              </div>
            </div>
            <div class="col-lg-3 col-md-12">
                
              <div class="mb-4">
                <h3 class="fw-bold mb-3">Get in touch</h3>
                <p>27, Tunde Alabi Crescent, Victory Estate, Lagos - Nigeria</p>
                <p class="mb-1">Email: <a href="mailto:freelance-desk@smartedung.com">freelance-desk@smartedung.com</a></p>
                <p>Phone: <span class="text-dark fw-semi-bold">(234) 816 560 2584</span></p>

              </div>
            </div>
          </div>
          <div class="row align-items-center g-0 border-top py-2 mt-6">
           
            <div class="col-lg-4 col-md-5 col-12">
                <span>© {year} , Smart Edu Inc. All Rights Reserved</span>
                </div>

              
            <div class="col-12 col-md-7 col-lg-8 d-md-flex justify-content-end">
                <nav class="nav nav-footer">
                    <Link to="/terms" class="nav-link ps-0">Privacy Policy</Link>
                    <Link to="/terms" class="nav-link px-2 px-md-3">Cookie Notice  </Link>
                    <Link to="/terms" class="nav-link">Terms of Use</Link>
                </nav>
            </div>
        </div>
        </div>
      </div>
    )
}

export default Footer
