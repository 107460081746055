import React from "react";

import axios from "axios";

import "./Loader.css";

import { useState } from "react";

import Auth from "./Auth";

const InvestTable = (getemail) => {
  const [schools, setSchools] = useState(null);

  const [message, setMessage] = useState("");

  const email = getemail.getemail;

  const [state, setState] = React.useState({
    color: "",
    alerts: "",
    info: "",
    investtoken: "",
    withdrawclass: "",
    withdrawtext: "",
    loggedIn: false,
    fetch: "",
    buttonname: " Fetch All School(s)"
  });

  const loader = {
    display: "grid",
    marginLeft: "300px",
    marginRight: "auto",
    position: "relative",
  };

  const Invest = async (clicked) => {
    console.log(clicked);

    axios({
      method: "post",
      url: "/api/invest.php",
      data: { token: clicked, email: email, fullname: email },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        // console.log(response.data.data.account_name);
        if (response.data.message === "success") {
          axios({
            method: "post",
            url: "/login/auth.php",
            data: { email: email },
            config: { headers: { "Content-Type": "multiport/form-data" } },
          })
            .then(function (response) {
              if (response.data.message === "success") {
                console.log(response.data.message);
                localStorage.setItem("token_id", response.data.token);

                setState({
                  withdrawtext:
                    "Hurray! Your Investment process has began. You would get a call from us within 24hours.",
                  withdrawclass:
                    "alert bg-light-success text-dark-success alert-dismissible fade show",
                });
                window.scrollTo(1400, 1400);
                setTimeout(fetchData, 5000);
                setTimeout(
                  setState({
                    loggedIn: true,
                  }),
                  8000
                );
              } else {
                setState({
                  loggedIn: false,
                });
              }
            })
            .catch((err) => {
              // console.log(err);
            });
        } else {
          setState({
            withdrawtext:
              "Request Failed. Investment process not completed! Please Check your connection ☹️",
            withdrawclass:
              "alert bg-light-danger text-dark-danger alert-dismissible fade show",
          });
          //window.scrollTo(700, 700);
        }
      })
      .catch((err) => {
        setState({
          withdrawtext:
            "Request Failed. Investment process not completed! Please Check your connection ☹️",
          withdrawclass:
            "alert bg-light-danger text-dark-danger alert-dismissible fade show",
        });
        //window.scrollTo(700, 700);
      });
  };

  const fetchData = async () => {
    const response = await axios.post("./api/school_lists.php", { email });

    const view = response.data;

    if (view.message === "failed") {
      setMessage(view.message);
    } else {
      setSchools(view);
    }

    setState({
      alerts: "",
      info: "",
      fetch: "spinner-border spinner-border-sm text-primary",
      buttonname: "Fetching All...."
    });
  };

  const renderTable = () => {
    if (message === "failed") {
      return (
        <tr className="mb-4">
            <td colspan="4">
              <div className="col-md-12 py-5 mt-1 text-center">
                <img
                  src="../assets/images/background/5.png"
                  alt="Gallery image 3"
                  class="gallery__img rounded-3"
                  style={{ width: "50%" }}
                />

                <h4 className="fs-3 fw-bold mt-8">No School Records Found</h4>
              </div>
            </td>
          
        </tr>
      );
    } else if (schools) {
      return schools.map((school, index) => {
        return (
          <tr className="mb-4">
            {school.status_code === "Completed" ? (
              <>
                <td class="align-middle border-top-0">
                  <a href="#">
                    <div class="d-lg-flex align-items-center">{index + 1}</div>
                  </a>
                </td>
                <td class="align-middle border-top-0">
                  {school.schoolname} - {school.schoolcode}
                </td>
                <td class="align-middle border-top-0">
                  ₦{school.schoolpopulation * 200}.0
                </td>
                <td class="text-muted align-middle border-top-0">
                  {school.pay_status === "INVEST" ? (
                    <button
                      type="button"
                      class="btn btn-sm btn-primary"
                      disabled
                    >
                      Invested
                    </button>
                  ) : (
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-primary"
                      id={school.token}
                      onClick={(b) => {
                        Invest(b.target.id);
                        //console.log(b.target.id);
                      }}
                    >
                      Invest Now
                    </button>
                  )}
                </td>
              </>
            ) : (
              <>
              
              </>
            )}
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="4">
            <div className="col-md-12 text-center mt-5">
              <div class="lds-facebook">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </td>
        </tr>
      );
    }
  };

  if (state.loggedIn) {
    return (
      <>
        <Auth /> 
      </>
    );
  } else {
    return (
      <>
        <div class={state.withdrawclass} role="alert" onMouseMove={fetchData} onTouchMove={fetchData}>
          <p class="mb-0">
            {state.withdrawtext}
            <br />
            <br />
          </p>
        </div>

      <div class="pb-3 d-lg-flex justify-content-between align-items-center card-header border-bottom-0" onMouseMove={fetchData} onTouchMove={fetchData}>
      <div class="mb-3 mb-lg-0">
        <h3 class="h3 fw-semi-bold mb-0">All Completed Schools</h3>
      </div>
      <div>
          <button type="button" class="btn btn-outline-primary btn-sm" onClick={fetchData}>
           {state.buttonname}{"  "}
            <div className={state.fetch} role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </button>
        </div>
        </div>

        <div class="table-responsive mt-2 border-0">
          <table class="table mb-4">
            <thead class="table-white">
              <tr>
                <th scope="col" class="border-0">
                  S/N
                </th>
                <th scope="col" class="border-0">
                  SCHOOL DETAILS
                </th>
                <th scope="col" class="border-0">
                  AMOUNT
                </th>
                <th scope="col" class="border-0"></th>
              </tr>
            </thead>
            <tbody className="mb-4">{renderTable()}</tbody>
          </table>
        </div>
      </>
    );
  }
};

export default InvestTable;
