import React from "react";

import axios from "axios";

import { useState } from "react";

const Table = (getemail) => {
  const [schools, setSchools] = useState(null);

  const email = getemail.getemail;

  // console.log(email);

  //let viewdata;

  const [state, setState] = React.useState({
    fetch: "",
    buttonname: " Fetch All Payouts"
  });

  const fetchData = async () => {
    const response = await axios.post("./api/payout_lists.php", { email });

    const view = response.data;

    setSchools(view);

    setState({
      fetch: "spinner-border spinner-border-sm text-primary",
      buttonname: "Fetching All...."
    });
  };

  const renderTable = () => {
    if (schools) {
      return schools.map((school, index) => {
        const TimeDate = new Date(school.tm_date).toDateString();
        return (
          <>
            {school.status_code === "Completed" ? (
              <tr>
                <td class="align-middle border-top-0" key={index}>
                  {index + 1}
                </td>
                <td class="align-middle border-top-0">
                  {school.pay_id}
                </td>
                <td class="align-middle border-top-0"> ₦{school.amount}</td>
                <td class="align-middle border-top-0"> {TimeDate}</td>
              </tr>
            ) : (
              <></>
            )}
          </>
        );
      });
    } else {
      return <p className="text-justify ms-4 fw-bold mt-2">Loading..</p>;
    }
  };

  return (
    <div class="card mb-4">
      <div class="pb-6 d-lg-flex justify-content-between align-items-center card-header border-bottom-0" onMouseMove={fetchData} onTouchMove={fetchData}>
      <div class="mb-3 mb-lg-0">
        <h3 class="h4 mb-0">All Payouts Notifications</h3>
      </div>
      <div>
          <button type="button" class="btn btn-outline-primary btn-sm" onClick={fetchData}>
           {state.buttonname}{"  "}
            <div className={state.fetch} role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </button>
        </div>
        </div>

      <div class="table-responsive border-0">
        <table class="table mb-0">
          <thead class="table-success">
            <tr>
              <th scope="col" class="border-0">
                S/N
              </th>
              <th scope="col" class="border-0">
                PAY ID
              </th>
              <th scope="col" class="border-0">
                AMOUNT
              </th>
              <th scope="col" class="border-0">
                DATE
              </th>
            </tr>
          </thead>

          <tbody>{renderTable()}</tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
