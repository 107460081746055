import React, { useEffect, useState } from "react";

import axios from "axios";

import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

toast.configure();

const Navbar = () => {
  const getusers = useSelector((state) => state.user.value);

  const getname = getusers.fullname;

  const iconName = getname.split(" ")[0];

  console.log(getusers);

  const getemail = getusers.email;

  function LogOut() {
    localStorage.clear();
    window.location.href = "../#/login";
  }

  const [schools, setSchools] = useState(null);

  const [message, setMessage] = useState("");

  const fetchData = async () => {
    const response = await axios.post("./api/notify_.php", { email: getemail });

    const view = response.data;

    if (view.message === "failed") {
      setMessage(view.message);
    } else {
      setSchools(view);
    }
  };

  const renderTable = () => {
    if (message === "failed") {
      return (
        <div className="col-md-12 py-5 mt-1 text-center">
          <img
            src="../assets/images/background/5.png"
            alt="Gallery image 3"
            class="gallery__img rounded-3"
            style={{ width: "10%" }}
          />

          <h4 className="fs-3 fw-bold mt-8">No Records Found</h4>
        </div>
      );
    } else if (schools) {
      return schools.map((school, index) => {
        const TimeDate = new Date(school.tm_date).toDateString();
        //console.log(school.tm_date)
        return (
          <>
            <li class="list-group-item">
              <div class="row">
                <div class="col">
                  <a class="text-body">
                    <div class="d-flex">
                      <img
                        src="../assets/images/avatar/bot.svg"
                        alt=""
                        class="avatar-md rounded-circle"
                      />
                      <div class="ms-3">
                        <h5 class="fw-bold mb-1">Bot Kristin</h5>
                        <p class="mb-3 text-dark">{school.message}</p>
                        <span class="fs-6 text-muted">
                          <span>
                            <span class="fe fe-thumbs-up text-success me-1"></span>
                            {school.date_},
                          </span>
                          <span class="ms-1">{school.date_time}</span>
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-auto text-center me-2">
                  <a
                    class="badge-dot bg-info"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Mark as unread"
                  ></a>
                </div>
              </div>
            </li>
          </>
        );
      });
    } else {
      return (
        <div className="col-md-12 text-center py-5 mt-5">
          <div class="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
    }
  };

  const truncate = (str, max, suffix) =>
    str.length < max
      ? str
      : `${str.substr(
          0,
          str.substr(0, max - suffix.length).lastIndexOf(" ")
        )}${suffix}`;

  const [promo, setPromo] = useState(null);
  const [messages, setMessages] = useState("");

  useEffect(() => {
    axios({
      method: "post",
      url: "/api/promo.php",
      data: {
        email: getemail,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message === "failed") {
          //toast.error("Update: Promo is no longer Live!");
          setMessages(view.message);
        } else {
          //toast.success("Update: Promo is Live and Ongoing!");
          setPromo(view);
        }
      })
      .catch((err) => {
        toast.error("Error: Promo Loader Failed! Check your connection..");
      });
  }, []);

  const renderPromo = () => {
    if (messages === "failed") {
      return <></>;
    } else if (promo) {
      return promo.map((school, index) => {
        //const TimeDate = new Date(school.tm_date).toDateString();
        //console.log(school.tm_date)
        return (
          <>
            <div class="alert alert-success text-dark fade show fw-semi-bold">
              <i class="bi bi-bell-fill fs-3 text-warning"></i>{" "}
              {school.title.replace("?", "₦")}!{" "}
              {truncate(school.message.replace("?", "₦"), 80, ".......")}{" "}
              <Link to="../app/promo" class="alert-link">
                <u>Click link to view more details</u>
              </Link>
            </div>
          </>
        );
      });
    } else {
      return <></>;
    }
  };

  return (
    <>
      {renderPromo()}
      <nav class="navbar navbar-expand-lg navbar-default">
        <div class="container-fluid px-0">
          <a class="navbar-brand">
            <img
              src="../assets/images/brand/logo/smfreelance-2.png"
              width="220"
              alt=""
            />
          </a>

          <ul class="navbar-nav navbar-right-wrap ms-auto d-lg-none d-flex nav-top-wrap">
            <li class="dropdown stopevent">
              <a
                class="btn btn-light btn-icon rounded-circle text-muted indicator indicator-primary"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                onClick={fetchData}
              >
                <i class="fe fe-bell"> </i>
              </a>
              <div class="dropdown-menu dropdown-menu-end shadow">
                <div>
                  <div class="border-bottom px-3 pb-3 d-flex justify-content-between align-items-center">
                    <span class="h5 mb-0">Notifications</span>
                    <a class="text-muted">
                      <span class="align-middle">
                        <i class="fe fe-settings me-1"></i>
                      </span>
                    </a>
                  </div>

                  <ul class="list-group list-group-flush notification-list-scroll">
                    {renderTable()}
                  </ul>

                  <div class="border-top px-3 pt-3 pb-0">
                    <Link
                      to="../app/notifications"
                      class="text-link fw-semi-bold"
                    >
                      See all Notifications
                    </Link>
                  </div>
                </div>
              </div>
            </li>
            <li class="dropdown ms-2">
              <a
                class="rounded-circle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
              >
                <div class="avatar avatar-md avatar-indicators avatar-online">
                  <span class="mt-0 avatar avatar-md avatar-primary">
                    <span class="avatar-initials rounded-circle fw-bold fs-3">
                      {iconName.charAt(0).toUpperCase()}
                    </span>
                  </span>
                </div>
              </a>
              <div class="dropdown-menu dropdown-menu-end shadow">
                <div class="dropdown-item">
                  <div class="d-flex">
                    <div class="avatar avatar-md avatar-indicators avatar-online">
                      <span class="mt-0 avatar avatar-md avatar-primary">
                        <span class="avatar-initials rounded-circle fw-bold fs-3">
                          {iconName.charAt(0).toUpperCase()}
                        </span>
                      </span>
                    </div>
                    <div class="ms-3 lh-1">
                      <h5 class="mb-1">{getname}</h5>
                      <p class="mb-0 text-muted">{getemail}</p>
                    </div>
                  </div>
                </div>
                <div class="dropdown-divider"></div>
                <ul class="list-unstyled">
                  <li>
                    <Link to="../app/profile" class="dropdown-item">
                      <i class="fe fe-settings nav-icon"></i> Profile
                    </Link>
                  </li>

                  <li>
                    <Link to="../app/security" class="dropdown-item">
                      <i class="fe fe-user nav-icon"></i> Security
                    </Link>
                  </li>
                </ul>
                <div class="dropdown-divider"></div>
                <ul class="list-unstyled">
                  <li>
                    <a class="dropdown-item" onClick={LogOut}>
                      <i class="fe fe-power nav-icon"></i> Sign Out
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>

          <div class="collapse navbar-collapse" id="navbar-default">
            <ul class="navbar-nav navbar-right-wrap ms-auto d-none d-lg-block">
              <li class="dropdown d-inline-block stopevent">
                <a
                  class="btn btn-light btn-icon rounded-circle text-muted indicator indicator-primary"
                  href="#"
                  role="button"
                  id="dropdownNotificationSecond"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={fetchData}
                >
                  <i class="fe fe-bell"> </i>
                </a>
                <div
                  class="dropdown-menu dropdown-menu-end dropdown-menu-lg"
                  aria-labelledby="dropdownNotificationSecond"
                >
                  <div>
                    <div class="border-bottom px-3 pb-3 d-flex justify-content-between align-items-center">
                      <span class="h5 mb-0">Notifications</span>
                      <a class="text-muted">
                        <span class="align-middle">
                          <i class="fe fe-settings me-1"></i>
                        </span>
                      </a>
                    </div>

                    <ul class="list-group list-group-flush notification-list-scroll ">
                      {renderTable()}
                    </ul>

                    <div class="border-top px-3 pt-3 pb-0">
                      <Link
                        to="../app/notifications"
                        class="text-link fw-semi-bold"
                      >
                        See all Notifications
                      </Link>
                    </div>
                  </div>
                </div>
              </li>

              <li class="dropdown ms-2 d-inline-block">
                <a
                  class="rounded-circle"
                  href="#"
                  data-bs-toggle="dropdown"
                  data-bs-display="static"
                  aria-expanded="false"
                >
                  <div class="avatar avatar-md avatar-indicators">
                    <span class="mt-0 avatar avatar-md avatar-primary">
                      <span class="avatar-initials rounded-circle fw-bold fs-3">
                        {iconName.charAt(0).toUpperCase()}
                      </span>
                    </span>
                  </div>
                </a>
                <div class="dropdown-menu dropdown-menu-end">
                  <div class="dropdown-item">
                    <div class="d-flex">
                      <div class="avatar avatar-md avatar-indicators">
                        <span class="mt-0 avatar avatar-md avatar-primary">
                          <span class="avatar-initials rounded-circle fw-bold fs-3">
                            {iconName.charAt(0).toUpperCase()}
                          </span>
                        </span>
                      </div>
                      <div class="ms-3 lh-1">
                        <h5 class="mb-1">{getname}</h5>
                        <p class="mb-0 text-muted">{getemail}</p>
                      </div>
                    </div>
                  </div>
                  <div class="dropdown-divider"></div>
                  <ul class="list-unstyled">
                    <li>
                      <Link to="../app/profile" class="dropdown-item">
                        <i class="fe fe-settings nav-icon"></i> Profile
                      </Link>
                    </li>

                    <li>
                      <Link to="../app/security" class="dropdown-item">
                        <i class="fe fe-user nav-icon"></i> Security
                      </Link>
                    </li>
                  </ul>
                  <div class="dropdown-divider"></div>
                  <ul class="list-unstyled">
                    <li>
                      <a class="dropdown-item" onClick={LogOut}>
                        <i class="fe fe-power nav-icon"></i> Sign Out
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
