import React from "react";

import { useSelector, useDispatch } from "react-redux";

import { register } from "../features/Users";

import Navbar from "./Navbar";

import EarningsAPP from "./Screens/Earning";

import Sidebar from "./Sidebar";

import Form from "./Form";
import OrdersAPP from "./Screens/Orders";
import Footer from "../Footer";


const Orders = () => {

  var hours = 1; // to clear the localStorage after 1 hour(if someone want to clear after 8hrs simply change hours=8)
  var now = new Date().getTime();
  var setupTime = localStorage.getItem("user_data");
  if (setupTime == null) {
    localStorage.clear();
    window.location.href = "../#/login";
  } else {
    if (now - setupTime > hours * 60 * 60 * 1000) {
      localStorage.clear();
      window.location.href = "../#/login";
    }
  }

  const dispatch0 = useDispatch();

  const get_user_data0 = window.localStorage.getItem("user_data");

  // console.log(get_user_data0);

  let verify_user;

  if (get_user_data0) {
    verify_user = dispatch0(register(JSON.parse(get_user_data0)));
  }else{
    window.location.href = "../#/login";
  }

  if (window.localStorage.getItem("email") || window.localStorage.getItem("fullname")) {
    window.location.href = "../#/login";
  }

  console.log(verify_user);

  if (verify_user) {
    localStorage.setItem("token", "");
    localStorage.setItem("tokenid", "");
    localStorage.setItem("token_id", "");
  }

  const getusers = useSelector((state) => state.user.value);

  const getname = getusers.fullname;

  const iconName = getname.split(" ")[0];

  console.log(getusers);

  const getemail = getusers.email;

  return (
    <div className="bg-white">
      <Navbar />

      <div class="pt-5 pb-5">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-12">
              <div
                class="pt-16 rounded-top-md"
                style={{
                  backgroundImage: `url(../assets/images/background/profile-bg.jpg)`,
                  backgroundsize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
              <div class="d-flex align-items-end justify-content-between bg-white px-4 pt-2 pb-4 rounded-none rounded-bottom-md shadow-sm">
                <div class="d-flex align-items-center">
                  <div class="me-2 position-relative d-flex justify-content-end align-items-end mt-n5">
                    <span class="mt-6 avatar avatar-lg avatar-primary">
                      <span class="avatar-initials rounded-circle fw-bold fs-2">
                        {iconName.charAt(0).toUpperCase()}
                      </span>
                    </span>
                    <a
                      class="position-absolute top-0 end-0 mt-4"
                      data-bs-toggle="tooltip"
                      data-placement="top"
                      title="Verified"
                    >
                      <img
                        src="../assets/images/svg/checked-mark.svg"
                        alt=""
                        height="30"
                        width="30"
                      />
                    </a>
                  </div>
                  <div class="lh-1">
                    <h2 class="mb-0">{getname}</h2>
                  </div>
                </div>
                <div className="ms-3">
                  <button
                    type="button"
                    class="btn btn-primary btn-sm d-md-block"
                    data-bs-toggle="modal"
                    data-bs-target=".gd-example-modal-lg"
                  >
                    Add School
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-0 mt-md-4">
            <Sidebar />

            <OrdersAPP/>
          </div>
        </div>
      </div>

      <Form/>

      <Footer/>

    </div>
  );
};

export default Orders;
