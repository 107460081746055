import React from "react";

import axios from "axios";

import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import Carousel from "react-bootstrap/Carousel";

import PaystackPop from "@paystack/inline-js";

toast.configure();

const UpgradeApp = () => {
  const getusers = useSelector((state) => state.user.value);

  const getemail = getusers.email;

  const getname = getusers.fullname;

  const plan_type = getusers.plan_type;

  const ApiKey = "pk_live_5a3352e9dbb5657c64a70252cbad7dca4562412f";

  function UpgradePlan(clicked, plan) {
    axios({
      method: "post",
      url: "/api/plan.php",
      data: {
        email: getemail,
        plan: plan,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        if (response.data.message === "success") {
          toast.success("Success: " + clicked);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check your connection..");
      });
  }

  const payWithPaystack = () => {
    let handler = PaystackPop.setup({
      key: ApiKey, // Replace with your public key

      email: getemail,

      amount: 10250 * 100,

      currency: "NGN",

      ref: "BASIC_PLAN_UPGRADE" + Math.floor(Math.random() * 1000000000 + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you

      label: getname,

      onClose: function () {
        toast.error("Error: Request Failed! Payment not made!");
      },

      callback: function (response) {
        let message = "Payment complete! Reference: " + response.reference;

        let plan = "BASIC_PLAN_UPGRADE";

        UpgradePlan(message, plan);
      },
    });

    handler.openIframe();
  };

  const payWithPaystack_1 = () => {
    let handler = PaystackPop.setup({
      key: ApiKey, // Replace with your public key

      email: getemail,

      amount: 20400 * 100,

      currency: "NGN",

      ref: "STANDARD_PLAN_UPGRADE" + Math.floor(Math.random() * 1000000000 + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you

      label: getname,

      onClose: function () {
        toast.error("Error: Request Failed! Payment not made!");
      },

      callback: function (response) {
        let message = "Payment complete! Reference: " + response.reference;

        let plan = "STANDARD_PLAN_UPGRADE";

        UpgradePlan(message, plan);
      },
    });

    handler.openIframe();
  };

  const payWithPaystack_2 = () => {
    let handler = PaystackPop.setup({
      key: ApiKey, // Replace with your public key

      email: getemail,

      amount: 30550 * 100,

      currency: "NGN",

      ref:
        "PLATINIUM_PLAN_UPGRADE" + Math.floor(Math.random() * 1000000000 + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you

      label: getname,

      onClose: function () {
        toast.error("Error: Request Failed! Payment not made!");
      },

      callback: function (response) {
        let message = "Payment complete! Reference: " + response.reference;

        let plan = "PLATINIUM_PLAN_UPGRADE";

        UpgradePlan(message, plan);
      },
    });

    handler.openIframe();
  };

  return (
    <div class="container-fluid bg-white">
      <div class="row align-items-center min-vh-100">
        <div
          class="col-lg-6 d-lg-flex align-items-center w-lg-50 
            min-vh-lg-100 position-fixed-lg bg-cover bg-light top-0
              right-0"
          style={{
            backgroundImage: `url(../assets/images/about/levelup.jpg)`,
          }}
        >
          <div class="px-4 px-xl-20 py-20 py-lg-20"></div>
        </div>

        <div class="col-lg-6 col-md-12 col-12">
          <div class="row">
            <div class="offset-xl-2 col-xl-8 offset-lg-1 col-lg-10 col-md-12 col-12">
              <div class="mb-8 text-center">
                <Link
                  to="../app/earnings"
                  className="text-danger fs-3 fw-semi-bold mb-6"
                >
                  <i class="bi bi-arrow-left"></i>
                  Back
                </Link>
                <h2 class="display-4 mb-3 fw-bold">
                  Upgarde for higher income
                </h2>
                <p class="lead mb-4">
                  Get higher pay income when you upgrade your account. Become a
                  stakeholder.
                </p>
              </div>

              <Carousel variant="white" controls={false}>
                {plan_type === "BASIC_PLAN_UPGRADE" ? (
                  <Carousel.Item>
                    <div className="row">
                      {plan_type === "STANDARD_PLAN_UPGRADE" ? (
                        <></>
                      ) : (
                        <div class="col-lg-6 col-md-6 col-12">
                          <div class="card shadow-none border mb-3">
                            <div class="p-5">
                              <div class="mb-5">
                                <h4 class="fw-bold text-uppercase ls-md mb-4">
                                  Standard
                                </h4>

                                <h6 class="fw-bold text-uppercase ls-md mb-1">
                                  Maximum of
                                </h6>
                                <div class="d-flex mb-4">
                                  <span class="h3 mb-0 fw-bold">₦</span>
                                  <div
                                    class="price-card--price-number toggle-price-content-second odometer fw-semi-bold fs-3"
                                    data-price-monthly="39"
                                    data-price-yearly="49"
                                  >
                                    500,000
                                  </div>
                                </div>
                              </div>

                              <a
                                class="btn btn-warning btn-block"
                                onClick={payWithPaystack_1}
                              >
                                Upgrade Now
                              </a>
                            </div>
                          </div>
                        </div>
                      )}

                      {plan_type === "PLATINIUM_PLAN_UPGRADE" ? (
                        <></>
                      ) : (
                        <div class="col-lg-6 col-md-6 col-12">
                          <div class="card shadow-none border mb-3">
                            <div class="p-5">
                              <div class="mb-5">
                                <h4 class="fw-bold text-uppercase ls-md mb-4">
                                  Platinium
                                </h4>

                                <h6 class="fw-bold text-uppercase ls-md mb-1">
                                  Maximum of
                                </h6>
                                <div class="d-flex mb-4">
                                  <span class="h3 mb-0 fw-bold">₦</span>
                                  <div
                                    class="price-card--price-number toggle-price-content-second odometer fw-semi-bold fs-3"
                                    data-price-monthly="39"
                                    data-price-yearly="49"
                                  >
                                    1,000,000
                                  </div>
                                </div>
                              </div>

                              <a
                                class="btn btn-warning btn-block"
                                onClick={payWithPaystack_2}
                              >
                                Upgrade Now
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </Carousel.Item>
                ) : (
                  <Carousel.Item>
                    <div className="row">
                      {plan_type === "NULL" ? (
                        <div class="col-lg-6 col-md-6 col-12">
                          <div class="card shadow-none border border-success mb-3">
                            <div class="p-5">
                              <div class="mb-5">
                                <h4 class="fw-bold text-uppercase ls-md mb-4">
                                  Starter
                                </h4>

                                <h6 class="fw-bold text-uppercase ls-md mb-1">
                                  Maximum of
                                </h6>

                                <div class="d-flex mb-4">
                                  <span class="h3 mb-0 fw-bold">₦</span>
                                  <div
                                    class="price-card--price-number toggle-price-content-second odometer fw-semi-bold fs-3"
                                    data-price-monthly="0"
                                    data-price-yearly="0"
                                  >
                                    100,000
                                  </div>
                                </div>
                              </div>

                              <a class="btn btn-outline-success btn-block">
                                Starter Plan
                              </a>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {plan_type === "BASIC_PLAN_UPGRADE" ? (
                        <></>
                      ) : (
                        <div class="col-lg-6 col-md-6 col-12">
                          <div class="card shadow-none border mb-3">
                            <div class="p-5">
                              <div class="mb-5">
                                <h4 class="fw-bold text-uppercase ls-md mb-4">
                                  Basic
                                </h4>

                                <h6 class="fw-bold text-uppercase ls-md mb-1">
                                  Maximum of
                                </h6>
                                <div class="d-flex mb-4">
                                  <span class="h3 mb-0 fw-bold">₦</span>
                                  <div
                                    class="price-card--price-number toggle-price-content-second odometer fw-semi-bold fs-3"
                                    data-price-monthly="39"
                                    data-price-yearly="49"
                                  >
                                    300,000
                                  </div>
                                </div>
                              </div>

                              <a
                                class="btn btn-warning btn-block"
                                onClick={payWithPaystack}
                              >
                                Upgrade Now
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </Carousel.Item>
                )}
                
                {plan_type === "NULL" ? (
                <Carousel.Item>
                  <div className="row">
                    {plan_type === "STANDARD_PLAN_UPGRADE" ? (
                      <></>
                    ) : (
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="card shadow-none border mb-3">
                          <div class="p-5">
                            <div class="mb-5">
                              <h4 class="fw-bold text-uppercase ls-md mb-4">
                                Standard
                              </h4>

                              <h6 class="fw-bold text-uppercase ls-md mb-1">
                                Maximum of
                              </h6>
                              <div class="d-flex mb-4">
                                <span class="h3 mb-0 fw-bold">₦</span>
                                <div
                                  class="price-card--price-number toggle-price-content-second odometer fw-semi-bold fs-3"
                                  data-price-monthly="39"
                                  data-price-yearly="49"
                                >
                                  500,000
                                </div>
                              </div>
                            </div>

                            <a
                              class="btn btn-warning btn-block"
                              onClick={payWithPaystack_1}
                            >
                              Upgrade Now
                            </a>
                          </div>
                        </div>
                      </div>
                    )}

                    {plan_type === "PLATINIUM_PLAN_UPGRADE" ? (
                      <></>
                    ) : (
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="card shadow-none border mb-3">
                          <div class="p-5">
                            <div class="mb-5">
                              <h4 class="fw-bold text-uppercase ls-md mb-4">
                                Platinium
                              </h4>

                              <h6 class="fw-bold text-uppercase ls-md mb-1">
                                Maximum of
                              </h6>
                              <div class="d-flex mb-4">
                                <span class="h3 mb-0 fw-bold">₦</span>
                                <div
                                  class="price-card--price-number toggle-price-content-second odometer fw-semi-bold fs-3"
                                  data-price-monthly="39"
                                  data-price-yearly="49"
                                >
                                  1,000,000
                                </div>
                              </div>
                            </div>

                            <a
                              class="btn btn-warning btn-block"
                              onClick={payWithPaystack_2}
                            >
                              Upgrade Now
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Carousel.Item>
                 ) : ( <>
                 </>)}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradeApp;
