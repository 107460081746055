import React, { useState } from "react";

import axios from "axios";

import { Link, Navigate } from "react-router-dom";

import Auth from "./App/Auth";

const Reset = () => {
  const image = {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "65%",
  };

  
  const [state, setState] = React.useState({
    message: '',
    alerts: ''
  });

  const [email, setEmail] = useState("");

  const [emailErr, setEmailErr] = useState({});


  const onSubmit = (b) => {
    b.preventDefault();
    const isValid = formValidation();
    //console.log("works");

    localStorage.setItem("email", "");
    localStorage.setItem("fullname", "");

    if (isValid) {
      const users = {
        email: email
      };

      //console.log(users);

      axios({
        method: "post",
        url: "/auth/reset.php",
        data: users,
        config: { headers: { "Content-Type": "multiport/form-data" } },
      })
        .then(function (response) {
          //console.log(response);
          if (response.data.message === "success") {
            setState({
                message: "Reset Password Link has been sent to your mail. Please go and check!",
                alerts: "text-center fs-4 mt-3 mb-3 alert alert-success",
              });
          } else if (response.data.message === "exist") {
            setState({
                message: "Oops! Looks like you don't have an account here.",
                alerts: "text-center fs-4 mt-3 mb-3 text-dark alert alert-warning",
              });
          }
          else {
            setState({
              message: "Request Failed. Try again!",
              alerts: "text-center fs-4 mt-3 mb-3 alert alert-danger",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const formValidation = () => {
    const emailErr = {};
    let isValid = true;

    if (!email) {
      emailErr.emailWrong = "Email is required here";
      isValid = false;
    }

    setEmailErr(emailErr);

    return isValid;
  };

    return (
      <div className="bg-light-gradient-bottom">
        <div class="container d-flex flex-column">
          <div class="row align-items-center justify-content-center g-0 min-vh-100">
            <div class="col-lg-5 col-md-8 py-6 py-xl-0">
              <div class="card shadow ">
                <div class="card-body p-6">
                  <div class="mb-4">
                    <Link to="/" className="ms-8 text-center">
                      <img
                        src="../assets/images/brand/logo/smfreelance-2.png"
                        className="mb-4 text-center"
                        width="260"
                        alt=""
                        style={image}
                      />
                    </Link>
                    <h1 class="mb-1 fw-bold">Forgot Password</h1>
                    <span>
                    Fill the form to reset your password.
                    </span>
                  </div>

                  <div class={state.alerts} role="alert">
                    {state.message}
                  </div>

                  <form onSubmit={onSubmit}>
                    <div className="mb-3">
                      <label for="name" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email Address"
                        onChange={(e) => {
                          setEmail(e.target.value);
                          emailErr.emailWrong = "";
                        }}
                      />
                      {Object.keys(emailErr).map((key) => {
                        return (
                          <div className="text-danger fs-5 mt-3">
                            {emailErr[key]}
                          </div>
                        );
                      })}
                    </div>

                    <div>
                      <div class="d-grid">
                        <button type="submit" class="btn btn-primary">
                         Send Reset Link
                        </button>
                      </div>
                    </div>
                    <hr class="my-4" />
                    <div class="mt-4 text-center">
                      <a
                        href="#"
                        class="btn-social btn-social-outline btn-facebook"
                      >
                        <i class="fab fa-telegram"></i>
                      </a>

                      <a
                        href="#"
                        class="btn-social btn-social-outline btn-twitter"
                      >
                        <i class="fab fa-twitter"></i>
                      </a>

                      <a
                        href="#"
                        class="btn-social btn-social-outline btn-linkedin"
                      >
                        <i class="fab fa-linkedin"></i>
                      </a>

                      <a
                        href="#"
                        class="btn-social btn-social-outline btn-instagram"
                      >
                        <i class="fab fa-instagram"></i>
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Reset;
