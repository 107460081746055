import React, { useState } from "react";

import InvestTable from "./InvestTable";

import { Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

const InvestAPP = () => {
  const getusers0 = useSelector((state) => state.user.value);

  const getinvest_total = getusers0.invest_total;

  const getinvest = getusers0.invest;

  const getemail = getusers0.email;

  const getzenith = getusers0.zenith_acc;

  const getpolaris = getusers0.polaris_acc;

  const getname = getusers0.fullname;

  const [disable, setDisable] = React.useState(false);

  let invest = getinvest;

  let withdraw_status;

  if (getinvest_total == 0) {
    withdraw_status =
      "Proceed to investing a school commission (Your earnings) and get paid 3 times your initial fee at the end of three terms";
  } else {
    withdraw_status = "Hurray! you can now withdraw your investments! 😃";
  }

  const [state, setState] = React.useState({
    isSubmit: "",
    buttonname: "Update Account Number",
    account: getzenith,
    account_name: "",
    text: "",
    isSubmit1: "",
    buttonname1: "Update Account Number",
    account1: getpolaris,
    account_name1: "",
    text1: "",
    withdrawclass1: "",
    withdrawtext1: "",
    withdrawclass:
      "alert bg-light-dark text-dark-light alert-dismissible fade show",
    withdrawtext:
      "Hi there! Welcome to Smart Invest.io. If you have any enquires regarding Smart Invest.io kindly send an email to the mail above.",
  });

  return (
    <div class="col-lg-9 col-md-8 col-12">
      <div class="card mb-4">
        <div class="card-header">
          <h3 class="mb-0">Smart Invest.io</h3>
          <p class="mb-0 text-dark">Welcome to triple income opportunity!</p>
        </div>

        <div class="card mb-4">
          <div class="card-body">
            <div class={state.withdrawclass} role="alert">
              <strong>smartinvest@smartedung.com</strong>
              <p class="mb-0">
                {state.withdrawtext}
                <br />
                <br />
                Thank you
              </p>

              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>

            <div class="mt-4">
              <h4 class="mb-0">About Smart Invest.io</h4>
            </div>

            <p className="mt-4">
            Smart Invest is a platform where you can get paid for a full 
            session (three terms) rather than one term payment. 
            To achieve this you would need to save your first and second payments
             with Smart Edu Inc and allow it to grow times three (3).
            </p>

            <p className="mt-2">
            imagine, ₦312,450 your actual earning multiply by 3 to give ₦937,350
            </p>

            <p className="text-danger mt-4">
             Please note that once you activate Smart Invest you can not reverse the process.
             It would immediately start running till the end of 3 Terms. <br/><br/>
             Also note that money would not be paid until the process is completed. 
            </p>
          </div>
        </div>

        <div class="card-header">
          <h3 class="mb-0">Smart Invest.io Options</h3>
        </div>

        <div class="card-body">
          <div class="row mt-6">
            <div class="col-xl-4 col-lg-4 col-md-12 col-12 mb-3 mb-lg-0">
              <div class="text-center">
                <div id="payoutChart" class="apex-charts"></div>
                <h4 class="mb-3">Your Total Smart Invest Earnings</h4>
                <p class="mb-1 fw-semi-bold text-dark">Smart Invest payment would reflect here at the end 
                of the investment cycle</p>
                <h5 class="mb-0 display-4 fw-bold">₦{getinvest_total}.0</h5>
                <p class="px-4 text-primary">{withdraw_status}</p>

                <Link to="../app/payouts" class="nav-link">
                  <a type="button" class="btn btn-danger">
                    Go To Withdraw Earning
                  </a>
                </Link>
              </div>
            </div>
            <div class="col-xl-8 col-lg-8 col-md-12 col-12">
            <p className="text-danger text-center fw-bold mt-4">
             Please note that this process is not reversible.
            </p>
              <InvestTable getemail={getemail} />
            </div>
          </div>
        </div>
      </div>

     
    </div>
  );
};

export default InvestAPP;
