import ReactDOM from "react-dom";

import { HashRouter, Route, Routes } from "react-router-dom";

import Home from "./Home/Home";
import About from "./About/About";
import Earn from "./Earn/Earn";
import Blog from "./Blog/Blog";
import Faq from "./Faq/Faq";
import Contact from "./Contact/Contact";
import Terms from "./Terms";
import NoPage from "./NoPage";

import HomeApp from "./App/App";

import { configureStore } from '@reduxjs/toolkit';

import { Provider } from 'react-redux';

import useReducer from "./features/Users";

import Login from "./Login";

import Signup from "./Signup";

import AppHome from "./App/Home";

import Schools from "./App/Schools";

import Payouts from "./App/Payouts";

import Earnings from "./App/Earnings";

import Orders from "./App/Orders";

import Invest from "./App/Invest";

import Profile from "./App/Profile";

import Security from "./App/Security";

import Notify from "./App/Notify";

import './index.css'

import Delete from "./App/Delete";

import Auth from "./App/Auth2";

import Support from "./Support/Support";

import Reset from "./Reset";

import Upgrade from "./App/Upgrade";

import Promo from "./App/promo";


const store = configureStore({
  reducer: {
    user: useReducer,
  }
})

export default function App() {
  return (
    <HashRouter>
      <Routes>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="earnings" element={<Earn />} />
          <Route path="blog" element={<Blog />} />
          <Route path="faq" element={<Faq />} />
          <Route path="contact" element={<Contact />} />
          <Route path="terms" element={<Terms />} />
          <Route path="app" element={<HomeApp />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="reset" element={<Reset />} />
          <Route path="support" element={<Support />} />
          <Route path="app/home" element={<AppHome />} />
          <Route path="app/schools" element={<Schools />} />
          <Route path="app/payouts" element={<Payouts />} />
          <Route path="app/earnings" element={<Earnings />} />
          <Route path="app/orders" element={<Orders />} />
          <Route path="app/smartinvest.io" element={<Invest />} />
          <Route path="app/profile" element={<Profile />} />
          <Route path="app/security" element={<Security />} />
          <Route path="app/notifications" element={<Notify />} />
          <Route path="app/delete" element={<Delete />} />
          <Route path="app/auths" element={<Auth />} />
          <Route path="app/upgrade" element={<Upgrade />} />
          <Route path="app/promo" element={<Promo />} />
          <Route path="*" element={<NoPage />} />
      </Routes>
    </HashRouter>
  );
}

ReactDOM.render( <Provider store={store}><App /></Provider>, document.getElementById("root"));