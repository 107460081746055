import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import axios from "axios";

import Auth from "./Auth";

import { Navigate } from "react-router-dom";

const jwttokens = {
  jwt: localStorage.getItem("token"),
};

const post = async () => {

  axios({
    method: "post",
    url: "/login/cancel.php",
    data: jwttokens,
    config: { headers: { "Content-Type": "multiport/form-data" } },
  })
    .then(function (response) {
      // console.log(response.data.user_data.data.fullname);
      if (response.data.user_data.data.address) {
        localStorage.setItem("loggin", "True");
      }
    })
    .catch((response) => {
     // console.log(response);
    });
};

setTimeout(() => {
  post();
}, 1000);

const Contact = () => {
  const users1 = useSelector((state) => state.user.value);

  const firstname1 = users1.fullname;

  const email1 = users1.email;

  const checkAddress = users1.address;

  console.log(email1)

  let fname2;

  if (firstname1) {
    fname2 = firstname1.split(" ")[1];
  } else {
    fname2 = "";
  }

  const [state, setState] = React.useState({
    loggedIn: false,
  });

  const [address, setAddress] = useState("");
  const [job, setJob] = useState("");
  const [birth, setBirth] = useState("");

  const [addressErrr, setaddressErrr] = useState({});
  const [jobErrr, setjobErrr] = useState({});
  const [birthErrr, setbirthErrr] = useState({});

  const onSubmit = (b) => {
    b.preventDefault();
    const isValid = formValidation();
    //console.log("works");

    if (isValid) {
      const user_contact = {
        address: address,
        job: job,
        birth: birth,
        email: email1
      };

      console.log(user_contact);

      axios({
        method: "post",
        url: "/login/contact.php",
        data: user_contact,
        config: { headers: { "Content-Type": "multiport/form-data" } },
      })
        .then(function (response) {
          if (response.data.message === "success") {
            console.log(response.data.message);
            localStorage.setItem("token_id", response.data.token);
          } else {
            setState({
              loggedIn: false,
            });
          }
        })
        .catch((err) => {
         // console.log(err);
        });
    }
  };

  const formValidation = () => {
    const addressErrr = {};
    const jobErrr = {};
    const birthErrr = {};
    let isValid = true;

    if (!address) {
      addressErrr.addressshort = "Please enter your phone number";
      isValid = false;
    }

    if (!job) {
      jobErrr.jobShort = "Please enter your phone number";
      isValid = false;
    }

    if (!birth) {
      birthErrr.birthShort = "Please enter your phone number";
      isValid = false;
    }

    setaddressErrr(addressErrr);
    setbirthErrr(birthErrr);
    setjobErrr(jobErrr);

    return isValid;
  };

  const token_id = localStorage.getItem("token_id");

  const Loggin = localStorage.getItem("loggin");

  if (checkAddress || Loggin) {
    return <Navigate to={"./home"} />;
  } else if (token_id) {
    localStorage.removeItem("email");
    return (
      <>
        <Auth />
      </>
    );
  } else {
    return (
      <div class="container-fluid bg-white">
        <div class="row align-items-center min-vh-100">
          <div
            class="col-lg-6 d-lg-flex align-items-center w-lg-50 
          min-vh-lg-100 position-fixed-lg bg-cover bg-light top-0
            right-0"
            style={{
              backgroundImage: `url(../assets/images/about/address.jpg)`,
            }}
          >
            <div class="px-4 px-xl-20 py-20 py-lg-20"></div>
          </div>

          <div class="col-lg-6 col-md-12 col-12">
            <div class="px-xl-10 px-md-4 px-4 py-8 py-lg-0">
              <div class="text-dark ms-2">
                <h1 class="display-4 fw-bold">
                  Almost there{" "}
                  <span className="text-success">
                    {fname2.charAt(0).toUpperCase() + fname2.slice(1)}
                    ..
                  </span>
                </h1>

                <form onSubmit={onSubmit}>
                  <div className="row">
                    <div className="mb-3">
                      <label for="phone" className="form-label">
                        Enter your home address
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Home Address"
                        onChange={(e) => {
                          setAddress(e.target.value);
                          addressErrr.addressshort = "";
                        }}
                      />

                      {Object.keys(addressErrr).map((key) => {
                        return (
                          <div className="text-danger fw-bold fs-5 mt-2">
                            {addressErrr[key]}
                          </div>
                        );
                      })}
                    </div>

                    <div className="col-md-6 mb-3">
                      <label for="phone" className="form-label">
                        Enter your nature of Job
                      </label>
                      <input
                        type="phone"
                        className="form-control"
                        placeholder="Enter Your Job Title"
                        onChange={(e) => {
                          setJob(e.target.value);
                          jobErrr.jobShort = "";
                        }}
                      />

                      {Object.keys(jobErrr).map((key) => {
                        return (
                          <div className="text-danger fw-bold fs-5 mt-2">
                            {jobErrr[key]}
                          </div>
                        );
                      })}
                    </div>

                    <div className="col-md-6 mb-3">
                      <label for="phone" className="form-label">
                        Enter your Date of Birth
                      </label>
                      <input
                        class="form-control"
                        data-inputmask-alias="datetime"
                        placeholder=""
                        type="date"
                        data-inputmask-inputformat="dd/mm/yyyy"
                        inputmode="numeric"
                        id="date"
                        onChange={(e) => {
                          setBirth(e.target.value);
                          birthErrr.birthShort = "";
                        }}
                      />
                      {Object.keys(birthErrr).map((key) => {
                        return (
                          <div className="text-danger fw-bold fs-5 mt-2">
                            {birthErrr[key]}
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="d-grid mt-5">
                    <button type="submit" className="btn btn-dark">
                      Proceed
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Contact;
