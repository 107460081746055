import React from "react";

import { useSelector, useDispatch } from "react-redux";

import { ShimmerPostItem } from "react-shimmer-effects";

import { register } from "../features/Users";

import Navbar from "./Navbar";

import PromoApp from "./Screens/Promo";

import Sidebar from "./Sidebar";


const Promo = () => {

  var hours = 1; // to clear the localStorage after 1 hour(if someone want to clear after 8hrs simply change hours=8)
  var now = new Date().getTime();
  var setupTime = localStorage.getItem("user_data");
  if (setupTime == null) {
    localStorage.clear();
    window.location.href = "../#/login";
  } else {
    if (now - setupTime > hours * 60 * 60 * 1000) {
      localStorage.clear();
      window.location.href = "../#/login";
    }
  }
  
  const dispatch0 = useDispatch();

  const get_user_data0 = window.localStorage.getItem("user_data");

  // console.log(get_user_data0);

  let verify_user;

  if (get_user_data0) {
    verify_user = dispatch0(register(JSON.parse(get_user_data0)));
  }else{
    window.location.href = "../#/login";
  }

  if (window.localStorage.getItem("email") || window.localStorage.getItem("fullname")) {
    window.location.href = "../#/login";
  }

  //console.log(verify_user);

  if (verify_user) {
    localStorage.setItem("token", "");
    localStorage.setItem("tokenid", "");
    localStorage.setItem("token_id", "");
  }

  const getusers = useSelector((state) => state.user.value);

  const getname = getusers.fullname;

  const iconName = getname.split(" ")[0];

  console.log(getusers);

  const getemail = getusers.email;

  return (
    <>
     <div className="bg-white">
      <Navbar />

      <PromoApp/>
      </div>
    </>
  );
};

export default Promo;
