import React, { useEffect, useState } from "react";

import axios from "axios";

import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

toast.configure();

const PromoApp = () => {
  const getusers = useSelector((state) => state.user.value);

  const getemail = getusers.email;

  const getname = getusers.fullname;

  const [promo, setPromo] = useState(null);
  const [message, setMessage] = useState("");

  useEffect(() => {
    axios({
      method: "post",
      url: "/api/promo.php",
      data: {
        email: getemail,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message === "failed") {
          toast.error("Update: Promo is no longer Live!");
          setMessage(view.message);
        } else {
          toast.success("Update: Promo is Live and Ongoing!");
          setPromo(view);
        }
      })
      .catch((err) => {
        toast.error("Error: Promo Loader Failed! Check your connection..");
      });
  }, []);

  const renderPromo = () => {
    if (message === "failed") {
      return (
        <>
          <div class="mb-5">
            <h1 class="display-3 text-danger mb-4 fw-bold ">
              No Ongoing <br />
              Promo Sales!
            </h1>

            <p class="lead mb-4 pe-xl-12 ">
              You would be notified once a new Promo is Live!
            </p>

            <Link to="../app/home" class="btn btn-primary ">
              Go To Dashboard
            </Link>
            <p class=" mt-4 mb-0">
              For more information visit the telegram channel for more details{" "}
              <a href="https://t.me/+aO5rVEkmkJ5mNzBk" target="_blank">
                Visit channel
              </a>
              .
            </p>
          </div>
        </>
      );
    } else if (promo) {
      return promo.map((school, index) => {
        //const TimeDate = new Date(school.tm_date).toDateString();
        //console.log(school.tm_date)
        return (
          <>
            <div class="mb-5">
              <h1 class="display-3 mb-4 fw-bold ">
                Ongoing <br />
                Promo Sales!
              </h1>

              <h1 class="display-6 mb-4 text-success fw-bold ">
                👉 {school.title.replace("?", "₦")}!
              </h1>

              <p class="lead mb-4 pe-xl-12 ">
                {school.message.replace("?", "₦")}

                <br></br>
                Promo Ends {new Date(school.end).toDateString()}
              </p>
              <Link to="../app/home" class="btn btn-primary ">
                Go To Dashboard
              </Link>
              <p class=" mt-4 mb-0">
                For more information visit the telegram channel for more details{" "}
                <a href="https://t.me/+aO5rVEkmkJ5mNzBk" target="_blank">
                  Visit channel
                </a>
                .
              </p>
            </div>
          </>
        );
      });
    } else {
      return <></>;
    }
  };

  return (
    <div class="py-8 bg-white">
      <div class="container ">
        <div class="row align-items-center">
          <div class="col-xl-6 col-lg-6 col-12">{renderPromo()}</div>
          <div class="col-lg-6 col-12">
            <div class="row">
              <div class="col-md-4 col-4 px-1">
                <div
                  class="bg-cover rounded-3 mb-2 h-12rem"
                  style={{
                    backgroundImage: `url("../assets/images/about/promo2.jpg")`,
                  }}
                ></div>
                <div
                  class="bg-cover rounded-3 mb-2 h-18rem"
                  style={{
                    backgroundImage: `url("../assets/images/about/promo1.jpg")`,
                  }}
                ></div>
              </div>

              <div class="col-md-4 col-4 px-1">
                <div
                  class="bg-cover rounded-3 mb-2 h-18rem"
                  style={{
                    backgroundImage: `url("../assets/images/about/promo3.jpg")`,
                  }}
                ></div>
                <div
                  class="bg-cover rounded-3 mb-2 h-18rem"
                  style={{
                    backgroundImage: `url("../assets/images/about/promo4.jpg")`,
                  }}
                ></div>
              </div>

              <div class="col-md-4 col-4 px-1">
                <div
                  class="bg-cover rounded-3 mb-2 h-13rem"
                  style={{
                    backgroundImage: `url("../assets/images/about/promo5.jpg")`,
                  }}
                ></div>
                <div
                  class="bg-cover rounded-3 mb-2 h-13rem"
                  style={{
                    backgroundImage: `url("../assets/images/about/promo6.jpg")`,
                  }}
                ></div>
                <div
                  class="bg-cover rounded-3 mb-2 h-13rem"
                  style={{
                    backgroundImage: `url("../assets/images/about/promo7.jpg")`,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromoApp;
