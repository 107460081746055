import React from "react";

import { Link } from "react-router-dom";

import Footer from "../Footer";

const Support = () => {
  return (
    <div class="bg-white">
      <nav class="navbar navbar-expand-lg bg-dark">
        <div class="container px-0">
          <div class="d-flex align-items-center">
            <Link to="/" className="navbar-brand text-inverse">
              <img
                src="../assets/images/brand/logo/smfreelance.png"
                width="280"
                alt=""
              />
            </Link>
            <div class="text-white-50 justify-content-between d-flex">
              <div class="vr bg-light bg-opacity-25"></div>{" "}
              <p class="ms-3 mb-0">Help Center</p>
            </div>
          </div>
          <div class="ms-auto d-flex align-items-center">
            <a
              href="https://t.me/SmartEduFreelance/"
              target="_blank"
              class="btn btn-info mb-2 fs-4"
            >
              <i class="bi bi-telegram fs-4"></i> Join Telegram Group
            </a>
          </div>

          <div class=""></div>
        </div>
      </nav>

      <div class="py-8 bg-colors-gradient">
        <div class="container">
          <div class="row">
            <div class="offset-md-2 col-md-8 col-12 ">
              <h1 class="fw-bold mb-0 display-4 lh-1">Support</h1>
            </div>
          </div>
        </div>
      </div>

      <div class="py-10">
        <div class="container">
          <div class="row">
            <div class="offset-md-2 col-md-8 col-12">
              <div class="mb-4">
                <h2 class="mb-0 fw-semi-bold">Most asked</h2>
              </div>
              <div class="accordion accordion-flush" id="accordionExample">
                <div class="border p-3 rounded-3 mb-2" id="headingOnes">
                  <h3 class="mb-0 fs-4">
                    <a
                      href="#"
                      class="d-flex align-items-center text-inherit
                    text-decoration-none active"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <span class="me-auto">
                        How do i withdraw my <b>Bonus Earning</b>?
                      </span>
                      <span class="collapse-toggle ms-4">
                        <i class="fe fe-chevron-down text-muted"></i>
                      </span>
                    </a>
                  </h3>

                  <div
                    id="collapseOne"
                    class="collapse show"
                    aria-labelledby="headingOnes"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="pt-2">
                      Bonus Earning is a sure giveaway by Smart Edu Freelance.
                      Before you can access this bonus, you must have concluded
                      a deal with one school to access the bonus.
                      <br />
                      <br />
                      Note you would definatlely get your bonus after the terms
                      as been met.
                    </div>
                  </div>
                </div>

                <div class="border p-3 rounded-3 mb-2" id="headingOne">
                  <h3 class="mb-0 fs-4">
                    <a
                      href="#"
                      class="d-flex align-items-center text-inherit
                    text-decoration-none"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      <span class="me-auto">
                        Is there any payment to be made before becoming a
                        freelance marketer?
                      </span>
                      <span class="collapse-toggle ms-4">
                        <i class="fe fe-chevron-down text-muted"></i>
                      </span>
                    </a>
                  </h3>

                  <div
                    id="collapseOne"
                    class="collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="pt-2">
                      Smart Edu Freelance Marketing platform is free for all. We
                      won't ask you for any payment what so ever for the
                      platform. All that is required of you is to sign up.
                    </div>
                  </div>
                </div>

                <div class="border p-3 rounded-3 mb-2" id="headingTwo">
                  <h3 class="mb-0 fs-4">
                    <a
                      href="#"
                      class="d-flex align-items-center text-inherit
                    text-decoration-none"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <span class="me-auto">
                        What’s the benefits of the Smart Invest?
                      </span>
                      <span class="collapse-toggle ms-4">
                        <i class="fe fe-chevron-down text-muted"></i>
                      </span>
                    </a>
                  </h3>

                  <div
                    id="collapseTwo"
                    class="collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="pt-3">
                      Smart Invest is a platform organised by Smart Edu
                      Freelance to compound your earnings.
                      <br />
                      <br />
                      This is a secure investment plan where you can only access
                      your money in bulk at a the end of a full session (three
                      term). To achieve this you would need to save your first
                      and second payments with Smart Edu Inc and allow it to
                      grow times three
                    </div>
                  </div>
                </div>

                <div class="border p-3 rounded-3 mb-2" id="headingThree">
                  <h3 class="mb-0 fs-4">
                    <a
                      href="#"
                      class="d-flex align-items-center text-inherit
                    text-decoration-none active"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <span class="me-auto">
                        How much time will i need to get started with the
                        Marketing?
                      </span>
                      <span class="collapse-toggle ms-4">
                        <i class="fe fe-chevron-down text-muted"></i>
                      </span>
                    </a>
                  </h3>

                  <div
                    id="collapseThree"
                    class="collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="pt-3">
                      Once you sign up you get started right away.
                      <br />
                      <br />
                      The platform as enabled you to have access to all that is
                      need for you to market the App.
                    </div>
                  </div>
                </div>

                <div class="border p-3 rounded-3 mb-2" id="headingFour">
                  <h3 class="mb-0 fs-4">
                    <a
                      href="#"
                      class="d-flex align-items-center text-inherit
                    text-decoration-none active"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      <span class="me-auto">
                        Are there any deadlines for bringing a school?
                      </span>
                      <span class="collapse-toggle ms-4">
                        <i class="fe fe-chevron-down text-muted"></i>
                      </span>
                    </a>
                  </h3>

                  <div
                    id="collapseFour"
                    class="collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="pt-3">
                      There are no deadlines for bring in a school(s), once you
                      have made contact with the school and they signify
                      interest of sign up, you get paid instantly.
                    </div>
                  </div>
                </div>

                <div class="border p-3 rounded-3 mb-2" id="headingFive">
                  <h3 class="mb-0 fs-4">
                    <a
                      href="#"
                      class="d-flex align-items-center text-inherit
                    text-decoration-none active"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      <span class="me-auto">
                        When would i get paid for marketing?
                      </span>
                      <span class="collapse-toggle ms-4">
                        <i class="fe fe-chevron-down text-muted"></i>
                      </span>
                    </a>
                  </h3>

                  <div
                    id="collapseFive"
                    class="collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="pt-3">
                      Once you have introduced a school and they have signed and
                      made commitment your money would be sent directly to your
                      account within 24hrs automatically.
                    </div>
                  </div>
                </div>

                <div class="border p-3 rounded-3 mb-2" id="headingSix">
                  <h3 class="mb-0 fs-4">
                    <a
                      href="#"
                      class="d-flex align-items-center text-inherit
                    text-decoration-none active"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      <span class="me-auto">
                        How do I get my payments (commission) from Smart Edu
                        Freelance?
                      </span>
                      <span class="collapse-toggle ms-4">
                        <i class="fe fe-chevron-down text-muted"></i>
                      </span>
                    </a>
                  </h3>

                  <div
                    id="collapseSix"
                    class="collapse"
                    aria-labelledby="headingSix"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="pt-3">
                      From the Account Dashboard once you have logged in, click
                      on <b>"Withdraw Earnings"</b> your payment would then be
                      processed and you would get the payment within 24hrs.
                    </div>
                  </div>
                </div>

                <div class="border p-3 rounded-3 mb-2" id="headingSeven">
                  <h3 class="mb-0 fs-4">
                    <a
                      href="#"
                      class="d-flex align-items-center text-inherit
                    text-decoration-none active"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      <span class="me-auto">
                        How Does Smart Edu Freelance Work?
                      </span>
                      <span class="collapse-toggle ms-4">
                        <i class="fe fe-chevron-down text-muted"></i>
                      </span>
                    </a>
                  </h3>

                  <div
                    id="collapseSeven"
                    class="collapse"
                    aria-labelledby="headingSeven"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="pt-3">
                      Smart Edu Freelance is a platform that pays you once you
                      introduce <b>Smart Edu App</b> to schools.
                      <br />
                      <br />
                      Therefore Smart Edu Freelance platform has created an
                      opportunity for people to earn greatly, build capacity,
                      gain more connection and create a self owner job platform
                      for individuals.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pb-lg-16 pb-10">
        <div class="container">
          <div class="row">
            <div class="offset-lg-2 col-lg-4  col-12">
              <div class="mb-8">
                <h2 class="mb-0 h1 fw-semi-bold">
                  Can't find what you're looking for?
                </h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="offset-lg-2 col-lg-8  col-12">
              <div class="row">
                <div class="col-md-6 col-12">
                  <div class="card border mb-md-0 mb-4">
                    <div class="card-body">
                      <div class="mb-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-help-circle text-primary"
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                          <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                          <line x1="12" y1="17" x2="12.01" y2="17"></line>
                        </svg>
                      </div>

                      <h3 class="mb-2 fw-semi-bold">Contact us</h3>
                      <p>
                        Our Team are here to help. We can provide you with the
                        support you need. Just contact us and our team will
                        reply quick to you.
                      </p>

                      <Link to="/contact" class="btn btn-primary btn-sm">
                        Contact us
                      </Link>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="card border">
                    <div class="card-body">
                      <div class="mb-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#754ffe"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-life-buoy text-primary"
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                          <circle cx="12" cy="12" r="4"></circle>
                          <line x1="4.93" y1="4.93" x2="9.17" y2="9.17"></line>
                          <line
                            x1="14.83"
                            y1="14.83"
                            x2="19.07"
                            y2="19.07"
                          ></line>
                          <line
                            x1="14.83"
                            y1="9.17"
                            x2="19.07"
                            y2="4.93"
                          ></line>
                          <line
                            x1="14.83"
                            y1="9.17"
                            x2="18.36"
                            y2="5.64"
                          ></line>
                          <line
                            x1="4.93"
                            y1="19.07"
                            x2="9.17"
                            y2="14.83"
                          ></line>
                        </svg>
                      </div>

                      <h3 class="mb-2 fw-semi-bold">Support</h3>
                      <p>
                        The good news is that you’re not alone, and you’re in
                        the right place. Contact us for more detailed support.
                      </p>

                      <a
                        
                        class="btn btn-outline-secondary btn-sm"
                      >
                        Submit a Ticket (coming soon)
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Support;
