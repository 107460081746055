import React, { useState } from "react";

import Navbar from "./Navbar";

import Footer from "../Footer";

import "../index.css";

import {
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";

import { useEffect } from "react";

import Newsletter from "./Newsletter";

import { Link, useLocation, BrowserRouter as Router } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Earn = () => {
  const handleindex = { zIndex: "1" };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  // Top: 0 takes us all the way back to the top of the page
  // Behavior: smooth keeps it smooth!
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  let query = useQuery();

  const blog = query.get("blog");

  if (blog == "how lucrative is school business") {
    return (
      <div className="bg-white">
        <Navbar />

        <div class="py-4 py-lg-8 pb-14 ">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-8 col-lg-8 col-md-12 col-12 mb-2">
                <div class="text-center mb-4">
                  <a
                    
                    class="fs-3 fw-semi-bold d-block mb-4 text-primary"
                  >
                    Article
                  </a>
                  <h1 class="display-3 fw-bold mb-4">
                    How lucrative (do you think) is school business?
                  </h1>
                  <span class="mb-3 d-inline-block">5 min read</span>
                </div>

                <div class="d-flex justify-content-between align-items-center mb-5">
                  <div class="d-flex align-items-center">
                    <img
                      src="../assets/images/avatar/staff.jpg"
                      alt=""
                      class="rounded-circle avatar-md"
                    />
                    <div class="ms-2 lh-1">
                      <h5 class="mb-1 ">Funmilayo Deborah</h5>
                      <span class="text-primary">Marketing Manager</span>
                    </div>
                  </div>
                  <div>
                    <span class="ms-2 text-muted">Share</span>
                    <FacebookShareButton
                      title="How lucrative (do you think) is school business?"
                      url="/blog?How lucrative (do you think) is school business?"
                    >
                      <i class="fab fa-facebook ms-2 text-muted"></i>
                    </FacebookShareButton>
                    <TwitterShareButton
                      title="How lucrative (do you think) is school business?"
                      url="/blog?How lucrative (do you think) is school business?"
                    >
                      <i class="fab fa-twitter ms-2 text-muted"></i>
                    </TwitterShareButton>
                    <LinkedinShareButton
                      title="How lucrative (do you think) is school business?"
                      url="/blog?How lucrative (do you think) is school business?"
                    >
                      <i class="fab fa-linkedin ms-2 text-muted"></i>
                    </LinkedinShareButton>
                    <WhatsappShareButton
                      title="How lucrative (do you think) is school business?"
                      url="/blog?How lucrative (do you think) is school business?"
                    >
                      <i class="fab fa-whatsapp ms-2 text-muted"></i>
                    </WhatsappShareButton>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-xl-10 col-lg-10 col-md-12 col-12 mb-6">
                <img
                  src="../assets/images/blog/2.png"
                  alt=""
                  class="img-fluid rounded-3"
                />
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-xl-8 col-lg-8 col-md-12 col-12 mb-2">
                <div>
                  <div class="mb-4">
                    <p class="lead text-dark">
                      “Is school business in Nigeria profitable? ? Is it is a
                      business with good patronage in Nigeria? Is it a stable
                      business?” Yes! It’s a highly profitable business, very
                      stable and has a large percentage of patronage as well!
                    </p>
                    <p>
                      Most parent want to send their children to school to
                      acquire knowledge. School fees and expenses often take
                      more than 20 per cent of what average parents earn,
                      depending on the number of children they have. This has
                      led to the booming of the school business as many people
                      are beginning to seek how to start a school business or
                      How to run a private school successfully. The school
                      business in Nigeria has become more profitable these days
                      than ever before due to the sheer number of children. With
                      such an increase in the number of children born annually,
                      there is a need for more schools to cater to them. This
                      need for even more will continue to increase over the
                      coming years.
                    </p>
                    <p>
                      Whatever the case, the business is certainly lucrative.
                      For example, if your school business charges N10,000 per
                      term and you have 200 pupil, the amount you will be
                      getting from school fees is 10,000 x 200 = N2,000,000 per
                      term and{" "}
                      <span class="border-bottom border-danger fw-semi-bold">
                        you should be able to run your business on 1 to 1.5
                        million naira per term.
                      </span>
                    </p>
                  </div>

                  <div class="mb-4">
                    <p>
                      To the question of stability, School business is a stable
                      business. The challenge of stability faced by other
                      businesses is most times not the case in running a school
                      business. Most times students attend same schools till
                      they graduate. Parents also tend to enroll all their
                      children in one school this boosts the stability of school
                      business.
                    </p>
                  </div>

                  <div class="mb-4 ">
                    <p class="mb-0">Good Luck!</p>
                  </div>
                </div>

                <hr class="mt-8 mb-5" />
                <div class="d-flex justify-content-between align-items-center mb-5">
                  <div class="d-flex align-items-center">
                    <img
                      src="../assets/images/avatar/staff.jpg"
                      alt=""
                      class="rounded-circle avatar-md"
                    />
                    <div class="ms-2 lh-1">
                      <h5 class="mb-1 ">Funmilayo Deborah</h5>
                      <span class="text-primary">Marketing Manager</span>
                    </div>
                  </div>
                  <div>
                    <span class="ms-2 text-muted">Share</span>
                    <FacebookShareButton
                      title="How lucrative (do you think) is school business?"
                      url="/blog?How lucrative (do you think) is school business?"
                    >
                      <i class="fab fa-facebook ms-2 text-muted"></i>
                    </FacebookShareButton>
                    <TwitterShareButton
                      title="How lucrative (do you think) is school business?"
                      url="/blog?How lucrative (do you think) is school business?"
                    >
                      <i class="fab fa-twitter ms-2 text-muted"></i>
                    </TwitterShareButton>
                    <LinkedinShareButton
                      title="How lucrative (do you think) is school business?"
                      url="/blog?How lucrative (do you think) is school business?"
                    >
                      <i class="fab fa-linkedin ms-2 text-muted"></i>
                    </LinkedinShareButton>
                    <WhatsappShareButton
                      title="How lucrative (do you think) is school business?"
                      url="/blog?How lucrative (do you think) is school business?"
                    >
                      <i class="fab fa-whatsapp ms-2 text-muted"></i>
                    </WhatsappShareButton>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="bg-dark text-white myBtn">
          {isVisible && (
            <div onClick={scrollToTop}>
              <i class="bi bi-arrow-up-square"></i>
            </div>
          )}
        </div>

        <Footer />
      </div>
    );
  } else if (blog == "how many schools are in Nigeria") {
   
    return (
      <div className="bg-white">
        <Navbar />

        <div class="py-4 py-lg-8 pb-14 ">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-8 col-lg-8 col-md-12 col-12 mb-2">
                <div class="text-center mb-4">
                  <a
                    
                    class="fs-3 fw-semi-bold d-block mb-4 text-primary"
                  >
                    Article
                  </a>
                  <h1 class="display-3 fw-bold mb-4">
                    How many (do you think) schools are in Nigeria?
                  </h1>
                  <span class="mb-3 d-inline-block">2 min read</span>
                </div>

                <div class="d-flex justify-content-between align-items-center mb-5">
                  <div class="d-flex align-items-center">
                    <img
                      src="../assets/images/avatar/staff.jpg"
                      alt=""
                      class="rounded-circle avatar-md"
                    />
                    <div class="ms-2 lh-1">
                      <h5 class="mb-1 ">Funmilayo Deborah</h5>
                      <span class="text-primary">Marketing Manager</span>
                    </div>
                  </div>
                  <div>
                    <span class="ms-2 text-muted">Share</span>
                    <FacebookShareButton
                      title="How many (do you think) schools are in Nigeria?"
                      url="/blog?How many (do you think) schools are in Nigeria?"
                    >
                      <i class="fab fa-facebook ms-2 text-muted"></i>
                    </FacebookShareButton>
                    <TwitterShareButton
                      title="How many (do you think) schools are in Nigeria?"
                      url="/blog?How many (do you think) schools are in Nigeria?"
                    >
                      <i class="fab fa-twitter ms-2 text-muted"></i>
                    </TwitterShareButton>
                    <LinkedinShareButton
                      title="How many (do you think) schools are in Nigeria?"
                      url="/blog?How many (do you think) schools are in Nigeria?"
                    >
                      <i class="fab fa-linkedin ms-2 text-muted"></i>
                    </LinkedinShareButton>
                    <WhatsappShareButton
                      title="How many (do you think) schools are in Nigeria?"
                      url="/blog?How many (do you think) schools are in Nigeria?"
                    >
                      <i class="fab fa-whatsapp ms-2 text-muted"></i>
                    </WhatsappShareButton>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-xl-10 col-lg-10 col-md-12 col-12 mb-6">
                <img
                  src="../assets/images/blog/4.png"
                  alt=""
                  class="img-fluid rounded-3"
                />
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-xl-8 col-lg-8 col-md-12 col-12 mb-2">
                <div>
                  <div class="mb-4">
                    <p class="lead text-dark">
                      How many schools are in Nigeria? In 2018, Nigeria counted
                      5.5 million children in private schools. Recent data show
                      that the gross enrollment rate in primary schools stood at
                      68.3 percent and North –Western states registered the
                      highest figures.
                    </p>
                    <p>
                      There are over 80,000 private schools in Nigeria
                      presently. One do not need to go too far before locating a
                      private school around. In most places in Nigeria, within a
                      short distance of about few minutes on feet, one can
                      locate a school. With the profitable yielding nature of
                      school business and the stability of the business as well,
                      the population of schools in Nigeria is on the increase.
                    </p>
                  </div>
                </div>

                <hr class="mt-8 mb-5" />
                <div class="d-flex justify-content-between align-items-center mb-5">
                  <div class="d-flex align-items-center">
                    <img
                      src="../assets/images/avatar/staff.jpg"
                      alt=""
                      class="rounded-circle avatar-md"
                    />
                    <div class="ms-2 lh-1">
                      <h5 class="mb-1 ">Funmilayo Deborah</h5>
                      <span class="text-primary">Marketing Manager</span>
                    </div>
                  </div>
                  <div>
                    <span class="ms-2 text-muted">Share</span>
                    <FacebookShareButton
                      title="How many (do you think) schools are in Nigeria?"
                      url="/blog?How many (do you think) schools are in Nigeria?"
                    >
                      <i class="fab fa-facebook ms-2 text-muted"></i>
                    </FacebookShareButton>
                    <TwitterShareButton
                      title="How many (do you think) schools are in Nigeria?"
                      url="/blog?How many (do you think) schools are in Nigeria?"
                    >
                      <i class="fab fa-twitter ms-2 text-muted"></i>
                    </TwitterShareButton>
                    <LinkedinShareButton
                      title="How many (do you think) schools are in Nigeria?"
                      url="/blog?How many (do you think) schools are in Nigeria?"
                    >
                      <i class="fab fa-linkedin ms-2 text-muted"></i>
                    </LinkedinShareButton>
                    <WhatsappShareButton
                      title="How many (do you think) schools are in Nigeria?"
                      url="/blog?How many (do you think) schools are in Nigeria?"
                    >
                      <i class="fab fa-whatsapp ms-2 text-muted"></i>
                    </WhatsappShareButton>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="bg-dark text-white myBtn">
          {isVisible && (
            <div onClick={scrollToTop}>
              <i class="bi bi-arrow-up-square"></i>
            </div>
          )}
        </div>

        <Footer />
      </div>
    );
  } else if (blog == "smart edu app (smart edu inc)") {
    
    return (
      <div className="bg-white">
        <Navbar />

        <div class="py-4 py-lg-8 pb-14 ">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-8 col-lg-8 col-md-12 col-12 mb-2">
                <div class="text-center mb-4">
                  <a
                    
                    class="fs-3 fw-semi-bold d-block mb-4 text-primary"
                  >
                    Article
                  </a>
                  <h1 class="display-3 fw-bold mb-4">
                    Smart Edu App (Smart Edu Inc)
                  </h1>
                  <span class="mb-3 d-inline-block">3 min read</span>
                </div>

                <div class="d-flex justify-content-between align-items-center mb-5">
                  <div class="d-flex align-items-center">
                    <img
                      src="../assets/images/avatar/staff.jpg"
                      alt=""
                      class="rounded-circle avatar-md"
                    />
                    <div class="ms-2 lh-1">
                      <h5 class="mb-1 ">Funmilayo Deborah</h5>
                      <span class="text-primary">Marketing Manager</span>
                    </div>
                  </div>
                  <div>
                    <span class="ms-2 text-muted">Share</span>
                    <FacebookShareButton
                      title="Smart Edu App (Smart Edu Inc)"
                      url="/blog?Smart Edu App (Smart Edu Inc)"
                    >
                      <i class="fab fa-facebook ms-2 text-muted"></i>
                    </FacebookShareButton>
                    <TwitterShareButton
                      title="Smart Edu App (Smart Edu Inc)"
                      url="/blog?Smart Edu App (Smart Edu Inc)"
                    >
                      <i class="fab fa-twitter ms-2 text-muted"></i>
                    </TwitterShareButton>
                    <LinkedinShareButton
                      title="Smart Edu App (Smart Edu Inc)"
                      url="/blog?Smart Edu App (Smart Edu Inc)"
                    >
                      <i class="fab fa-linkedin ms-2 text-muted"></i>
                    </LinkedinShareButton>
                    <WhatsappShareButton
                      title="Smart Edu App (Smart Edu Inc)"
                      url="/blog?Smart Edu App (Smart Edu Inc)"
                    >
                      <i class="fab fa-whatsapp ms-2 text-muted"></i>
                    </WhatsappShareButton>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-xl-10 col-lg-10 col-md-12 col-12 mb-6">
                <img
                  src="../assets/images/blog/3.png"
                  alt=""
                  class="img-fluid rounded-3"
                />
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-xl-8 col-lg-8 col-md-12 col-12 mb-2">
                <div>
                  <div class="mb-4">
                    <p class="lead text-dark">
                      Smart Edu App is a web App designed and developed by Smart
                      Edu Inc. It’s an App developed to suit the management
                      activities of the school environment. It’s equipped with a
                      lot of technological features to handle school management
                      activitiess at a very good speed.
                    </p>
                    <p>
                      The App has been built to enhance an explicit data base
                      management system, stress free learning, easy access to
                      lecture notes and exercises, automatic results
                      calculations, provide easy access to these results by
                      parents and stress free bill payments even in the comfort
                      of homes.
                    </p>
                  </div>
                </div>

                <div class="mb-4 ">
                  <p class="mb-0">
                    The App is user friendly; easy to operate, with beautiful
                    and interesting User interface and User experience.
                  </p>
                </div>

                <hr class="mt-8 mb-5" />
                <div class="d-flex justify-content-between align-items-center mb-5">
                  <div class="d-flex align-items-center">
                    <img
                      src="../assets/images/avatar/staff.jpg"
                      alt=""
                      class="rounded-circle avatar-md"
                    />
                    <div class="ms-2 lh-1">
                      <h5 class="mb-1 ">Funmilayo Deborah</h5>
                      <span class="text-primary">Marketing Manager</span>
                    </div>
                  </div>
                  <div>
                    <span class="ms-2 text-muted">Share</span>
                    <FacebookShareButton
                      title="Smart Edu App (Smart Edu Inc)"
                      url="/blog?Smart Edu App (Smart Edu Inc)"
                    >
                      <i class="fab fa-facebook ms-2 text-muted"></i>
                    </FacebookShareButton>
                    <TwitterShareButton
                      title="Smart Edu App (Smart Edu Inc)"
                      url="/blog?Smart Edu App (Smart Edu Inc)"
                    >
                      <i class="fab fa-twitter ms-2 text-muted"></i>
                    </TwitterShareButton>
                    <LinkedinShareButton
                      title="Smart Edu App (Smart Edu Inc)"
                      url="/blog?Smart Edu App (Smart Edu Inc)"
                    >
                      <i class="fab fa-linkedin ms-2 text-muted"></i>
                    </LinkedinShareButton>
                    <WhatsappShareButton
                      title="Smart Edu App (Smart Edu Inc)"
                      url="/blog?Smart Edu App (Smart Edu Inc)"
                    >
                      <i class="fab fa-whatsapp ms-2 text-muted"></i>
                    </WhatsappShareButton>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="bg-dark text-white myBtn">
          {isVisible && (
            <div onClick={scrollToTop}>
              <i class="bi bi-arrow-up-square"></i>
            </div>
          )}
        </div>

        <Footer />
      </div>
    );
  } else {
    return (
      <div className="bg-white">
        <Navbar />

        <div class="py-4 py-lg-8 pb-14 ">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-8 col-lg-8 col-md-12 col-12 mb-2">
                <div class="text-center mb-4">
                  <a
                    
                    class="fs-3 fw-semi-bold d-block mb-4 text-primary"
                  >
                    Article
                  </a>
                  <h1 class="display-3 fw-bold mb-4">
                    Introducing school management system (Smart Edu App) into
                    schools
                  </h1>
                  <span class="mb-3 d-inline-block">4 min read</span>
                </div>

                <div class="d-flex justify-content-between align-items-center mb-5">
                  <div class="d-flex align-items-center">
                    <img
                      src="../assets/images/avatar/staff.jpg"
                      alt=""
                      class="rounded-circle avatar-md"
                    />
                    <div class="ms-2 lh-1">
                      <h5 class="mb-1 ">Funmilayo Deborah</h5>
                      <span class="text-primary">Marketing Manager</span>
                    </div>
                  </div>
                  <div>
                    <span class="ms-2 text-muted">Share</span>
                    <FacebookShareButton
                      title="Introducing school management system (Smart Edu App) into
                    schools"
                      url="/blog?Introducing school management system (Smart Edu App) into
                    schools"
                    >
                      <i class="fab fa-facebook ms-2 text-muted"></i>
                    </FacebookShareButton>
                    <TwitterShareButton
                      title="Introducing school management system (Smart Edu App) into
                    schools"
                      url="/blog?Introducing school management system (Smart Edu App) into
                    schools"
                    >
                      <i class="fab fa-twitter ms-2 text-muted"></i>
                    </TwitterShareButton>
                    <LinkedinShareButton
                      title="Introducing school management system (Smart Edu App) into
                    schools"
                      url="/blog?Introducing school management system (Smart Edu App) into
                    schools"
                    >
                      <i class="fab fa-linkedin ms-2 text-muted"></i>
                    </LinkedinShareButton>
                    <WhatsappShareButton
                      title="Introducing school management system (Smart Edu App) into
                    schools"
                      url="/blog?Introducing school management system (Smart Edu App) into
                    schools"
                    >
                      <i class="fab fa-whatsapp ms-2 text-muted"></i>
                    </WhatsappShareButton>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-xl-10 col-lg-10 col-md-12 col-12 mb-6">
                <img
                  src="../assets/images/blog/1.png"
                  alt=""
                  class="img-fluid rounded-3"
                />
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-xl-8 col-lg-8 col-md-12 col-12 mb-2">
                <div>
                  <div class="mb-4">
                    <p class="lead text-dark">
                      You can work remotely as all you need to do to earn big
                      for yourself is to introduce the App to schools and make
                      sure they sign up.
                    </p>
                    <p>
                      It’s no doubt that a school management system is a need in
                      every school, however there are still schools that do not
                      have any school management software functional in their
                      school. Smart Edu app, one of the finest school management
                      system is currently operational in many schools and we are
                      introducing the Smart Edu community where freelance
                      marketers can market the App and get handsomely paid as
                      the school (s) sign up to the usage of the App.
                    </p>
                    <p>
                      You do not need to go too far before locating a private
                      school around. In most places in Nigeria, within a short
                      distance of about few minutes on feet, one can locate a
                      school. You may know a couple of school owners and
                      affiliates that you can introduce the App to as well. So
                      you{" "}
                      <span class="border-bottom border-danger fw-semi-bold">
                        can earn money without even spending money.
                      </span>
                    </p>
                  </div>
                  <hr class="mt-lg-10 mb-lg-6 my-md-6" />
                  <blockquote class="blockquote text-center ">
                    <p class="text-primary font-italic fw-semi-bold lh-lg h1 px-2 px-lg-14">
                      "Failure will never overtake me if my determination to
                      succeed is strong enough."
                    </p>
                    <footer class="blockquote-footer mt-3 text-muted">
                      <cite title="Source Title">Og Mandino</cite>
                    </footer>
                  </blockquote>
                  <hr class="mt-lg-6 mb-lg-10 my-md-6" />
                  <div class="mb-4">
                    <p>
                      The market target is straight; the App is majorly for
                      school environment. This helps create major focus and give
                      guide to the freelancers as the focus is clear. The market
                      is large as well, the population of schools in Nigeria is
                      on the increase with thousands of children being enrolled
                      yearly.
                    </p>
                  </div>

                  <div class="mb-4">
                    <div class="d-flex align-items-start">
                      <div>
                        <p>
                          <strong>
                            You can earn 200 thousand naira to 1 million naira
                          </strong>{" "}
                          in three months Smart Edu mode of sales is at 1000
                          naira per child, and gives the freelancer 100 naira
                          commission per child and also opportunity to sell at
                          choice price. For instance a school has 200 students
                          and you sell at 2000 naira, your commission becomes
                          1000 naira +100 naira =1100 ×200= 120,000 naira. This
                          runs for three terms before expiration. That is you
                          will be getting 120,000 ×3= 320,000 naira in a session
                          for just one school.
                        </p>
                      </div>
                      <img
                        src="../assets/images/blog/freelance3.jpg"
                        alt=" "
                        width="200"
                        class="img-fluid ms-4 rounded-3  d-none d-lg-block d-md-block"
                      />
                    </div>
                  </div>
                </div>

                <hr class="mt-8 mb-5" />
                <div class="d-flex justify-content-between align-items-center mb-5">
                  <div class="d-flex align-items-center">
                    <img
                      src="../assets/images/avatar/staff.jpg"
                      alt=""
                      class="rounded-circle avatar-md"
                    />
                    <div class="ms-2 lh-1">
                      <h5 class="mb-1 ">Funmilayo Deborah</h5>
                      <span class="text-primary">Marketing Manager</span>
                    </div>
                  </div>
                  <div>
                    <span class="ms-2 text-muted">Share</span>
                    <FacebookShareButton
                      title="Introducing school management system (Smart Edu App) into
                    schools"
                      url="/blog?Introducing school management system (Smart Edu App) into
                    schools"
                    >
                      <i class="fab fa-facebook ms-2 text-muted"></i>
                    </FacebookShareButton>
                    <TwitterShareButton
                      title="Introducing school management system (Smart Edu App) into
                    schools"
                      url="/blog?Introducing school management system (Smart Edu App) into
                    schools"
                    >
                      <i class="fab fa-twitter ms-2 text-muted"></i>
                    </TwitterShareButton>
                    <LinkedinShareButton
                      title="Introducing school management system (Smart Edu App) into
                    schools"
                      url="/blog?Introducing school management system (Smart Edu App) into
                    schools"
                    >
                      <i class="fab fa-linkedin ms-2 text-muted"></i>
                    </LinkedinShareButton>
                    <WhatsappShareButton
                      title="Introducing school management system (Smart Edu App) into
                    schools"
                      url="/blog?Introducing school management system (Smart Edu App) into
                    schools"
                    >
                      <i class="fab fa-whatsapp ms-2 text-muted"></i>
                    </WhatsappShareButton>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="container">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                <div class="my-5">
                  <h2>Related Post</h2>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                <div class="card mb-4 shadow-lg ">
                  <Link
                    to="/blog?blog=how lucrative is school business"
                    class="card-img-top"
                  >
                    {" "}
                    <img
                      src="../assets/images/blog/2.png"
                      class="card-img-top rounded-top-md"
                      alt=""
                    />
                  </Link>

                  <div class="card-body">
                    <Link to="/blog?blog=how lucrative is school business">
                      <h3>How lucrative (do you think) is school business?</h3>
                    </Link>
                    <p>
                      “Is school business in Nigeria profitable? Is it is a
                      business with good patronage in Nigeria?
                    </p>

                    <div class="row align-items-center g-0 mt-4">
                      <div class="col-auto">
                        <img
                          src="../assets/images/avatar/staff.jpg"
                          alt=""
                          class="rounded-circle avatar-sm me-2"
                        />
                      </div>
                      <div class="col lh-1">
                        <h5 class="mb-1"> Funmilayo Deborah</h5>
                        <p class="fs-6 mb-0">September 09, 2021</p>
                      </div>
                      <div class="col-auto">
                        <p class="fs-6 mb-0">5 Min Read</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                <div class="card mb-4 shadow-lg ">
                  <Link
                    to="/blog?blog=how many schools are in Nigeria"
                    class="card-img-top"
                  >
                    {" "}
                    <img
                      src="../assets/images/blog/4.png"
                      class="card-img-top rounded-top-md"
                      alt=""
                    />
                  </Link>

                  <div class="card-body">
                    <Link to="/blog?blog=how many schools are in Nigeria">
                      <h3>How many (do you think) schools are in Nigeria?</h3>
                    </Link>
                    <p>
                      There are over 80,000 private schools in Nigeria
                      presently.
                    </p>

                    <div class="row align-items-center g-0 mt-4">
                      <div class="col-auto">
                        <img
                          src="../assets/images/avatar/staff.jpg"
                          alt=""
                          class="rounded-circle avatar-sm me-2"
                        />
                      </div>
                      <div class="col lh-1">
                        <h5 class="mb-1"> Funmilayo Deborah</h5>
                        <p class="fs-6 mb-0">September 09, 2021</p>
                      </div>
                      <div class="col-auto">
                        <p class="fs-6 mb-0">2 Min Read</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                <div class="card mb-4 shadow-lg ">
                  <Link
                    to="/blog?blog=smart edu app (smart edu inc)"
                    class="card-img-top"
                  >
                    {" "}
                    <img
                      src="../assets/images/blog/3.png"
                      class="card-img-top rounded-top-md"
                      alt=""
                    />
                  </Link>

                  <div class="card-body">
                    <Link to="/blog?blog=smart edu app (smart edu inc)">
                      <h3>Smart Edu App (Smart Edu Inc)</h3>
                    </Link>
                    <p>
                      The App has been built to enhance an explicit data base
                      management system, stress free learning...
                    </p>

                    <div class="row align-items-center g-0 mt-4">
                      <div class="col-auto">
                        <img
                          src="../assets/images/avatar/staff.jpg"
                          alt=""
                          class="rounded-circle avatar-sm me-2"
                        />
                      </div>
                      <div class="col lh-1">
                        <h5 class="mb-1"> Funmilayo Deborah</h5>
                        <p class="fs-6 mb-0">September 09, 2021</p>
                      </div>
                      <div class="col-auto">
                        <p class="fs-6 mb-0">3 Min Read</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-dark text-white myBtn">
          {isVisible && (
            <div onClick={scrollToTop}>
              <i class="bi bi-arrow-up-square"></i>
            </div>
          )}
        </div>

        <Footer />
      </div>
    );
  }
};

export default Earn;
