import React, { useState } from "react";

import axios from "axios";

import Contact from "./Contact";

import { useSelector } from "react-redux";

import { Navigate } from "react-router-dom";


const user_verify = {
  email: localStorage.getItem("email"),
};

const post_phone = async () => {
  axios({
    method: "post",
    url: "/login/get_phone.php",
    data: user_verify,
    config: { headers: { "Content-Type": "multiport/form-data" } },
  })
    .then(function (response) {
      if (response.data.message === "exist") {
        localStorage.setItem("tokenid", response.data.token);
      } else {
       
      }
    })
    .catch((err) => {
      console.log(err);
    });
  };

  setTimeout(() => {
    post_phone();
  }, 6000);

const Dashboard = (getname) => {

  const email = localStorage.getItem("email");

  const username = getname.getname;

  let fname_1;

  if(username){
    fname_1 = username.split(" ")[1];
    }else{
      fname_1 = '';
    }
 
  const [state, setState] = React.useState({
    isValid: false,
  });

  const [phone, setPhone] = useState("");

  const [phoneErrr, setPhoneErr] = useState({});

  const onSubmit = (b) => {
    b.preventDefault();
    const isValid = formValidation();
    //console.log("works");

    if (isValid) {
      const user_phone = {
        phone: phone,
        email: email,
      };
      // console.log(user_phone);

      axios({
        method: "post",
        url: "/login/phone.php",
        data: user_phone,
        config: { headers: { "Content-Type": "multiport/form-data" } },
      })
        .then(function (response) {
          if (response.data.message === "success") {
            // console.log(response);
            localStorage.setItem("tokenid", response.data.tokenid);
            localStorage.setItem("token", response.data.tokenid);
            setState({
              loggedIn: true,
            });
          } else if (response.data.message === "exist") {
            setState({
              loggedIn: false,
              message: "Email already exist. Try again!",
            });
          } else {
            setState({
              loggedIn: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const formValidation = () => {
    const phoneErrr = {};
    let isValid = true;

    if (!phone) {
      phoneErrr.phoneShort = "Please enter your phone number";
      isValid = false;
    } else if (phone.trim().length < 11) {
      phoneErrr.phoneShort = "Phone number is not correct!";
      isValid = false;
    }

    setPhoneErr(phoneErrr);

    return isValid;
  };

  const tokenid = localStorage.getItem("tokenid");

  let checkPhone;

  const users_1 = useSelector((state) => state.user.value);

  if(!email){
  
  if(users_1){
    checkPhone = users_1.address;
  }

  }

  if(checkPhone){

    localStorage.removeItem("fullname");
    localStorage.removeItem("token_id");

    return (
      <>
        <Contact />
      </>
    );

  }

  else if (tokenid && email) {
    
    localStorage.removeItem("fullname");
    localStorage.removeItem("token_id");

    return (
      <>
        <Contact />
      </>
    );
  } 
  
  else if(email && !tokenid) {
    
    return (
      <div class="container-fluid bg-white">
        <div class="row align-items-center min-vh-100">
          <div
            class="col-lg-6 d-lg-flex align-items-center w-lg-50 
          min-vh-lg-100 position-fixed-lg bg-cover bg-light top-0
            right-0"
            style={{
              backgroundImage: `url(../assets/images/about/welcome.jpg)`,
            }}
          >
            <div class="px-4 px-xl-20 py-20 py-lg-20"></div>
          </div>

          <div class="col-lg-6 col-md-12 col-12">
            <div class="px-xl-20 px-md-8 px-4 py-8 py-lg-0">
              <div class="text-dark ms-2">
                <h1 class="display-4 fw-bold">
                  Welcome on board{" "}
                  <span className="text-success">
                    {fname_1.charAt(0).toUpperCase() + fname_1.slice(1)}
                    ..
                  </span>
                </h1>
                <p class="lead py-4 text-dark">
                  Complete the following steps...
                </p>

                <form onSubmit={onSubmit}>
                  <div className="mb-3">
                    <label for="phone" className="form-label">
                      Enter your Phone Number
                    </label>
                    <input
                      type="phone"
                      className="form-control"
                      placeholder="Phone Number"
                      onChange={(e) => {
                        setPhone(e.target.value);
                        phoneErrr.phoneShort = "";
                      }}
                    />

                    {Object.keys(phoneErrr).map((key) => {
                      return (
                        <div className="text-danger fw-bold fs-5 mt-2">
                          {phoneErrr[key]}
                        </div>
                      );
                    })}
                  </div>

                  <div className="d-grid mt-5">
                    <button type="submit" className="btn btn-dark">
                      Proceed
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  else{
    return <Navigate to={"../login"} />;
  }
};

export default Dashboard;
