import React from "react";

import { useSelector } from "react-redux";

import { Link } from "react-router-dom";

import Graph from "./Graph";
import Table from "./Table";

const Home = () => {

  const getusers0 = useSelector((state) => state.user.value);

  const getrevenue = getusers0.revenue;

  const getschool = getusers0.schools;

  const getbonus = getusers0.bonus;

  const getemail = getusers0.email;

  
    return (
      <div class="col-lg-9 col-md-8 col-12">
        <div class="row">
          <div class="col-lg-4 col-md-12 col-12">
            <div class="card mb-4 text-center">
              <div class="p-4">
                <span class="fs-6 text-uppercase fw-semi-bold">Revenue</span>
                <h2 class="mt-4 fw-bold mb-1  align-items-center h1 lh-1">
                  ₦{getrevenue}
                </h2>
                <span class="align-items-center">
                  <span>Last Earning</span>
                  <Link to="../app/payouts" class="nav-link">
                  <span class="badge bg-success ms-2">Update payout method</span>
                  </Link>
                </span>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-12 col-12">
            <div class="card mb-4 text-center">
              <div class="p-4">
                <span class="fs-6 text-uppercase fw-semi-bold">
                  Schools Achieved
                </span>
                <h2 class="mt-4 fw-bold mb-1  align-items-center h1 lh-1">
                  {getschool}
                </h2>
                <span class="align-items-center">
                  <span>New this month</span>
                  <Link to="../app/schools" class="nav-link">
                  <span class="badge bg-info ms-2">All Schools</span>
                  </Link>
                </span>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-12">
            <div class="card mb-4 text-center">
              <div class="p-4">
                <span class="fs-6 text-uppercase fw-semi-bold">
                  Bonus Earning
                </span>
                <h2 class="mt-4 fw-bold mb-1  align-items-center h1 lh-1">
                ₦{getbonus}
                </h2>
                <span class="align-items-center">
                  <span>How to withdraw</span>
                  <Link to="../support" class="nav-link">
                  <span class="badge bg-warning ms-2">Bonus Points</span>
                  </Link>
                </span>
              </div>
            </div>
          </div>
          
        </div>

        <Graph getemail={getemail}/>

        <Table getemail={getemail}/>

      </div>
    );
  
}

export default Home;
