import React, { useState } from "react";

import axios from "axios";

import { Link, Navigate } from "react-router-dom";

const Signup = () => {
  const image = {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "65%",
  };

  const [state, setState] = React.useState({
    loggedIn: false,
  });

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [fullNameErr, setFullNameErr] = useState({});
  const [emailErr, setEmailErr] = useState({});
  const [passwordErr, setPasswordErr] = useState({});

  const onSubmit = (b) => {
    b.preventDefault();
    const isValid = formValidation();
    //console.log("works");

    if (isValid) {
      const users = {
        fullname: fullName,
        email: email,
        password: password,
      };

      //console.log(users);

      axios({
        method: "post",
        url: "/login/",
        data: users,
        config: { headers: { "Content-Type": "multiport/form-data" } },
      })
        .then(function (response) {
          if (response.data.message === "success") {
            // console.log(response);
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("email", email);
            localStorage.setItem("fullname", fullName);
            setState({
              loggedIn: true,
            }); 
          } else if (response.data.message === "exist") {
            setState({
              loggedIn: false,
              message: "Email already exist. Try again!",
            });
          } else {
            setState({
              loggedIn: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const formValidation = () => {
    const fullNameErr = {};
    const emailErr = {};
    const passwordErr = {};
    let isValid = true;

    if (fullName.trim().length < 5) {
      fullNameErr.fullNameshort = "Full name is required";
      isValid = false;
    }

    if (password.trim().length < 5) {
      passwordErr.passwordWrong = "Password is too short";
      isValid = false;
    }

    if (!password) {
      passwordErr.passwordWrong = "Password is required here";
      isValid = false;
    }

    if (!email) {
        emailErr.emailWrong = "Email is required here";
        isValid = false;
      }

    setFullNameErr(fullNameErr);
    setEmailErr(emailErr);
    setPasswordErr(passwordErr);

    return isValid;
  };

  if (state.loggedIn) {
    localStorage.removeItem("tokenid"); 
    localStorage.removeItem("token_id"); 
    window.history.back();
    return <Navigate to={"/app"} />;
  } else {
    return (
      <div>
        <div class="container d-flex flex-column">
          <div class="row align-items-center justify-content-center g-0 min-vh-100">
            <div class="col-lg-5 col-md-8 py-8 py-xl-0">
              <div class="card shadow">
                <div class="card-body p-6">
                  <div class="mb-4">
                    <Link to="/" className="ms-8 text-center">
                      <img
                        src="../assets/images/brand/logo/smfreelance-2.png"
                        className="mb-4 text-center"
                        width="260"
                        alt=""
                        style={image}
                      />
                    </Link>
                    <h1 class="mb-1 fw-bold">Sign up</h1>
                    <span>
                      Already have an account?
                      <Link to="/login" class="ms-1">
                        Sign in
                      </Link>
                    </span>
                  </div>

                  <div className="text-danger text-center fs-4 mt-3 mb-3">
                    {state.message}
                  </div>

                  <form onSubmit={onSubmit}>
                    <div className="mb-3">
                      <label for="name" className="form-label">
                        Full Name
                      </label>
                      <input
                        type="name"
                        className="form-control"
                        placeholder="Full Name"
                        onChange={(e) => {
                          setFullName(e.target.value);
                          fullNameErr.fullNameshort = "";
                        }}
                      />
                      {Object.keys(fullNameErr).map((key) => {
                        return (
                          <div className="text-danger fs-5 mt-3">
                            {fullNameErr[key]}
                          </div>
                        );
                      })}
                    </div>

                    <div className="mb-3">
                      <label for="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                          emailErr.emailWrong = "";
                        }}
                      />
                      {Object.keys(emailErr).map((key) => {
                        return (
                          <div className="text-danger fs-5 mt-3">
                            {emailErr[key]}
                          </div>
                        );
                      })}
                    </div>

                    <div className="mb-3">
                      <label for="password" className="form-label">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        onChange={(e) => {
                          setPassword(e.target.value); 
                          passwordErr.passwordWrong = "";
                        }}
                      />
                      {Object.keys(passwordErr).map((key) => {
                        return (
                          <div className="text-danger fs-5 mt-3">
                            {passwordErr[key]}
                          </div>
                        );
                      })}
                    </div>

                    <div className="d-grid">
                      <button type="submit" className="btn btn-primary">
                        Join us now
                      </button>
                    </div>

                    <hr class="my-4" />
                    <div class="mt-4 text-center">
                      <a
                        href="https://t.me/+aO5rVEkmkJ5mNzBk" target="_blank"
                        class="btn-social btn-social-outline btn-facebook"
                      >
                        <i class="fab fa-telegram"></i>
                      </a>

                      <a
                        href="https://twitter.com/SmEduFreelance?s=09" target="_blank"
                        class="btn-social btn-social-outline btn-twitter"
                      >
                        <i class="fab fa-twitter"></i>
                      </a>

                      <a
                        href="https://www.instagram.com/smartedufreelance?r=nametag" target="_blank"
                        class="btn-social btn-social-outline btn-instagram"
                      >
                        <i class="fab fa-instagram"></i>
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Signup;
