import React, { useState } from "react";

import axios from "axios";

import { useSelector } from "react-redux";

import { Link } from "react-router-dom";

const SecurityApp = () => {
  const getusers = useSelector((state) => state.user.value);

  const getname = getusers.fullname;

  const getemail = getusers.email;

  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const [state, setState] = React.useState({
    isValid: false,
  });

  const onSubmit = (b) => {
    b.preventDefault();

    const isValid = formValidation();

    if (isValid) {
      const user_contact = {
        password: password,
        password1: password1,
        password2: password2,
        email: getemail
      };

      console.log(user_contact);

      axios({
        method: "post",
        url: "/api/security.php",
        data: user_contact,
        config: { headers: { "Content-Type": "multiport/form-data" } },
      })
        .then(function (response) {
          if (response.data.message === "success") {
            setState({
              withdrawtext:
                "Hurray! Your profile has been updated. It is undergoing further review. 👋",
              withdrawclass:
                "alert bg-light-success text-dark-success alert-dismissible fade show",
            });
          } else if (response.data.message === "invalid") {
            setState({
              withdrawtext: "Request Failed. Old Password is incorrect! ☹️",
              withdrawclass:
                "alert bg-light-danger text-dark-danger alert-dismissible fade show",
            });
          } else {
            setState({
              withdrawtext:
                "Request Failed. Password not updated! Please Check your connection ☹️",
              withdrawclass:
                "alert bg-light-danger text-dark-danger alert-dismissible fade show",
            });
          }
        })
        .catch((err) => {
          // console.log(err);
          setState({
            withdrawtext:
              "Request Failed. Password not updated! Please Check your connection ☹️",
            withdrawclass:
              "alert bg-light-danger text-dark-danger alert-dismissible fade show",
          });
        });
    } else {
      setState({
        withdrawtext: "Request Failed. New Password and Confirm password doesn't match! ☹️",
        withdrawclass:
          "alert bg-light-danger text-dark-danger alert-dismissible fade show",
      });
    }

  };

    const formValidation = () => {
      
      let isValid = false;

      if (password1 === password2) {

        isValid = true
  
      }

      return isValid;
    };

  return (
    <div class="col-lg-9 col-md-8 col-12">
      <div class="card">
        <div class="card-header">
          <h3 class="mb-0">Security</h3>
          <p class="mb-0">
            Edit your account settings and change your password here.
          </p>
        </div>

        <div class="card-body">
          <p>
            Your current email address is{" "}
            <span class="text-success">{getemail}</span>
          </p>

          <div>
            <h4 class="mb-0">Change Password</h4>
            <p>
              We will email you a confirmation when changing your password, so
              please expect that email after submitting.
            </p>

            <div class={state.withdrawclass} role="alert">
              <p class="mb-0">
                {state.withdrawtext}
                <br />
              </p>
            </div>

            <form class="row" onSubmit={onSubmit}>
              <div class="col-lg-6 col-md-12 col-12">
                <div class="mb-3">
                  <label class="form-label" for="currentpassword">
                    Current password
                  </label>
                  <input
                    id="currentpassword"
                    type="password"
                    name="currentpassword"
                    class="form-control"
                    placeholder=""
                    required
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </div>

                <div class="mb-3">
                  <label class="form-label" for="newpassword">
                    New password
                  </label>
                  <input
                    id="newpassword"
                    type="password"
                    name="newpassword"
                    class="form-control mb-2"
                    placeholder=""
                    required
                    onChange={(e) => {
                      setPassword1(e.target.value);
                    }}
                  />
                </div>
                <div class="mb-3">
                  <label class="form-label" for="confirmpassword">
                    Confirm New Password
                  </label>
                  <input
                    id="confirmpassword"
                    type="password"
                    name="confirmpassword"
                    class="form-control mb-2"
                    placeholder=""
                    required
                    onChange={(e) => {
                      setPassword2(e.target.value);
                    }}
                  />
                </div>

                <button type="submit" class="btn btn-primary">
                  Save Password
                </button>
                <div class="col-6"></div>
              </div>
              <div class="col-12 mt-4">
                <p class="mb-0">
                  Can't remember your current password?{" "}
                  <Link to="../reset">Reset your password via email</Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecurityApp;
